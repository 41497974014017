import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material
import { Grid, Button, Container, Stack, Typography, Card, IconButton, Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Iconify from '../../../components/Iconify';
import { getActiveClient, getClientSubscriptionPlanDetails } from '../../../store/coach/reducer';
import { formatDate, getAge } from '../../../utils/date';
// mocks_
import account from '../../../_mock/account';
// ----------------------------------------------------------------------

export default function UserSummary() {
    const theme = useTheme();
    const clientDetails = useSelector(state => getActiveClient(state));
    const subscribedPlanDetails = clientDetails.subscription;
    // const subscribedPlanDetails = useSelector(state => getClientSubscriptionPlanDetails(state));

    return (
            <Card className='glass-box' sx={{padding:2, borderRadius: 2}}>
                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h6" gutterBottom>
                        User Details
                    </Typography>
                </Stack> */}
                <Grid container direction="column"  > 
                    {/* <Grid item container direction="row" justifyContent="flex-start">
                        <Typography variant="h6" gutterBottom>
                            User Details
                        </Typography>
                    </Grid> */}
                    {/* <Grid item container direction="row" justifyContent="center">
                        <IconButton >
                            <Avatar src={account.photoURL} alt="photoURL" style={{width:120, height:120}} />
                        </IconButton>
                    </Grid>
                    <Grid item container direction="row" justifyContent="center">
                        <Typography  gutterBottom>
                            {clientDetails && `${clientDetails.first_name} ${clientDetails.last_name}`}
                        </Typography>
                        
                    </Grid>
                    <Grid item container direction="row" justifyContent="space-around">
                        
                        <Typography  gutterBottom  sx={{fontSize: '0.8rem'}}>
                            {clientDetails && clientDetails.dob && `${getAge(clientDetails.dob)} years `}
                        </Typography>
                        <Typography gutterBottom  sx={{fontSize: '0.8rem'}}>
                                {clientDetails && clientDetails.gender}
                        </Typography>        
                                 
                    </Grid> */}

                    <Grid item mt={4} >
                         <Card className='glass-box' style={{borderRadius: 5, padding:10}}>
                            <Grid container direction="column" >                                
                                 
                                <Typography gutterBottom sx={{fontSize: '0.8rem'}}>
                                    Name : {clientDetails && `${clientDetails.first_name} ${clientDetails.last_name}`}
                                </Typography>

                                <Typography gutterBottom sx={{fontSize: '0.8rem'}}>
                                    Age : {clientDetails && clientDetails.dob && `${getAge(clientDetails.dob)} years`}
                                </Typography>

                                <Typography gutterBottom sx={{fontSize: '0.8rem'}}>
                                    Ggender : {clientDetails && clientDetails.gender}
                                </Typography>
                            </Grid>

                        </Card>
                         
                    </Grid>

                    <Grid item mt={4} >
                         <Card className='glass-box' style={{borderRadius: 5, padding:10}}>
                            <Grid container direction="column" >                                
                                 
                                <Typography gutterBottom sx={{fontSize: '0.8rem'}}>
                                    Height : {clientDetails && clientDetails.height && `${clientDetails.height} cms`}
                                </Typography>

                                <Typography gutterBottom sx={{fontSize: '0.8rem'}}>
                                    Weight : {clientDetails && clientDetails.weight && `${clientDetails.weight} kg`}
                                </Typography>
                            </Grid>

                        </Card>
                         
                    </Grid>

                    <Grid item mt={4} >
                         <Card className='glass-box' style={{borderRadius: 5, padding:10}}>
                            <Grid container direction="column" >                                
                                <Typography gutterBottom sx={{fontSize: '0.8rem'}}>
                                    Subscription Plan : {subscribedPlanDetails && subscribedPlanDetails.meal_plan
                                        && subscribedPlanDetails.meal_plan.parent_meal_plan
                                        && subscribedPlanDetails.meal_plan.parent_meal_plan.display_name}
                                </Typography>

                                <Typography gutterBottom sx={{fontSize: '0.8rem'}}>
                                    Start Date : {subscribedPlanDetails
                                        && formatDate(subscribedPlanDetails.start_date, "DD MMM YYYY")}
                                </Typography>

                                <Typography gutterBottom sx={{fontSize: '0.8rem'}}>
                                    End Date : {subscribedPlanDetails 
                                        && formatDate(subscribedPlanDetails.end_date, "DD MMM YYYY")}
                                </Typography>
                            </Grid>

                        </Card>
                         
                    </Grid>

                    <Grid item mt={4} >
                         <Card className='glass-box' style={{borderRadius: 5, padding:10}}>
                            <Grid container direction="column" >                                
                                 
                                <Typography gutterBottom sx={{fontSize: '0.8rem'}}>
                                    Chest : {clientDetails && clientDetails.chest && `${clientDetails.chest} in`}
                                </Typography>

                                <Typography gutterBottom sx={{fontSize: '0.8rem'}}>
                                    Waist : {clientDetails && clientDetails.waist && `${clientDetails.waist} in`}
                                </Typography>

                                <Typography gutterBottom sx={{fontSize: '0.8rem'}}>
                                    HIP : {clientDetails && clientDetails.hip && `${clientDetails.hip} in`}
                                </Typography>
                            </Grid>

                        </Card>
                         
                    </Grid>
                    
                </Grid>
            </Card>
    );
}
