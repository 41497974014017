import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { Button, Card, Container, Divider, Grid, Typography } from "@mui/material";

import { fetchSubscriptionPlanList } from "../../../data/network/subscription/api";
import Page from "../../../components/Page";
import { getSelectedSubscriptionPlan, getSubscriptionPlanList, setSelectedSubscriptionPlan } from "../../../store/subscription/reducer";
import Iconify from "../../../components/Iconify";
import { MealPlanSchedule } from "./MealPlanSchedule";

export const MealPlanDetail = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [cookies] = useCookies(['mobile', 'access_token', 'session']);

    const selectedMealPlan = useSelector((state) => getSelectedSubscriptionPlan(state));

    useEffect(() => {

    },[]);

    return (
        <Grid container pt={2}>
            <Card className={'glass-box'} sx={{padding:2, minWidth:'100%'}}>
                <Grid container direction={'column'} justifyContent={'flex-start'} alignContent={'flex-start'}>
                    <Button variant={'text'} onClick={() => {
                        dispatch(setSelectedSubscriptionPlan({data: null}));
                        navigate(`/coach/meal_plan`);
                    }}>
                        <Iconify icon={'eva:arrow-back-outline'} sx={{fontSize:24, mx:1}} />
                        <Typography variant="body1">Go to plan list</Typography>
                    </Button>
                </Grid>

                <Grid item my={1}>
                    <Typography variant="h3" mx={2}>
                        {selectedMealPlan && selectedMealPlan.display_name}
                    </Typography>
                </Grid>

                <Divider />

                <Grid container direction={'row'} justifyContent={'space-between'} my={1} >
                    <Grid container item direction={'column'} justifyContent={'flex-start'} p={2}>
                        <Typography variant="body1">{selectedMealPlan && selectedMealPlan.description}</Typography>
                    </Grid>

                    <Grid container item direction={'column'} justifyContent={'flex-start'} p={2}>
                        {
                            selectedMealPlan && selectedMealPlan.sample_meals 
                            && <Grid item>
                            <Typography variant="h5">{`Sample Meals`}</Typography>
                            </Grid>
                        } 

                        <Grid container item direction={'row'} rowGap={1} columnGap={1}>
                            {
                                selectedMealPlan && selectedMealPlan.sample_meals 
                                    && Object.keys(selectedMealPlan.sample_meals).map((e) => 
                                        selectedMealPlan.sample_meals[e] && selectedMealPlan.sample_meals[e].map((meal) => {
                                            return <Card sx={{m:1, height:100, width:100}}>
                                                <Grid container justifyContent={'center'} alignContent={'center'} p={1}>
                                                    <Typography variant="body1">{meal.display_name}</Typography>
                                                </Grid>
                                            </Card>                                                
                                        })
                                    )
                            }
                        </Grid>
                    </Grid>

                    <Grid item direction={'column'} justifyContent={'flex-start'} p={2}>
                        <Grid item>
                            <Typography variant="h5">{`Upcoming Schedule`}</Typography>
                        </Grid>

                        <Grid>
                            {/*  */}
                            {/* <MealPlanSchedule /> */}
                        </Grid>

                    </Grid>


                    {/* <Grid direction={'row'} justifyContent={'flex-end'} sx={{position:'absolute', bottom:0, right:0}}>
                        <Button variant={'contained'} sx={{height:48, borderBottomLeftRadius:0, borderTopLeftRadius: 16, borderTopRightRadius:0}} 
                            onClick={() => {
                                navigate(`/coach/meal_plan?meal_plan_id=${selectedMealPlan.id}`);
                            }}>
                            <Typography variant="body1">View</Typography>
                            <Iconify icon={'eva:arrow-forward-outline'} sx={{fontSize:24, mx:1}} />
                        </Button>
                    </Grid> */}
                </Grid>
            </Card>
        </Grid>
    )
}
