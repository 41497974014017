import { createSlice } from '@reduxjs/toolkit';
import { formatDate } from '../../utils/date';
import {STATUS, PAYMENT_STATUS, PLAN_SELECTION_STATUS, PAYMENT_MODE, MEAL_TYPE,
  MEAL_PREFERENCE, ORDER_TYPE, CLIENT_MEAL_SCHEDULE_EDIT_STATUS,
  CLIENT_ACTIVITY_SCHEDULE_EDIT_STATUS
} from '../../constants/app_constants';

const coachSlice = createSlice({
  name: 'coach',
  initialState: {
    clientList: null,
    activeClient: null,
    clientDetailSubmitted: false,
    planSelectionStatus: PLAN_SELECTION_STATUS.INITIAL,
    selectedPlan: null,
    mealPreferenceDetails: {
      mealPreference: MEAL_PREFERENCE.VEGETARIAN,
      duration: 7,
      mealTypes: [MEAL_TYPE.BREAKFAST, MEAL_TYPE.LUNCH, MEAL_TYPE.SNACKS, MEAL_TYPE.DINNER ],
      startDate: null,
    },
    subscribedPlanDetails: null,
    subscriptionCartDetails : null,
    subscriptionCartTotalAmount : 0,
    paymentStatus : PAYMENT_STATUS.INITIAL,
    paymentMode: PAYMENT_MODE.OFFLINE,
    orderDetails: null,
    addTransactionDetails: null,
    orderType: ORDER_TYPE.SUBSCRIPTION,
    clientMealScheduleEditStatus: CLIENT_MEAL_SCHEDULE_EDIT_STATUS.INITIAL,
    mealSchedule: null,
    mealOptions: null,
    activitySchedule: null,
    clientActivityScheduleEditStatus: CLIENT_ACTIVITY_SCHEDULE_EDIT_STATUS.INITIAL,
  },
  reducers: {
    setClientList(state, action) {
      state.clientList = action.payload.data;
    },
    setClientSubscriptionDetails(state, action) {
      state.subscribedPlanDetails = action.payload.data.subscription;
      state.subscriptionCartDetails = action.payload.data.cart;
      try{
        state.subscriptionCartDetails.price_breakup.forEach(element => {
          if(element.name === "grand_total"){
            state.subscriptionCartTotalAmount = element.value;
          }
        });
      }catch(e){
        console.log(e);
      }
    },
    setActiveClient(state, action) {
      state.activeClient = action.payload.data;
    },
    setClientDetailSubmitted(state, action){
      state.coachDetailSubmitted = action.payload.status;
    },
    setClientPlanSelection(state, action){
      state.planSelectionStatus = action.payload.planSelectionStatus;
      state.selectedPlan = action.payload.selectedPlan;
      console.log(action.payload.selectedPlan);
    },
    setClientMealPreference(state,action) {
      console.log(action.payload );
      if(action.payload.mealPreference !== undefined){
        state.mealPreferenceDetails.mealPreference =  action.payload.mealPreference; 
      }

      if(action.payload.mealTypes !== undefined){
        state.mealPreferenceDetails.mealTypes =  action.payload.mealTypes;         
      }

      if(action.payload.duration !== undefined){
        state.mealPreferenceDetails.duration =  action.payload.duration;         
      }

      if(action.payload.startDate !== undefined){
        state.mealPreferenceDetails.startDate =  action.payload.startDate;         
      }
    },
    setPaymentStatus(state, action){
      state.paymentStatus = action.payload.status;
    },
    setPaymentMode(state, action){
      state.paymentMode = action.payload.mode;
    },
    setClientOrderDetails(state, action){
      state.orderDetails = action.payload.data;
    },
    setClientOrderType(state, action){
      state.orderType = action.payload.type;
    },
    setAddTransactionDetails(state, action){
      state.addTransactionDetails = action.payload.data;
    },
    resetClientState(state, action){
      // state.activeClient = null;
      // state.coachDetailSubmitted = false;
      // state.planSelectionStatus = PLAN_SELECTION_STATUS.INITIAL;
      // state.selectedPlan = {};
      // state.paymentStatus = PAYMENT_STATUS.INITIAL;
      // state.paymentMode = PAYMENT_MODE.OFFLINE;
      // state.subscribedPlanDetails = null;
      // state.subscriptionCartDetails = null;
      // state.subscriptionCartTotalAmount = 0;
      // state.orderDetails = null;
      // state.addTransactionDetails = null;
      // state.mealPreferenceDetails = {
      //   mealPreference: MEAL_PREFERENCE.VEGETARIAN,
      //   duration: 7,
      //   mealTypes: [MEAL_TYPE.BREAKFAST, MEAL_TYPE.LUNCH, MEAL_TYPE.SNACKS, MEAL_TYPE.DINNER ],
      //   startDate: formatDate(new Date()),
      // };

      state.coachList= null;
      state.activeClient= null;
      state.coachDetailSubmitted= false;
      state.planSelectionStatus= PLAN_SELECTION_STATUS.INITIAL;
      state.selectedPlan= {};
      state.mealPreferenceDetails= {
        mealPreference: MEAL_PREFERENCE.VEGETARIAN,
        duration: 7,
        mealTypes: [MEAL_TYPE.BREAKFAST, MEAL_TYPE.LUNCH, MEAL_TYPE.SNACKS, MEAL_TYPE.DINNER ],
        startDate: formatDate(new Date()),
      };
      state.subscribedPlanDetails= null;
      state.subscriptionCartDetails = null;
      state.subscriptionCartTotalAmount = 0;
      state.paymentStatus = PAYMENT_STATUS.INITIAL;
      state.paymentMode= PAYMENT_MODE.OFFLINE;
      state.orderDetails= null;
      state.addTransactionDetails= null;
      state.orderType= ORDER_TYPE.SUBSCRIPTION;
      state.coachMealScheduleEditStatus= CLIENT_MEAL_SCHEDULE_EDIT_STATUS.INITIAL;
      state.mealSchedule= null;
      state.mealOptions= null;
      state.activitySchedule= null;
      state.coachActivityScheduleEditStatus= CLIENT_ACTIVITY_SCHEDULE_EDIT_STATUS.INITIAL;
    },
    setClientMealScheduleEditStatus(state, action) {
      state.coachMealScheduleEditStatus = action.payload.status;
    },
    setClientMealSchedule(state,action){
      if(action.payload.status === STATUS.SUCCESS){
        state.mealSchedule = action.payload.data;
      }
    },
    setClientMealOptions(state,action){
      if(action.payload.status === STATUS.SUCCESS){        
        state.mealOptions = action.payload.data;
      }
    },
    setActiveClientAddress(state,action){
      state.activeClient.address = action.payload.data;
    },
    setClientActivityScheduleEditStatus(state, action) {
      state.coachActivityScheduleEditStatus = action.payload.status;
    }, 
    setClientActivitySchedule(state,action){
      if(action.payload.status === STATUS.SUCCESS){
        state.activitySchedule = action.payload.data;
      }
    },
    setClientJournal(state, action) {
      state.activeClient = {
        ...state.activeClient,
        journal: action.payload.data
      } 
    },
    setClientJournalEditDetail(state, action) {
      state.activeClient = {
        ...state.activeClient,
        journal_edit_detail: action.payload.data
      } 
    },
    setClientJournalEditStatus(state, action) {
      console.log(action.payload.status);
      state.activeClient = {
        ...state.activeClient,
        journal_edit_status: action.payload.status
      } 
    },
    setActiveClientDinnerAddress(state, action) {
      state.activeClient = {
        ...state.activeClient,
        dinnerAddress: action.payload.address
      } 
    },
    setActiveClientLunchAddress(state, action) {
      state.activeClient = {
        ...state.activeClient,
        lunchAddress: action.payload.address
      } 
    },
    setClientReport(state, action) {
      state.activeClient = {
        ...state.activeClient,
        report : action.payload.data
      }
    }
  }
})

export const getClientListData = (state) => {
  
    if(state.coach != null && state.coach !== undefined && state.coach.clientList !== null){

      return state.coach.clientList;
    }
  
    return null;
  }

export const getClientList = (state, status=0) => {

  const clientList = [];

  if(state.coach != null && state.coach !== undefined && state.coach.clientList !== null  && state.coach.clientList.data !== null){

    state.coach.clientList.data.forEach(e => {
      const subscriptionStatus = e.subscription.meal_plan.status;
      clientList.push(e);
      // if(status === 0 && subscriptionStatus === "ACTIVE"){
      //   clientList.push(e);
      // }

      // if(status === 1 && subscriptionStatus !== "ACTIVE"){
      //   clientList.push(e);
      // }

    })

    // return state.coach.clientList;
  }

  return clientList;
}

export const getActiveClient = (state) => {

  if(state.coach != null && state.coach !== undefined){
    return state.coach.activeClient;
  }
  return null;
}



export const getClientDetailStatus = (state) => state.coach.clientDetailSubmitted

export const getClientPlanSelectionStatus = (state) => state.coach.planSelectionStatus

export const getClientSelectedPlan = (state) => state.coach.selectedPlan

export const getClientPaymentStatus = (state) => state.coach.paymentStatus

export const getClientPaymentMode = (state) => state.coach.paymentMode

export const getClientOrderDetails = (state) => state.coach.orderDetails

export const getAddTransactionDetails = (state) => state.coach.addTransactionDetails;

export const getClientSubscriptionPlanDetails = (state) => state.coach.subscribedPlanDetails;

export const getClientSubscriptionCartDetails = (state) => state.coach.subscriptionCartDetails;

export const getClientSubscriptionCartTotalAmount = (state) => state.coach.subscriptionCartTotalAmount;

export const getClientMealPreference  = (state) => state.coach.mealPreferenceDetails;

export const getClientOrderType  = (state) => state.coach.orderType;

export const getClientMealScheduleEditStatus = (state) => state.coach.clientMealScheduleEditStatus;

export const getClientMealSchedule = (state) => state.coach.mealSchedule;

export const getClientActivitySchedule = (state) => state.coach.activitySchedule;

export const getClientMealOptions = (state) => state.coach.mealOptions;

export const getClientActivityScheduleEditStatus = (state) => state.coach.clientActivityScheduleEditStatus;

export const getClientJournal = (state) => {
  try{
    return state.coach.activeClient.journal
  }catch(e){
    return null;
  }
};

export const getClientJournalEditDetail = (state) => {
  try{
    return state.coach.activeClient.journal_edit_detail
  }catch(e){
    return null;
  }
};


export const getClientJournalEditStatus = (state) => {
  return true; 
  // try{
  //   console.log("------")
  //   console.log(state.coach.activeClient)
  //   console.log(state.coach.activeClient.journal_edit_status)
  //   return state.coach.activeClient.journal_edit_status
  // }catch(e){
  //   console.log("------2")
  //   return true;
  //   // return false;
  // } 
};


export const getActiveClientAddress = (state) => {
  try{
    return state.coach.activeClient.address
  }catch(e){
    return null;
  }
};

export const getActiveClientLunchAddress = (state) => {
  try{ 
    if(state.coach.activeClient.lunchAddress && state.coach.activeClient.lunchAddress !== null){
      return parseInt(state.coach.activeClient.lunchAddress, 10)
    }
  }catch(e){
    return null;
  }

  return null;
};

export const getActiveClientDinnerAddress = (state) => {
  try{
    if(state.coach.activeClient.dinnerAddress && state.coach.activeClient.dinnerAddress !== null){
      return parseInt(state.coach.activeClient.dinnerAddress, 10)
    }
  }catch(e){
    return null;
  }
  return null;
};

export const getActiveClientReport = (state) => {
  try{
    return state.coach.activeClient.report;
  }catch(e){
    return null;
  }
};

export const { setClientList, setActiveClient, setClientSubscriptionDetails, setActiveClientAddress,
  setClientPlanSelection, setClientDetailSubmitted,setClientMealPreference,setActiveClientDinnerAddress, 
  setPaymentStatus, setPaymentMode, setClientOrderDetails, setAddTransactionDetails, setActiveClientLunchAddress,
  resetClientState, setClientOrderType, setClientMealScheduleEditStatus, setClientMealSchedule, setClientActivitySchedule,
  setClientActivityScheduleEditStatus, setClientMealOptions, setClientJournal, setClientJournalEditDetail, setClientReport,
  setClientJournalEditStatus
} = coachSlice.actions

export default coachSlice.reducer