import { LoadingButton,  } from "@mui/lab";
import { AdapterDateFns,  } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Container, Grid, Stack, TextField } from "@mui/material";
import { Field, Form, Formik, FormikProvider, useField, useFormik, useFormikContext } from "formik";

import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { MEAL_PREFERENCE, MEAL_TYPE, ONBOARDING_FORM_STEPS, ORDER_TYPE, PLAN_SELECTION_STATUS, SNACKBAR_SEVERITY } from "../../../constants/app_constants";
import "react-datepicker/dist/react-datepicker.css";
import { subscribeClient } from "../../../data/network/subscription/api";
import { getActiveClient, getClientSelectedPlan, getClientSubscriptionPlanDetails, setClientMealPreference, getClientMealPreference, getClientPlanSelectionStatus, getClientOrderType } from "../../../store/coach/reducer";
import { confirmOrder, subscribeClientToPlan } from "../../../data/network/client/api";

import {formatDate} from '../../../utils/date';
import { getUserId } from "../../../store/user/reducer";
import { MEAL_PREFERENCE_ERROR } from "../../../constants/message_constants";
import { setSnackbarMessage, setSnackbarVisibilityStatus } from "../../../store/layout/reducer";
import { setOnboardingCurrentStep } from "../../../store/onboarding/reducer";

export default function MealPreferenceContainer(){

    const formik = useFormik({
        initialValues: {
          'field' : 7,
          'mealPreference' : MEAL_PREFERENCE.VEGETARIAN
        },
        // validationSchema: FormSchema,
        onSubmit: () => {
        //   console.log("123")
        },
    });
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;


    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

    const dispatch = useDispatch();
    const activeClient = useSelector(state => getActiveClient(state));
    const selectedPlan = useSelector(state => getClientSelectedPlan(state));
    const clientPlanSelectionStatus = useSelector(state => getClientPlanSelectionStatus(state));
    const subscribedPlan = useSelector(state => getClientSubscriptionPlanDetails(state));
    const mealPreferenceDetails = useSelector(state => getClientMealPreference(state));
    const orderType = useSelector(state => getClientOrderType(state));

    // Calculating date as per manifestation time
    const minDate = new Date();
    const currentHour  = minDate.getHours(); 

    const [showCustomTenureInput, setShowCustomTenureInput] = useState(false);    
    const [selectedTenureOption, setSelectedTenureOption]   = useState(7);
    const [customTenureValue, setCustomTenureValue]   = useState(45);

    useEffect(() => {

        if(!mealPreferenceDetails || mealPreferenceDetails.startDate === null){
            getMinDate();            
        }else if(mealPreferenceDetails.startDate !== null && mealPreferenceDetails.startDate < Date()){
            getMinDate();                
        }else{
                //
        }
    },[ currentHour, mealPreferenceDetails]);

    useEffect(() => {

        if(mealPreferenceDetails && mealPreferenceDetails.duration){
            if([3, 7, 15, 30].includes(mealPreferenceDetails.duration)){
                setSelectedTenureOption(mealPreferenceDetails.duration);
                setShowCustomTenureInput(false);
            }else{
                setSelectedTenureOption("custom");
                setShowCustomTenureInput(true);
                setCustomTenureValue(mealPreferenceDetails.duration);
            }
        }

    },[mealPreferenceDetails]);

    const getMinDate = () => {
        if(currentHour>18){
            minDate.setDate(minDate.getDate() + 3); 
        }else{
            minDate.setDate(minDate.getDate() + 2); 
        }

        dispatch(
            setClientMealPreference({
                'startDate' : formatDate(minDate),
            })
        );
    }

    const handleSaveDetailsClick = () => {

        const mealTypes = [];
        mealPreferenceDetails.mealTypes.forEach((e) => {
            switch(e){
                case MEAL_TYPE.BREAKFAST:
                    mealTypes.push(1);
                    break;
                case MEAL_TYPE.LUNCH:
                    mealTypes.push(2);
                    break;
                case MEAL_TYPE.SNACKS:
                    mealTypes.push(3);
                    break;
                case MEAL_TYPE.DINNER:
                    mealTypes.push(4);
                    break;
                default:
                    break;
            }
        })

        let _mealPreference = MEAL_PREFERENCE.VEGETARIAN;
        switch(mealPreferenceDetails.mealPreference ){
            case MEAL_PREFERENCE.VEGETARIAN:
                _mealPreference = MEAL_PREFERENCE.VEGETARIAN;
                break;
            case MEAL_PREFERENCE.NONVEGETARIAN:
                _mealPreference = MEAL_PREFERENCE.NONVEGETARIAN;
                break;
            case MEAL_PREFERENCE.EGGETERIAN:
                _mealPreference = MEAL_PREFERENCE.EGGETERIAN;
                break;
            default:
                break;
        }


        const formattedStartDate = formatDate(mealPreferenceDetails.startDate, "YYYY-MM-DD"); 
        
        if(clientPlanSelectionStatus === PLAN_SELECTION_STATUS.SELECTED){
            if(mealPreferenceDetails.duration && mealPreferenceDetails.duration !== ""){
                subscribeClientToPlan(dispatch, cookies, {
                    'user_id' : activeClient.user_id,
                    'meal_plan_id' : (subscribedPlan && subscribedPlan !== null) ? subscribedPlan.meal_plan_id : null,
                    'parent_meal_plan_id' : selectedPlan.id,
                    'preference' : _mealPreference,
                    'duration' : mealPreferenceDetails.duration,
                    'start_date' : formattedStartDate,
                    'meal_types' : mealTypes,
                    'order_type' : orderType
                });
            }else{
                console.log("please seelct duration")
                dispatch(setSnackbarMessage({'message': MEAL_PREFERENCE_ERROR.NO_TENURE_SELECTED, 'severity': SNACKBAR_SEVERITY.ERROR}));

                dispatch(setSnackbarVisibilityStatus({'status': true}));
            }
        }else{
            // show error please select a plan
            dispatch(setSnackbarMessage({ 'message': PLAN_SELECTION_STATUS.NO_PLAN_SELECTED, 'severity':SNACKBAR_SEVERITY.ERROR}));
            dispatch(setSnackbarVisibilityStatus({'status': true}));
            dispatch(setOnboardingCurrentStep(ONBOARDING_FORM_STEPS.PLAN_LIST));
        }
    };

    return (
        <div>
            {/* <h3>Subscription Preferences</h3> */}
            <FormikProvider value={formik}>
                <Box padding={2} />
                <Stack spacing={3} sx={{marginTop:2}}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                    <h4> Number of days </h4>
                        <Field 
                            id="mealTenure"
                            name="meal_tenure"
                            render={({ field }) => (
                                <>
                                <div className="radio-item">
                                <label htmlFor="3">
                                    <input
                                    {...field}
                                    id="3"
                                    value="3"
                                    checked={selectedTenureOption === 3}
                                    name="meal_tenure"
                                    type="radio"
                                    onChange={(e) => {
                                        setSelectedTenureOption(3);
                                        setShowCustomTenureInput(false);
                                        dispatch(setClientMealPreference({
                                            'duration' : 3
                                        }))
                                        // setChecked(val => !val);
                                        // setSelectedTenure(3);
                                    }}
                                    />
                                    <span style={{paddingLeft:4}}>3</span>
                                </label>
                                </div>

                                <div className="radio-item">
                                <label htmlFor="7">
                                 <input
                                    {...field}
                                    id="7"
                                    value="7"
                                    name="meal_tenure"
                                    checked={selectedTenureOption === 7}
                                    type="radio"
                                    onChange={(e) => {
                                        setSelectedTenureOption(7);
                                        setShowCustomTenureInput(false);

                                        dispatch(setClientMealPreference({
                                            'duration' : 7
                                        }))
                                        // setChecked(val => !val);
                                        // setSelectedTenure(7);
                                    }}
                                    />
                                    <span style={{paddingLeft:4}}>7</span>
                                    </label>
                                </div>
                                <div className="radio-item">
                                <label htmlFor="15">
                                    <input
                                    {...field}
                                    id="15"
                                    value="15"
                                    name="meal_tenure"
                                    checked={selectedTenureOption === 15}
                                    type="radio"
                                    onChange={(e) => {
                                        setSelectedTenureOption(15);
                                        setShowCustomTenureInput(false);

                                        dispatch(setClientMealPreference({
                                            'duration' : 15
                                        }))
                                        // setChecked(val => !val);
                                        // setSelectedTenure(15);
                                    }}
                                    />
                                    <span style={{paddingLeft:4}}>15</span>
                                    </label>
                                </div>
                                <div className="radio-item">
                                <label htmlFor="30">
                                    <input
                                    {...field}
                                    id="30"
                                    value="30"
                                    name="type"
                                    checked={selectedTenureOption === 30}
                                    type="radio"
                                    onChange={(e) => {
                                        setShowCustomTenureInput(false);
                                        setSelectedTenureOption(30);

                                        dispatch(setClientMealPreference({
                                            'duration' : 30
                                        }))
                                        // setChecked(val => !val);
                                        // setSelectedTenure(30);
                                    }}
                                    />
                                    <span style={{paddingLeft:4}}>30</span>
                                    {/* <label htmlFor="female">Female</label> */}
                                    </label>
                                </div>
                                <div className="radio-item">
                                <label htmlFor="custom">
                                    <input
                                    {...field}
                                    id="custom"
                                    value="custom"
                                    name="type"
                                    checked={selectedTenureOption === "custom"}
                                    type="radio"
                                    onChange={(e) => {

                                        setSelectedTenureOption("custom");

                                        setShowCustomTenureInput(true);
                                        // dispatch(setClientMealPreference({
                                        //     'duration' : custom
                                        // }))
                                        // setChecked(val => !val);
                                        // setSelectedTenure(30);
                                    }}
                                    />
                                    <span style={{paddingLeft:4}}>Custom</span>
                                    {/* <label htmlFor="female">Female</label> */}
                                    </label>
                                </div>

                                <div>
                                    {
                                        showCustomTenureInput
                                        ? <TextField
                                            type="number"
                                            fullWidth
                                            required
                                            InputProps={{ inputProps: { min: 3, max: 180 } }}
                                            id="custom_tenure"
                                            name="custom_tenure"
                                            label=""
                                            value={customTenureValue}
                                            sx={{width:'75px', height:'50px'}}
                                            {...getFieldProps('custom_tenure')}
                                            onChange = {
                                                (e) => {
                                                    if(e.target.value < 3){
                                                        // value should be greater than 3
                                                        dispatch(setSnackbarMessage({'message': MEAL_PREFERENCE_ERROR.MIN_TENURE, 'severity': SNACKBAR_SEVERITY.ERROR}));
                                                        dispatch(setSnackbarVisibilityStatus({'status': true}));
                                        
                                                        setCustomTenureValue(e.target.value);
                                                        dispatch(setClientMealPreference({
                                                            'duration' : ""
                                                        }))
                                                    }else if(e.target.value > 180){
                                                        // value should be less than 180
                                                        setCustomTenureValue(e.target.value);
                                                        dispatch(setSnackbarMessage({'message': MEAL_PREFERENCE_ERROR.MAX_TENURE, 'severity': SNACKBAR_SEVERITY.ERROR}));
                                                        dispatch(setSnackbarVisibilityStatus({'status': true}));
                                                        dispatch(setClientMealPreference({
                                                            'duration' : ""
                                                        }))
                                                    }else{
                                                        setCustomTenureValue(e.target.value);
                                                        dispatch(setClientMealPreference({
                                                            'duration' : e.target.value
                                                        }))
                                                    }
                                                }
                                            }
                                            error={Boolean(false)}
                                            helperText={false}
                                        />
                                        : null
                                    }
                                </div>
                                
                                </>
                            )}
                        />
                    
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                    <h4> Meal Preference </h4>
                        <Field 
                            id="mealPreference"
                            name="meal_preference"
                            render={({ mealPreference }) => (
                                <>
                                <div className="radio-item">
                                    <label htmlFor="vegetarian">
                                        <input
                                        {...mealPreference}
                                        id="vegetarian"
                                        value={MEAL_PREFERENCE.VEGETARIAN}
                                        checked={mealPreferenceDetails.mealPreference === MEAL_PREFERENCE.VEGETARIAN}
                                        name="meal_preference"
                                        type="radio"
                                        onChange={(e) => {
                                            dispatch(setClientMealPreference({
                                                'mealPreference' : MEAL_PREFERENCE.VEGETARIAN
                                            }))
                                            // setChecked(val => !val);
    
                                            // setSelectedMealPreference(MEAL_PREFERENCE.VEGETARIAN);
                                        }}
                                        />
                                        <span style={{paddingLeft:4}}>Vegetarian</span>
                                    </label>
                                </div>

                                <div className="radio-item">
                                    <label htmlFor="non-vegetarian">
                                        <input
                                        {...mealPreference}
                                        id="non-vegetarian"
                                        value={MEAL_PREFERENCE.NONVEGETARIAN}
                                        checked={mealPreferenceDetails.mealPreference === MEAL_PREFERENCE.NONVEGETARIAN}
                                        name="meal_preference"
                                        type="radio"
                                        onChange={(e) => {
                                            dispatch(setClientMealPreference({
                                                'mealPreference' : MEAL_PREFERENCE.NONVEGETARIAN
                                            }))
                                            // setChecked(val => !val);
                                        }}
                                        />
                                        <span style={{paddingLeft:4}}>Non Vegetarian</span>
                                    </label>
                                </div>

                                <div className="radio-item">
                                    <label htmlFor="eggeterian">
                                        <input
                                        {...mealPreference}
                                        id="eggeterian"
                                        value={MEAL_PREFERENCE.EGGETERIAN}
                                        checked={mealPreferenceDetails.mealPreference === MEAL_PREFERENCE.EGGETERIAN}
                                        name="meal_preference"
                                        type="radio"
                                        onChange={(e) => {
                                            dispatch(setClientMealPreference({
                                                'mealPreference' : MEAL_PREFERENCE.EGGETERIAN
                                            }))
                                            // setChecked(val => !val);
                                        }}
                                        />
                                        <span style={{paddingLeft:4}}>Eggeterian</span>
                                    </label>
                                </div>

                                </>
                            )}
                        />
                    
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                    <h4> Meal Type </h4>
                        <Field 
                            id="mealType"
                            name="meal_type"
                            render={({ mealType }) => (
                                <>
                                <div className="checkbox-item">
                                    <label htmlFor="breakfast">
                                        <input
                                        {...mealType}
                                        id="breakfast"
                                        value={MEAL_TYPE.BREAKFAST}
                                        checked={mealPreferenceDetails.mealTypes.includes(MEAL_TYPE.BREAKFAST)}
                                        name="meal_type"
                                        type="checkbox"
                                        onChange={(e) => {
                                            if(!mealPreferenceDetails.mealTypes.includes(MEAL_TYPE.BREAKFAST)){
                                                const updatedMealTypes = []; 
                                                for( let i = 0; i < mealPreferenceDetails.mealTypes.length; i+=1){ 
                                                    updatedMealTypes.push(mealPreferenceDetails.mealTypes[i] ); 
                                                }
                                                updatedMealTypes.push(MEAL_TYPE.BREAKFAST);
                                                dispatch(setClientMealPreference({
                                                    'mealTypes' : updatedMealTypes
                                                }))  
    
                                            }else{
                                                const updatedMealTypes = []; 
                                                for( let i = 0; i < mealPreferenceDetails.mealTypes.length; i+=1){ 
                                                    if ( mealPreferenceDetails.mealTypes[i] !== MEAL_TYPE.BREAKFAST) { 
                                                        updatedMealTypes.push(mealPreferenceDetails.mealTypes[i] ); 
                                                    }
                                                }
                                                dispatch(setClientMealPreference({
                                                    'mealTypes' : updatedMealTypes
                                                })) 
                                            } 

                                        }}
                                        />
                                        <span style={{paddingLeft:4}}>Breakfast</span>
                                    </label>
                                </div>

                                <div className="checkbox-item">
                                    <label htmlFor="lunch">
                                        <input
                                        {...mealType}
                                        id="lunch"
                                        value={MEAL_TYPE.LUNCH}
                                        checked={mealPreferenceDetails.mealTypes.includes(MEAL_TYPE.LUNCH)}
                                        name="meal_type"
                                        type="checkbox"
                                        onChange={(e) => { 
                                            if(!mealPreferenceDetails.mealTypes.includes(MEAL_TYPE.LUNCH)){
                                                const updatedMealTypes = []; 
                                                for( let i = 0; i < mealPreferenceDetails.mealTypes.length; i+=1){ 
                                                    updatedMealTypes.push(mealPreferenceDetails.mealTypes[i] ); 
                                                }
                                                updatedMealTypes.push(MEAL_TYPE.LUNCH);
                                                dispatch(setClientMealPreference({
                                                    'mealTypes' : updatedMealTypes
                                                }))  
    
                                            }else{
                                                const updatedMealTypes = []; 
                                                for( let i = 0; i < mealPreferenceDetails.mealTypes.length; i+=1){ 
                                                    if ( mealPreferenceDetails.mealTypes[i] !== MEAL_TYPE.LUNCH) { 
                                                        updatedMealTypes.push(mealPreferenceDetails.mealTypes[i] ); 
                                                    }
                                                }
                                                dispatch(setClientMealPreference({
                                                    'mealTypes' : updatedMealTypes
                                                })) 
                                            } 
                                        }}
                                        />
                                        <span style={{paddingLeft:4}}>Lunch</span>
                                    </label>
                                </div>

                                <div className="checkbox-item">
                                    <label htmlFor="snacks">
                                        <input
                                        {...mealType}
                                        id="snacks"
                                        value={MEAL_TYPE.SNACKS}
                                        checked={mealPreferenceDetails.mealTypes.includes(MEAL_TYPE.SNACKS)}
                                        name="meal_type"
                                        type="checkbox"
                                        onChange={(e) => {
                                            if(!mealPreferenceDetails.mealTypes.includes(MEAL_TYPE.SNACKS)){
                                                const updatedMealTypes = []; 
                                                for( let i = 0; i < mealPreferenceDetails.mealTypes.length; i+=1){ 
                                                    updatedMealTypes.push(mealPreferenceDetails.mealTypes[i] ); 
                                                }
                                                updatedMealTypes.push(MEAL_TYPE.SNACKS);
                                                dispatch(setClientMealPreference({
                                                    'mealTypes' : updatedMealTypes
                                                }))  
    
                                            }else{
                                                const updatedMealTypes = []; 
                                                for( let i = 0; i < mealPreferenceDetails.mealTypes.length; i+=1){ 
                                                    if ( mealPreferenceDetails.mealTypes[i] !== MEAL_TYPE.SNACKS) { 
                                                        updatedMealTypes.push(mealPreferenceDetails.mealTypes[i] ); 
                                                    }
                                                }
                                                dispatch(setClientMealPreference({
                                                    'mealTypes' : updatedMealTypes
                                                })) 
                                            } 


                                        }}
                                        />
                                        <span style={{paddingLeft:4}}>Snacks</span>
                                    </label>
                                </div>


                                <div className="checkbox-item">
                                    <label htmlFor="dinner">
                                        <input
                                        {...mealType}
                                        id="dinner"
                                        value={MEAL_TYPE.DINNER}
                                        checked={mealPreferenceDetails.mealTypes.includes(MEAL_TYPE.DINNER)}
                                        name="meal_type"
                                        type="checkbox"
                                        onChange={(e) => {

                                            if(!mealPreferenceDetails.mealTypes.includes(MEAL_TYPE.DINNER)){
                                                const updatedMealTypes = []; 
                                                for( let i = 0; i < mealPreferenceDetails.mealTypes.length; i+=1){ 
                                                    updatedMealTypes.push(mealPreferenceDetails.mealTypes[i] ); 
                                                }
                                                updatedMealTypes.push(MEAL_TYPE.DINNER);
                                                dispatch(setClientMealPreference({
                                                    'mealTypes' : updatedMealTypes
                                                }))  
    
                                            }else{
                                                const updatedMealTypes = []; 
                                                for( let i = 0; i < mealPreferenceDetails.mealTypes.length; i+=1){ 
                                                    if ( mealPreferenceDetails.mealTypes[i] !== MEAL_TYPE.DINNER) { 
                                                        updatedMealTypes.push(mealPreferenceDetails.mealTypes[i] ); 
                                                    }
                                                }
                                                dispatch(setClientMealPreference({
                                                    'mealTypes' : updatedMealTypes
                                                })) 
                                            } 

                                        }}
                                        />
                                        <span style={{paddingLeft:4}}>Dinner</span>
                                    </label>
                                </div>

                                
                                </>
                            )}
                        />
                    
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                    <h4>Start Date</h4>
                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                            <DatePicker 
                                name="date"
                                value={new Date(mealPreferenceDetails.startDate)}
                                selected={new Date(mealPreferenceDetails.startDate)}
                                onChange={(date) => 
                                    dispatch(setClientMealPreference({
                                        'startDate' : formatDate(date)
                                    }))
                                }
                                dateFormat="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                minDate={minDate}
                            />
                            
                        </LocalizationProvider>

                  </Stack>

                  <Grid  justifyContent="center" alignItems="center" container>
                    <Box padding={2} style={{width:'30%'}}>
                        <LoadingButton fullWidth size="large" onClick={() => handleSaveDetailsClick()}variant="contained" loading={isSubmitting}>
                        Save
                        </LoadingButton> 
                    </Box>
                  </Grid>
                 
                </Stack>
              
            </FormikProvider>
            
        </div>
    );

}