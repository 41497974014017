import { useState, useEffect,useRef } from "react";
import { Box, Button, Paper, Card, Grid, Stack, TextField, styled, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Collapse, Stepper, Step, StepLabel, TablePagination, TableFooter } from "@mui/material";
import { filter } from 'lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { getIcon } from "@iconify/react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { subDays } from "date-fns";

import ExportCSV from "../../../components/ExportCSV";
import SearchNotFound from "../../../components/SearchNotFound";
import Scrollbar from "../../../components/Scrollbar";
import FilterContainer from "./FilterContainer";
import { getComparator } from "../../../utils/sort_helper";
import { StoreSalesDetail } from "./StoreSalesDetail";
import { formatDate } from "../../../utils/date";
import { fetchStoreSales } from "../../../data/network/store/sales";
import { getActiveStoreId, getStoreSales } from "../../../store/partner_store/reducer";
import { fetchStoreStaff } from "../../../data/network/store/staff";

export const StoreSalesList = () => {

    const StackStyled = styled(Stack)(({ theme }) => ({
        background: '#fff',
        padding: '20px',
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
            gap: 20,
            flexDirection: 'column'
        },
        '& .MuiBox-root': {
            [theme.breakpoints.down('md')]: {
                marginLeft: 0,
                border: 'none',
                flexWrap: 'wrap',
            },
            display: 'flex',
            alignItems: 'center',
            flexBasis: '25%',
            '&:not(:last-child)': {
                borderRight: '1px solid #ccc',
                [theme.breakpoints.down('md')]: {
                    borderRight: 'none',
                },
            },
            '& .icon': {
                fontSize: '3rem',
                marginRight: '20px',
                '& .MuiBox-root': {
                    border: 'none',
                }
            },
            '& .details': {
                display: 'flex',
                flexDirection: 'column',
                '& .MuiTypography-caption': {
                    textTransform: 'uppercase',
                }
            }
        },
        '& .MuiOutlinedInput-input': {
            padding: '10px 14px',
        }
    }))
    
    const headers = [
        { label: "Order Id", key: "OrderID" },
        { label: "Order Date", key: "OrderDate" },
        { label: "User Details", key: "User" },
        { label: "Item Details", key: "ItemDetails" },
        { label: "Order Value", key: "OrderValue" },
        { label: "Corporate Paid", key: "CorporatePaid" },
        { label: "UserPaid", key: "UserPaid" },
        { label: "Order Status", key: "status" },
    ];

    const [openModal, setModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(10);
    const [open, setOpen] = useState(false);  
    const [selectedRow, setSelectedRow] = useState(null);
    const [filter, setFilter] = useState('');

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    const tableRef = useRef();
    const _paginationRef = useRef();

    const dispatch = useDispatch();
    const [cookies] = useCookies(['mobile', 'access_token', 'session']);

    const SALESLIST = useSelector((state) => getStoreSales(state));
    const ORDERSTATS = SALESLIST?.data?.report_summary;
    const STAT_KEYS = {"total_payments": "Total Sales", "user_payments": "User Paid", "credits_used": "Corporate Paid"}
    // const ORDERSTATS = useSelector((state) => getStoreOrderStats(state));

    const storeId = useSelector((state) => getActiveStoreId(state));
    

    const handleStartDate = (newValue) => {
        setStartDate(formatDate(newValue));
    };
    const handleEndDate = (newValue) => {
        setEndDate(formatDate(newValue));
    };

    const handleSearch = () => {
        setPage(1);
        fetchStoreSales(dispatch, cookies, storeId, startDate, endDate, 1, count, filter);
    }


    function Row(props) {
        const { row } = props;
        const [rowExpanded, setRowExpanded] = useState(false);

        return (
          <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell component="th" scope="row">
                    {row.id}
                </TableCell>
                <TableCell component="th" scope="row">
                {   row.order_id}
                </TableCell>
                <TableCell align="center">{row.order_date} </TableCell>
                <TableCell align="center">{row.user_details}</TableCell>
                <TableCell align="center">{row.order_items}</TableCell>
                <TableCell align="center">
                    {row.status}
                </TableCell>
                {/* <TableCell align="center">{mealQty}</TableCell> */}
                <TableCell align="center">
                    {row.order_amount}                                
                </TableCell>
                <TableCell align="center">{row.credits}</TableCell>
                <TableCell align="center">{row.order_amount - row.credits}</TableCell>
            </TableRow>
          </>
        );
    }

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
        fetchStoreSales(dispatch, cookies, storeId, startDate, endDate, newPage, count, filter);
    };

    const handleFilterApiCall = (filterBy) => {
        setFilter(filterBy);
        setPage(1);
        fetchStoreSales(dispatch, cookies, storeId, startDate, endDate, 1, count, filterBy);
    }

    const orderData = () => {
        const data = [];
        let index = 0
        if (SALESLIST && SALESLIST.data && SALESLIST.data.order_report) {
          for (let i = 0; i < SALESLIST.data.order_report.length; i += 1) {
            data[i] = {
              'OrderID': SALESLIST.data.order_report[i].order_id,
              'OrderDate': SALESLIST.data.order_report[i].order_date,
              'User': SALESLIST.data.order_report[i].user_details,
              'ItemDetails': SALESLIST.data.order_report[i].order_items,
              'OrderValue': SALESLIST.data.order_report[i].order_amount,
              'CorporatePaid': SALESLIST.data.order_report[i].credits,
              'UserPaid': SALESLIST.data.order_report[i].order_amount - SALESLIST.data.order_report[i].credits,
              'status': SALESLIST.data.order_report[i].status,
            };
            index = i;
          }
          data[index+1] = {
                'OrderID': '',
                'OrderDate': '',
                'User': '',
                'ItemDetails': "Total Amount",
                'OrderValue': SALESLIST.data?.report_summary?.total_payments,
                'CorporatePaid': '',
                'UserPaid': '',
                'status': '',
            }
            data[index+2] = {
                'OrderID': '',
                'OrderDate': '',
                'User': '',
                'ItemDetails': "Total Corporate Paid",
                'OrderValue': SALESLIST.data?.report_summary?.credits_used,
                'CorporatePaid': '',
                'UserPaid': '',
                'status': '',
            }
            data[index+3] = {
                'OrderID': '',
                'OrderDate': '',
                'User': '',
                'ItemDetails': "Total User Paid",
                'OrderValue': SALESLIST.data?.report_summary?.user_payments,
                'CorporatePaid': '',
                'UserPaid': '',
                'status': '',
            }
        }
        return data;
    }

    useEffect(() => {
        if (storeId != null){
            // setEndDate(formatDate(Date()));
            // const _startDate = subDays(new Date(), 7);
            // console.log(_startDate);            
            // setStartDate(formatDate(_startDate));


            // fetchStoreSales(dispatch, cookies, storeId, startDate, endDate, page, count, filter);

            const _endDate = formatDate(Date());
            const _startDate = subDays(new Date(), 7);
            const _formattedStartDate = formatDate(_startDate);


            setEndDate(_endDate);
            setStartDate(_formattedStartDate);
            
            fetchStoreSales(dispatch, cookies, storeId, _formattedStartDate, _endDate, page, count, filter);
        }

    },[]);

    useEffect(() => {

        const _endDate = formatDate(Date());
        const _startDate = subDays(new Date(), 7);
        const _formattedStartDate = formatDate(_startDate);


        setEndDate(_endDate);
        setStartDate(_formattedStartDate);
        fetchStoreSales(dispatch, cookies, storeId, _formattedStartDate, _endDate, page, count, filter);
    },[storeId]);


    return (
        <>
            <Box sx={{ pb: 1, pt:2 }} />
            <StackStyled className="glass-box" direction='row' spacing={2}>
                {ORDERSTATS && Object.entries(ORDERSTATS).map(([_key, _value]) => (
                    <Box key={_key}>
                        <div className='details'>
                            <Typography variant='h6'>₹ {_value}</Typography>
                            <Typography variant='caption'>{STAT_KEYS[_key]}</Typography>
                        </div>
                    </Box>
                )
                )}
                <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            inputFormat="YYYY/MM/DD"
                            value={startDate}
                            onChange={handleStartDate}
                            renderInput={(params) => <TextField {...params} sx={{ width: 170 }} />}
                        />
                        <DesktopDatePicker
                            inputFormat="YYYY/MM/DD"
                            value={endDate}
                            onChange={handleEndDate}
                            renderInput={(params) => <TextField {...params} sx={{ width: 170 }} />}
                        />
                        <Button variant="contained" sx={{ ml: 1 }} onClick={handleSearch}>Go</Button>
                    </LocalizationProvider>
                </Box>
            </StackStyled>
            <Box margin={4} />
            <Grid container justifyContent="space-between" sx={{ pb: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Sales Report
                </Typography>
                <ExportCSV csvHeaders={headers}
                    csvData={orderData()}
                    fileName="sales_report.csv" />
            </Grid>
            {SALESLIST && <FilterContainer filterKey={SALESLIST.filter_params} filterMethod={handleFilterApiCall} />}

            <Card className="glass-box">
                <Scrollbar>

                <TableContainer className="glass-box" >
                    {
                        SALESLIST &&
                        SALESLIST.data &&
                        SALESLIST.data.order_report &&
                        SALESLIST.data.order_report.length > 0
                        ?
                            <Table className="glass-box" ref={tableRef} size="small" aria-label="collapsible table">
                                <TableHead className="glass-box">
                                <TableRow>
                                    <TableCell />
                                    <TableCell align="center">Order ID</TableCell>
                                    <TableCell align="center">Order Date</TableCell>
                                    <TableCell align="center">User</TableCell>
                                    <TableCell align="center">Items</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Order Value</TableCell>
                                    <TableCell align="center">User Paid</TableCell>
                                    <TableCell align="center">Corporate Paid</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody className="glass-box">
                                {SALESLIST.data && SALESLIST.data.order_report &&
                                SALESLIST.data.order_report.length > 0 && SALESLIST.data.order_report.map((row, _id) => (
                                    <Row key={_id} row={row} />
                                ))}
                                </TableBody>
                            </Table>
                        : <Typography variant='h6' sx={{display: 'flex', justifyContent: 'center'}}>No Data Available</Typography>
                    }   
                    </TableContainer>

                </Scrollbar>
            </Card>
            <Pagination ref={_paginationRef} page={page} count={SALESLIST?.pagination?.total_pages} variant="outlined" shape="rounded" onChange={handleChangePage} />

        </>

    );
}