import axios from '../../../utils/axios-configure';
import { API_GET_PARTNER_DISH, API_UPDATE_PARTNER_DISH, API_ADD_PARTNER_DISH, API_UPDATE_PARTNER_DISH_RECIPE, API_GET_PARTNER_MEAL, API_UPDATE_PARTNER_MEAL
} from "../endpoints";

import {getAccessTokenFromCookie} from '../session/api'
import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import { setPartnerDish, setPartnerMealList } from '../../../store/user/reducer';
import { SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { DISH_UPDATE, DISH_ADD, DISH_RECIPE_UPDATE, MEAL_UPDATE } from '../../../constants/message_constants';

export const fetchPartnerDish = (dispatch, cookies) => {
    dispatch(setLoadingStatus({status: true}));  

    const url = `${API_GET_PARTNER_DISH}`;
 
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    if(accessToken != null){
        axios({
            method: "get",
            url,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
          })
          .then((response) => {
            // handle success
            if(response.status === 200){
                dispatch(setPartnerDish({data: response.data.data}));
            }else{
              dispatch(setPartnerDish({data: []}));
            } 
            dispatch(setLoadingStatus({status: false}));  

            // return true;
          })
          .catch((err) => {
            dispatch(setPartnerDish({data: []})); 
            dispatch(setLoadingStatus({status: false}));  
          });
    }else{
      dispatch(setPartnerDish({data: []}));
      dispatch(setLoadingStatus({status: false}));  
    }
}

export const updatePartnerDish = (dispatch, cookies, data) => {
    dispatch(setLoadingStatus({status: true}));  

    const url = `${API_UPDATE_PARTNER_DISH}`;
 
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    if(accessToken != null){
        axios({
            method: "post",
            url,
            data,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
          })
          .then((response) => {
            // handle success
            if(response.status === 200){
                fetchPartnerDish(dispatch, cookies);
                dispatch(setSnackbarMessage({'message': DISH_UPDATE.SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
                dispatch(setSnackbarVisibilityStatus({'status': true }));   
            }else{
              dispatch(setSnackbarMessage({'message': DISH_UPDATE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
              dispatch(setSnackbarVisibilityStatus({'status': true }));   
            } 
            dispatch(setLoadingStatus({status: false}));  

            return true;
          })
          .catch((err) => {
            dispatch(setLoadingStatus({status: false}));  
            dispatch(setSnackbarMessage({'message': DISH_UPDATE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));       });
    }else{
        dispatch(setLoadingStatus({status: false}));  
        dispatch(setSnackbarMessage({'message': DISH_UPDATE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));         }
}

export const addPartnerDish = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));  

  const url = `${API_ADD_PARTNER_DISH}`;

  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
      axios({
          method: "post",
          url,
          data,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
          // handle success

          if(response.status === 200){
              fetchPartnerDish(dispatch, cookies);
              dispatch(setSnackbarMessage({'message': DISH_ADD.SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
              dispatch(setSnackbarVisibilityStatus({'status': true }));
          }else{
            dispatch(setSnackbarMessage({'message': DISH_ADD.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));   
          }
          dispatch(setLoadingStatus({status: false}));

          // return true;
        })
        .catch((err) => {
          console.log(err);
          dispatch(setLoadingStatus({status: false}));  
          dispatch(setSnackbarMessage({'message': DISH_ADD.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
          dispatch(setSnackbarVisibilityStatus({'status': true }));       });
  }else{
    console.log("err");

      dispatch(setLoadingStatus({status: false}));  
      dispatch(setSnackbarMessage({'message': DISH_ADD.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
      dispatch(setSnackbarVisibilityStatus({'status': true }));         
  }
}


export const updateDishRecipe = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));  

  const url = `${API_UPDATE_PARTNER_DISH_RECIPE}`;

  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
      axios({
          method: "post",
          url,
          data,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
          // handle success

          if(response.status === 200){
              fetchPartnerDish(dispatch, cookies);
              dispatch(setSnackbarMessage({'message': DISH_RECIPE_UPDATE.SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
              dispatch(setSnackbarVisibilityStatus({'status': true }));
          }else{
            dispatch(setSnackbarMessage({'message': DISH_RECIPE_UPDATE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));   
          }
          dispatch(setLoadingStatus({status: false}));

          // return true;
        })
        .catch((err) => {
          console.log(err);
          dispatch(setLoadingStatus({status: false}));  
          dispatch(setSnackbarMessage({'message': DISH_RECIPE_UPDATE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
          dispatch(setSnackbarVisibilityStatus({'status': true }));       });
  }else{
    console.log("err");

      dispatch(setLoadingStatus({status: false}));  
      dispatch(setSnackbarMessage({'message': DISH_RECIPE_UPDATE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
      dispatch(setSnackbarVisibilityStatus({'status': true }));         
  }

}

export const fetchPartnerMeals = (dispatch, cookies, page = 1, searchValue='', filterBy = { key: '', value: '' }) => {
  dispatch(setLoadingStatus({status: true}));

  // TODO: replace term with search term
  let url = `${API_GET_PARTNER_MEAL}?page=${page}&count=500`;

  if (searchValue !== ''){
    url += `&term=${searchValue}`;
  }

  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
      axios({
          method: "get",
          url,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
          // handle success
          if(response.status === 200){
            console.log(response.data);
              dispatch(setPartnerMealList({data: response.data}));
          }else{
            dispatch(setPartnerMealList({data: {'data' : [], 'pagination': {'page': 1, 'per_page': 10, 'total_pages': 1}}}));
          } 
          dispatch(setLoadingStatus({status: false}));  

          // return true;
        })
        .catch((err) => {
          // dispatch(setPartnerMealList({data: []})); 
          dispatch(setPartnerMealList({data: {'data' : [], 'pagination': {'page': 1, 'per_page': 10, 'total_pages': 1}}}));
          dispatch(setLoadingStatus({status: false}));  
        });
  }else{
    // dispatch(setPartnerMealList({data: []}));
    dispatch(setPartnerMealList({data: {'data' : [], 'pagination': {'page': 1, 'per_page': 10, 'total_pages': 1}}}));
    dispatch(setLoadingStatus({status: false}));  
  }
}

export const addPartnerMeal = (dispatch, cookies, data) => {
dispatch(setLoadingStatus({status: true}));  

const url = `${API_UPDATE_PARTNER_MEAL}`;

const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
if(accessToken != null){
    axios({
        method: "post",
        url,
        data,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
      })
      .then((response) => {
        // handle success

        if(response.status === 200){
            fetchPartnerMeals(dispatch, cookies);
            dispatch(setSnackbarMessage({'message': MEAL_UPDATE.SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));
        }else{
          dispatch(setSnackbarMessage({'message': MEAL_UPDATE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
          dispatch(setSnackbarVisibilityStatus({'status': true }));   
        }
        dispatch(setLoadingStatus({status: false}));

        // return true;
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoadingStatus({status: false}));  
        dispatch(setSnackbarMessage({'message': MEAL_UPDATE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));       });
}else{
  console.log("err");

    dispatch(setLoadingStatus({status: false}));  
    dispatch(setSnackbarMessage({'message': MEAL_UPDATE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
    dispatch(setSnackbarVisibilityStatus({'status': true }));         
}
}
