import { useState, useEffect,useRef } from "react";
import { Box, Button, Paper, Card, Grid, Stack, TextField, styled, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Collapse, Stepper, Step, StepLabel, TablePagination, TableFooter } from "@mui/material";
import { filter } from 'lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { getIcon } from "@iconify/react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { subDays } from "date-fns";

import SearchNotFound from "../../../components/SearchNotFound";
import Scrollbar from "../../../components/Scrollbar";
import FilterContainer from "./FilterContainer";
import { getComparator } from "../../../utils/sort_helper";
import { formatDate } from "../../../utils/date";
import { getActiveStoreId, getStoreRoles } from "../../../store/partner_store/reducer";
import { fetchStoreRoles } from "../../../data/network/store/role";

export const StoreRoleList = () => {

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(10);
    const [open, setOpen] = useState(false);  
    const [selectedRow, setSelectedRow] = useState(null);
    const [filter, setFilter] = useState('');


    const tableRef = useRef();
    const _paginationRef = useRef();

    const dispatch = useDispatch();
    const [cookies] = useCookies(['mobile', 'access_token', 'session']);

    const ROLELIST = useSelector((state) => getStoreRoles(state));
    const storeId = useSelector((state) => getActiveStoreId(state));


    const handleSearch = () => {
        setPage(1);
        fetchStoreRoles(dispatch, cookies, storeId, 1, count, filter);
    }

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
        fetchStoreRoles(dispatch, cookies, storeId, newPage, count, filter);
    };

    const handleFilterApiCall = (filterBy) => {
        setFilter(filterBy);
        setPage(1);
        fetchStoreRoles(dispatch, cookies, storeId, 1, count, filterBy);
    }

    useEffect(() => {
        fetchStoreRoles(dispatch, cookies, storeId, page, count, filter);
    },[]);
    
    return (
        <>
            <Box sx={{ pb: 1, pt:2 }} />
            
            <Box margin={4} />
            <Grid container justifyContent="space-between" sx={{ pb: 2 }}>
                <Typography variant="h4" gutterBottom>
                    All Roles
                </Typography>
            </Grid>
            {ROLELIST && <FilterContainer filterKey={ROLELIST.filter_params} filterMethod={handleFilterApiCall} />}

            <Card className="glass-box">
                <Scrollbar>

                <TableContainer className="glass-box" >
                    {
                        ROLELIST &&
                        ROLELIST.data &&
                        ROLELIST.data.length > 0
                        ?
                            <Table className="glass-box" ref={tableRef} size="small" aria-label="collapsible table">
                                <TableHead className="glass-box">
                                <TableRow>
                                    <TableCell />
                                    <TableCell align="center">Name</TableCell>
                                    <TableCell align="center">Display Name</TableCell>
                                    {/* <TableCell align="center">Name</TableCell> */}
                                    {/* <TableCell align="center">Action</TableCell> */}
                                </TableRow>
                                </TableHead>
                                <TableBody className="glass-box">
                                {ROLELIST.data &&
                                ROLELIST.data.length > 0 && ROLELIST.data.map((row, _id) => (
                                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                            
                                            <TableCell component="th" scope="row">
                                                {row.id}
                                            </TableCell>
                                            
                                                <TableCell align="center">{row.name}</TableCell>
                                                <TableCell align="center">{row.display_name}</TableCell>
                                                
                                                
                                            {/* <TableCell align="center">
                                                    <Button style={{ padding: '2px 10px', minWidth: 'auto', fontSize: '12px', marginRight: '5px' }} size='small' color='info' variant='contained'
                                                        onClick={() => {
                                                        setOpen(true);
                                                        setSelectedRow(row);
                                                    }}
                                                    >View</Button>

                                                
                                                </TableCell> */}
                                            </TableRow>
                                            
                                ))}
                                </TableBody>
                            </Table>
                        : <Typography variant='h6' sx={{display: 'flex', justifyContent: 'center'}}>No Data Available</Typography>
                    }   
                    </TableContainer>

                </Scrollbar>
            </Card>
            {ROLELIST && <Pagination ref={_paginationRef} page={page} count={ROLELIST?.pagination?.total_pages} variant="outlined" shape="rounded" onChange={handleChangePage} />}

        </>

    );
}