import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";

export default function BuildForm({data}){

    useEffect(() => {
        console.log(data);
    },[]);

    return (
        <Grid container sx={{padding:3}} flexDirection={'row'} justifyContent={'flex-start'}>
            <Grid container item sx={{width: '20%'}}>
                <Typography variant="h5">
                    {data.label}
                </Typography>
            </Grid>
            <Grid container item sx={{width: '80%'}}>
                {
                    data.value_type === 'text'
                    && <input type="text" id={data.key} name={data.key} value={data.default_value} />
                }

                {
                    data.value_type === 'option'
                    && <select id={data.key} name={data.key}>
                        {
                            data.options.map((e, i) => {
                                return <option value={e.key}>{e.label}</option>
                            })
                        }
                    </select>
                }

                {
                    data.value_type === 'int'
                    && <input type="number" id={data.key} name={data.key} value={data.default_value} />
                }

                {    
                    data.value_type === 'boolean'
                    && <select id={data.key} name={data.key}>
                        <option value={'yes'}>{'Yes'}</option>
                        <option value={'no'}>{'No'}</option>
                    </select>
                }

            </Grid>
        </Grid>
    );
}