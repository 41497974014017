
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Stack, Typography, Box, TextField, Select, MenuItem, Button, styled } from '@mui/material';
import { useCookies } from 'react-cookie';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import { updateCorporateUser, fetchCorporateUsers } from '../../data/network/corporate/api';
import { formatDate } from '../../../utils/date';
import { updateStoreStaff } from '../../../data/network/store/staff';
import { getActiveStoreId, getStoreRoles } from '../../../store/partner_store/reducer';
// import { getActiveCorporateClient } from '../../store/user/reducer';

const TextFieldStyle = styled(TextField)(() => ({
    '& .MuiInputBase-input': {
        padding: '8px 14px',
    }
}));

const SelectStyle = styled(Select)(() => ({
    '& .MuiSelect-select': {
        padding: '8px 14px',
    }
}));


export const StaffDetail = ({ data, visible, setOpen, editable=true, create=true }) => {
    const [editEnable, setEditEnable] = useState(true);
    const [rowData, setRowData] = useState({});
    const [date, setDate] = useState(null);

    const dispatch = useDispatch();
    const storeId = useSelector((state) => getActiveStoreId(state));
    const [cookies] = useCookies(['mobile', 'session', 'access_token']);

    const storeRoles = useSelector(state => getStoreRoles(state));

    useEffect(() => {
        
        if (data != null){
            const roleIds = []
            console.log(data);
            for (let i=0; i<data.roles.length; i+=1) {
                roleIds.push(data.roles[i].id);
            }
            
            setRowData({ ...data, 'role_ids': roleIds });
        }else{
            setRowData({'name': '', 'mobile_no' : '', 'role_ids': []});
        }
        setEditEnable(!editable);
    }, [data])

    const handleChange = (event) => {
        setRowData({ ...rowData, [event.target.name]: event.target.value })
    }

    const handleDate = (newValue) => {
        setDate(formatDate(newValue));
        setRowData({ ...rowData, dob: formatDate(newValue) })
    }



    const handleUpdate = () => {
        // alert('call update api here');
        // updateCorporateUser(dispatch, cookies, userData, activeClient)

        updateStoreStaff(dispatch, cookies, storeId, rowData);
        setOpen(false);
    }

    return (
        <Drawer
            anchor='right'
            open={visible}
            onClose={() => setOpen(false)}
        >
            <Stack sx={{ width: 400 }} style={{ padding: '20px', gap: '10px' }}>
                <Box sx={{ pb: 2 }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h6'>Detail View</Typography>
                    <Button size='small' color='info' onClick={() => setEditEnable(false)}>Edit</Button>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Name</Typography>
                    <TextFieldStyle disabled={editEnable} name='name' value={rowData?.name} onChange={handleChange} />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Mobile</Typography>
                    <TextFieldStyle disabled={editEnable} name='mobile_no' value={rowData?.mobile_no} onChange={handleChange} />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Role</Typography>
                    {/* <TextFieldStyle disabled={editEnable} name='role' value={rowData?.role} onChange={handleChange} /> */}
                    <SelectStyle
                        multiple
                        value={rowData?.role_ids}
                        onChange={handleChange}
                        displayEmpty
                        disabled={editEnable}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ width: 220 }}
                        name="role_ids"
                    >
                        {
                            storeRoles && storeRoles.data && storeRoles.data.map(role => <MenuItem value={role.id} >{role.display_name}</MenuItem>)
                        }
                    </SelectStyle>
                </Box>
                {/* {
                    !create && <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Status</Typography>
                    <SelectStyle
                        value={rowData?.is_active}
                        onChange={handleChange}
                        displayEmpty
                        disabled={editEnable}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ width: 220 }}
                        name="is_active"
                    >
                        <MenuItem value="true" >Active</MenuItem>
                        <MenuItem value="false" >InActive</MenuItem>
                    </SelectStyle>
                </Box>
} */}
                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Delivered at</Typography>
                    <TextFieldStyle disabled={editEnable} name='delivery_dt' type='text' value={orderData?.delivery_dt} onChange={handleChange} />
                </Box> */}
                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Delivered at</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            inputFormat="YYYY/MM/DD"
                            value={date}
                            onChange={handleDate}
                            disabled={editEnable}
                            renderInput={(params) => <TextFieldStyle {...params} sx={{ width: 220 }} />}
                        />
                    </LocalizationProvider>
                </Box>
                 */}
                {!editEnable && <Button sx={{ mt: 2, pt: 1, pb: 1 }} size='small' variant='contained' onClick={handleUpdate}>
                    {create ? <Typography>Create</Typography>
                     : <Typography>Update</Typography>
                    }
                </Button>}
            </Stack>
        </Drawer>
    )

}