import { useState, useEffect, Children } from 'react';
import { useSelector, useDispatch } from 'react-redux';  
import { Form, Formik, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';

// material
import { Grid, Button, Stack, Typography, TextField, Box, InputAdornment, DialogContent, Dialog, MenuItem, AppBar, Container, Toolbar, IconButton, Stepper, Step, StepLabel, Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { LoadingButton } from '@mui/lab';

// components 
import { getActiveClient } from '../../../store/coach/reducer'; 
import { addPartnerDish, addPartnerMeal } from '../../../data/network/dish/api'; 
import { resetAddActivityStatus } from '../../../store/activity/reducer';
import { getPartnerDish, getUserId } from '../../../store/user/reducer';
import Iconify from '../../../components/Iconify';
import { MEAL_CREATION_FORM_STEPS } from '../../../constants/meal_constants';
import { getMealCreationCurrentStep, setMealCreationCurrentStep, resetMealCreationState, getSelectedMeal } from '../../../store/meal/reducer';
import CreateMealDishInfo from './create_meal/CreateMealDishInfo';

export default function CreateMealForm( {handleClose} ) {
    const theme = useTheme();
    const clientDetails = useSelector(state => getActiveClient(state));
    const partnerId = useSelector((state) => getUserId(state));

    const dishes = useSelector((state) => getPartnerDish(state));
    
    const currentStep = useSelector((state) => getMealCreationCurrentStep(state));
    const selectedMeal = useSelector((state) => getSelectedMeal(state)); 

    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const dispatch = useDispatch();
    
    const [startDateValue, setStartDateValue] =  useState(
        new Date()
    );

    const [endDateValue, setEndDateValue] =  useState(
        new Date()
    );

    const [imageValue, setImageValue] = useState(null);

    const handleCreateMeal = () => {
        addPartnerMeal(dispatch, cookies, {
            'display_name': formik.values.name.replace(' ','_').toLowerCase(),
            'name': formik.values.name,
            'description': formik.values.description,
            // 'ingredients': "",
            // 'sku': formik.values.sku,
            // 'effective_price': formik.values.effective_price.toString(),
            // 'mark_price': formik.values.mark_price.toString(),
            // 'meal_effective_price' : formik.values.meal_effective_price.toString(),
            // 'meal_mark_price' : formik.values.meal_mark_price.toString(),
            // 'is_new_arrival' : 1,
            // 'portion_size': "1",
            // 'image_url' : "",
            // 'extra_info': "",
            // 'categories' : ["veg"],
            // 'type' : formik.values.type,
            // 'dish_nutrition_info' : [{
            //     "name" : "calories",
            //     "value" : formik.values.calories.toString()
            // },{
            //     "name" : "protein",
            //     "value" : formik.values.protein.toString()
            // },{
            //     "name" : "fat",
            //     "value" : formik.values.fat.toString()
            // },{
            //     "name" : "fiber",
            //     "value" : formik.values.fiber.toString()
            // },{
            //     "name" : "carbohydrates",
            //     "value" : formik.values.carbohydrates.toString()
            // }]
        });
        // dispatch(resetAddMealStatus());

        dispatch(setMealCreationCurrentStep({'step': MEAL_CREATION_FORM_STEPS.DISH_INFO}));

        // handleClose();
//        handleClose();
    };

    const mealType = [
        {
            "display_name"  : "Public",
            "value" : "public"
        },
        {
            "display_name"  : "Private",
            "value" : "private"
        }
    ];
    

    const MealSchema = Yup.object().shape({
        name: Yup.string().required('name is required')  ,
        description: Yup.string().required('Description is required')  ,
        // ingredients:Yup.string(),
        // sku: Yup.string(), 
        // effective_price: Yup.string().required('Effective Price is required')  ,
        // mark_price: Yup.string().required('Mark Price is required'),
        // meal_effective_price: Yup.string().required('Meal Effective Price is required')  ,
        // meal_mark_price: Yup.string().required('Meal Mark Price is required'),
        // // category: Yup.string().required('Category is required'),
        // calories: Yup.string().required('Calories is required'),
        // fat: Yup.string().required('Fat is required'),
        // protein: Yup.string().required('Protein is required'),
        // carbohydrates: Yup.string().required('Carbohydrates is required'),
        // fiber: Yup.string().required('Fiber is required'),
        // type: Yup.string().required('Type is required'),
    });

    const formik = useFormik({
        initialValues: {
          name: selectedMeal != null ? selectedMeal.display_name : '',
          description: selectedMeal != null ? selectedMeal.description : '',
          type: selectedMeal != null && selectedMeal.is_private ? 'private' : 'public'
        },
        enableReinitialize : true,
        validationSchema: MealSchema,
        onSubmit: () => {
  
        },
    });
    

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    const handleBasicInfoSubmit = () => {

        const mealBody = {
            'display_name' : formik.values.name,
            'name' : formik.values.name.replace(' ','_').toLowerCase(),
            'description' : formik.values.description,
        }

        if (selectedMeal && selectedMeal.meal_id) {
            mealBody.meal_id = selectedMeal.meal_id
            mealBody.dishes = selectedMeal.dishes
        }else{
            mealBody.dishes = [];
        }

        // const data = [
        //     {
        //         "meal_id" : selectedMeal.id,
        //         "name": selectedMeal.name,
        //         // "display_name": ,
        //         // "description" : "",
        //         "dishes" : selectedMeal.dishes
        //     }
        // ];

        addPartnerMeal(dispatch, cookies, {'data': [mealBody]})

        dispatch(setMealCreationCurrentStep({'step': MEAL_CREATION_FORM_STEPS.DISH_INFO}))
    };

    return (  
        <Container>
            <Dialog 
                maxWidth="lg"
                open
                onClose={handleClose}
                PaperProps={{
                sx: {
                    minHeight: '90vh',
                    maxHeight: '90vh'
                }
                }}
                // sx={{height:'90vh'}}
                // sx={{height:'80vh', width:'80%', margin:'10%', overflowY:'auto' }}
                >

                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Grid container flexDirection={'column'} justifyContent={'flex-start'} padding={1}>
                            <Grid container item flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Grid item>
                                    <Typography variant="h3">
                                        {
                                            selectedMeal 
                                            ? selectedMeal.display_name
                                            : 'Add new meal'
                                        }
                                    </Typography>
                                </Grid>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <Iconify icon="eva:close-circle-outline" sx={{height:40, width:40, cursor:'pointer'}}/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                
                <DialogContent > 
                    <FormikStepper
                        initialValues={{
                            // firstName: '',
                            // lastName: '',
                        }}
                        onSubmit={async (values) => {
                            // await sleep(3000);
                            console.log('values', values);
                        }}
                    >
                        <FormikStep label="Basic Details">
                            <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                    <Box margin={8} />

                                    <Grid sx={{padding:2, borderRadius: 2}}> 
                                        <Grid container direction="column">
                                            <Grid item container direction="column" justifyContent="center">

                                                <Stack direction={'column'} spacing={2}>
                                                    <Typography variant="h5">
                                                        Meal plan name
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        // label="Name"
                                                        // defaultValue={selectedMeal && selectedMeal.display_name}
                                                        placeholder='Enter a name for meal plan'
                                                        {...getFieldProps('name')}
                                                        error={Boolean(touched.name && errors.name)}
                                                        helperText={touched.name && errors.name}
                                                    />
                                                </Stack>
                                                <Box margin={2} />
                                                <Stack direction={'column'} spacing={2}>
                                                    <Typography variant="h5">
                                                        Description 
                                                    </Typography>
                                                    <TextField
                                                        multiline
                                                        rows={5}
                                                        // defaultValue={selectedMeal && selectedMeal.description}
                                                        fullWidth
                                                        placeholder='Enter description for meal plan'
                                                        // label="Description"
                                                        {...getFieldProps('description')}
                                                        error={Boolean(touched.description && errors.description)}
                                                        helperText={touched.description && errors.description}
                                                    />
                                                    
                                                </Stack>
                                                
                                                <Box margin={4} />

                                                <Grid direction="column" justifyContent="center" alignItems="center" container style={{position:'absolute', left:0, bottom: '40px'}}>
                                                    <Box style={{width:'15%'}}>
                                                        {  
                                                            <LoadingButton fullWidth size="large" onClick={() => 
                                                                handleBasicInfoSubmit()                                                                
                                                            } variant="contained" loading={isSubmitting}>
                                                                <Grid container justifyContent={'center'} alignItems={'center'} columnGap={1}>
                                                                    <Typography variant="body1">
                                                                        Next
                                                                    </Typography>
                                                                    <Iconify icon={'eva:arrow-forward-outline'} style={{color: '#fff', fontSize:'16px'}} />
                                                                </Grid>
                                                            </LoadingButton>
                                                        }

                                                    </Box>
                                                </Grid>
                                                
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            </FormikProvider>
                        </FormikStep>
                        <FormikStep label="Dish Info">
                            <FormikProvider>
                                <CreateMealDishInfo />
                            </FormikProvider>  
                        </FormikStep>
                        {/* <FormikStep label="Additional Info">
                            <FormikProvider>

                                <Grid container padding={2}>
                                    
                                    <Grid container flexDirection={'column'} margin={4}>
                                        <Typography variant="h5">Upload meal image</Typography>
                                        <Box margin={1} />
                                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                            <input
                                                type="file"
                                                name="avatar"
                                                onChange={(event) => {                                                            
                                                    setImageValue(event.currentTarget.files[0]);
                                                }}
                                            />
                                        </Stack>
                                    </Grid>
                                    

                                    <Grid container flexDirection={'column'} margin={4}>
                                        <Typography variant="h5">Tags</Typography>
                                        <Typography variant="body2">For eg: Weight Gain, Protein Rich, Diabetic Friendly etc</Typography>
                                        
                                        
                                        <Box margin={1} />
                                        
                                        <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                                            <TextField 
                                                fullWidth
                                                label=""
                                                placeholder='Start typing'
                                            />
                                        </Stack>
                                    </Grid>
                                    
                                    
                                    <Box margin={2} />

                                    <Grid direction="row" justifyContent="space-evenly" alignItems="center" container style={{position:'absolute', left:0, bottom: '40px'}}>
                                            <Box style={{width:'15%'}}>
                                                {  
                                                    <LoadingButton fullWidth size="large" onClick={() => 
                                                        dispatch(setMealCreationCurrentStep({'step': MEAL_CREATION_FORM_STEPS.DISH_INFO}))
                                                    } variant="contained" loading={isSubmitting}>
                                                        <Grid container justifyContent={'center'} alignItems={'center'} columnGap={1}>
                                                            <Iconify icon={'eva:arrow-back-outline'} style={{color: '#fff', fontSize:'16px'}} />
                                                            <Typography variant="body1">
                                                                Back
                                                            </Typography>
                                                        </Grid>
                                                    </LoadingButton>
                                                }

                                            </Box>
                                            <Box style={{width:'15%'}}>
                                                {  
                                                    <LoadingButton fullWidth size="large" onClick={() => 
                                                        dispatch(setMealCreationCurrentStep({'step': MEAL_CREATION_FORM_STEPS.ACK}))
                                                    } variant="contained" loading={isSubmitting}>
                                                        <Grid container justifyContent={'center'} alignItems={'center'} columnGap={1}>
                                                            <Typography variant="body1">
                                                                Next
                                                            </Typography>
                                                            <Iconify icon={'eva:arrow-forward-outline'} style={{color: '#fff', fontSize:'16px'}} />
                                                        </Grid>
                                                    </LoadingButton>
                                                }

                                            </Box>
                                        </Grid>
                                    </Grid>
                            </FormikProvider>  
                        </FormikStep> */}
                        <FormikStep label="Overview">
                            <FormikProvider>
                                <Box margin={4} />
                                
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        
                                        <Grid container flexDirection={'column'} rowGap={4} justifyContent={'center'} alignItems={'center'}>
                                            <Iconify icon={'eva:checkmark-circle-outline'} sx={{fontSize: '128px', color: 'green'}} />
                                        </Grid>
                                        
                                    </Stack>

                                    <Box margin={8} />

                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} padding={2}>
                                        <Grid container flexDirection={'row'} rowGap={4} justifyContent={'flex-start'} alignItems={'center'}>
                                            <Typography variant="h3">
                                                Congratulations, You have successfully updated a meal.
                                            </Typography>
                                        </Grid>
                                        
                                    </Stack>
                                    
                                    <Box margin={4} />

                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} padding={2}>

                                        <Grid container flexDirection={'column'} rowGap={4} justifyContent={'flex-start'} alignItems={'flex-start'}>

                                            <Grid container rowGap={2} flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
                                                <Grid container flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Typography variant="h5" style={{width: '20%'}}>Meal plan name : </Typography>
                                                    <Typography variant="h5">{selectedMeal && selectedMeal.display_name}</Typography>
                                                </Grid>
                                                <Grid container flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Typography variant="h5" style={{width: '20%'}}>Dishes : </Typography>
                                                    <Typography variant="h5">{selectedMeal && selectedMeal.dishes && selectedMeal.dishes.length}</Typography>
                                                </Grid>
                                                <Grid container flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Typography variant="h5" style={{width: '20%'}}>Tags : </Typography>
                                                    <Typography variant="h5">{selectedMeal && selectedMeal.display_name}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        
                                    </Stack>

                                    <Box margin={4} />

                                    <Grid direction="row" justifyContent="center" alignItems="center" container style={{position:'absolute', left:0, bottom: '40px'}}>
                                        
                                        <Box style={{width:'15%'}}>
                                            {  
                                                <LoadingButton fullWidth size="large" onClick={() => 
                                                    {
                                                        dispatch(resetMealCreationState())
                                                        handleClose();
                                                    }
                                                } variant="contained" loading={false}>
                                                    <Grid container justifyContent={'center'} alignItems={'center'} columnGap={1}>
                                                        <Typography variant="body1">
                                                            Close
                                                        </Typography>
                                                        {/* <Iconify icon={'eva:arrow-forward-outline'} style={{color: '#fff', fontSize:'16px'}} /> */}
                                                    </Grid>
                                                </LoadingButton>
                                            }

                                        </Box>

                                        <Box padding={2} style={{width:'20%'}}>
                                            {  
                                                <LoadingButton fullWidth size="large" onClick={() => 
                                                    dispatch(resetMealCreationState())
                                                } variant="contained" loading={false}>
                                                    <Grid container justifyContent={'center'} alignItems={'center'} columnGap={1}>
                                                        <Typography variant="body1">
                                                            Create another
                                                        </Typography>
                                                        <Iconify icon={'eva:arrow-forward-outline'} style={{color: '#fff', fontSize:'16px'}} />
                                                    </Grid>
                                                </LoadingButton>
                                            }
                                        </Box>
                                    </Grid>
                            </FormikProvider>
                        </FormikStep>
                    </FormikStepper>
                </DialogContent>
            </Dialog>
        </Container>
    );
}


export function FormikStep({ children }) {
    return <>{children}</>;
}

export function FormikStepper({ children, ...props }) {
    const childrenArray = Children.toArray(children);
    const [step, setStep] = useState(0);
    const currentChild = childrenArray[step];
    const [completed, setCompleted] = useState(false);

    // const otpVerified = useSelector((state) => getOTPVerificationStatus(state));
    // const clientDetailsSubmitted = useSelector((state) => getClientDetailStatus(state));
    // const clientPlanSelectionStatus = useSelector((state) => getClientPlanSelectionStatus(state));
    // const subscriptionPlanList = useSelector((state) => getSubscriptionPlanList(state));
  
    const currentStep = useSelector((state) => getMealCreationCurrentStep(state));

    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const dispatch = useDispatch();
  
    function isLastStep() {
      return step === childrenArray.length - 1;
    }
  
    useEffect(() => { 
        console.log(currentStep);
        if (currentStep){
            switch(currentStep.step){
                case MEAL_CREATION_FORM_STEPS.BASIC_DETAILS.step:
                    setStep(0);
                    break;
                case MEAL_CREATION_FORM_STEPS.DISH_INFO.step:
                    setStep(1);
                    break;
                // case MEAL_CREATION_FORM_STEPS.TAGS.step:
                //     setStep(2);
                //     break;
                case MEAL_CREATION_FORM_STEPS.ACK.step:
                    setStep(2);
                    break;
                default:
                setStep(0);
                break;
            }
        }
    },[currentStep]);

    return (
      <Formik
        {...props}
        validationSchema={currentChild && currentChild.props.validationSchema}
        onSubmit={async (values, helpers) => {
          if (isLastStep()) {
            await props.onSubmit(values, helpers);
            setCompleted(true);
          } else {
            setStep((s) => s + 1);
            helpers.setTouched({});
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <Stepper alternativeLabel activeStep={step}>
              {childrenArray.map((child, index) => (
                <Step key={child.props.label} completed={step > index || completed}>
                  <StepLabel>{child.props.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
  
            {currentChild}
          </Form>
        )}
      </Formik>
    );
}
