import { useSelector, useDispatch } from 'react-redux';  
import { Form, FormikProvider, useFormik } from 'formik';
import { useState, useEffect  } from 'react';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';

// material
import { Grid, Button, Stack, Typography, TextField, Box, InputAdornment, DialogContent, Dialog } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components 
import { getActiveClient } from '../../../store/coach/reducer'; 
import { addActivity, fetchActivity } from '../../../data/network/activity/api'; 
import { scheduleUserActivity, fetchActivitySchedule } from '../../../data/network/client/api';
import { getActivityCategoryList, getActivityList } from '../../../store/activity/reducer';
import { formatDate } from '../../../utils/date';
import { setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import { SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { ACTIVITY_CREATE } from '../../../constants/message_constants';

export default function ScheduleActivityForm( {userId, date, handleClose, visibleRange} ) {
    const theme = useTheme(); 
 
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const dispatch = useDispatch(); 
    const activityList = useSelector(state => getActivityList(state));
    const activityCategoryList = useSelector(state => getActivityCategoryList(state));
    
    const [activityCategory , setActivityCategory ] = useState("all");
    const [activityMetaId, setActivityMetaId] = useState(activityList[0].activity_id);

    useEffect(() => {
        // fetchActivity(dispatch, cookies);
    },[])
  
    const handleScheduleActivity = () => {

        if(activityMetaId !== null){

            scheduleUserActivity(dispatch, cookies, {
                'activity_meta_id': activityMetaId,
                'date': date,
                'user_id': userId , 
                'standard_duration': formik.values.activity_duration ,
                'standard_burned_calories': formik.values.calories_burnt , 
                'standard_rest_duration': formik.values.rest_duration
            }, false);

            fetchActivitySchedule(dispatch, cookies, {
                user_id: userId,
                // "start_date" : formatDate(visibleRange.start_date, "yyyy-MM-DD"),
                // "end_date" : formatDate(visibleRange.end_date, "yyyy-MM-DD"), 
            });

            handleClose();
        }else{
            dispatch(setSnackbarMessage({'message': ACTIVITY_CREATE.MISSING_ACTIVITY , 'severity': SNACKBAR_SEVERITY.ERROR}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));     
        }
    };

    const ActivitySchema = Yup.object().shape({
        activity_meta_id: Yup.string().required('Activity is required'),
        activity_duration: Yup.string().required('Activity Duration is required')  ,
        calories_burnt: Yup.string().required('Calories Burnt is required')  , 
        rest_duration: Yup.string().required('Rest Duration is required')  , 
    });
    
    const formik = useFormik({
        initialValues: {
            activity_meta_id: (activityList != null && activityList[0]) ? activityList[0].activity_id : '',
            activity_duration: (activityList != null && activityList[0]) ? activityList[0].standard_duration : '',
            calories_burnt: (activityList != null && activityList[0]) ? activityList[0].standard_burned_calories : '',
            rest_duration: (activityList != null && activityList[0]) ? activityList[0].standard_rest_duration : ''
        },
        validationSchema: ActivitySchema,
        onSubmit: () => {

        },
    });

    const handleActivityCategoryChange = (e) => { 
        setActivityCategory(e.target.value); 

        setActivityMetaId(null);

        if(activityList !== null){

            for(let i=0; i< activityList.length ; i+=1){
                  
                if(e.target.value === "all" || activityList[i].category === e.target.value ){
                    setActivityMetaId(activityList[i].activity_id); 
                    console.log(activityList[i]);
                    break ; 
                } 
            }
        } 
    }


    const handleActivityChange = (e) => {

        setActivityMetaId(e.target.value);
        if(activityList !== null){
            activityList.forEach(element => {
                if(element.activity_id === parseInt(e.target.value,10)){

                    formik.setFieldValue('activity_duration', element.standard_duration);
                    formik.setFieldValue('calories_burnt', element.standard_burned_calories);
                    formik.setFieldValue('rest_duration', element.standard_rest_duration);

                }
            });
        }
    }

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (  
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid item container direction="column" justifyContent="center">                                    
                    <Box margin={2} />
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth
                            select
                            label="Select Activity Category"
                            onChange={handleActivityCategoryChange}
                            // {...getFieldProps('activity_meta_id')}
                            error={Boolean(touched.activity_category_id && errors.activity_category_id)}
                            helperText={touched.activity_category_id && errors.activity_category_id}
                            SelectProps={{
                                native: true,
                            }}
                        >
                            <option key="all" value="all">
                                Show All
                            </option>
                            {activityCategoryList != null && activityCategoryList.map((option) => (
                                <option key={option.display_name} value={option.display_name}>
                                    {option.display_name}
                                </option>
                            ))}
                        </TextField>
                    </Stack>
                    <Box margin={2} />
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth
                            select
                            label="Select Activity"
                            onChange={handleActivityChange}
                            // {...getFieldProps('activity_meta_id')}
                            error={Boolean(touched.activity_meta_id && errors.activity_meta_id)}
                            helperText={touched.activity_meta_id && errors.activity_meta_id}
                            SelectProps={{
                                native: true,
                            }} >

                            {activityList != null && activityList.map((option) => (
                                ((activityCategory != null && activityCategory !== "all" && option.category===activityCategory) || activityCategory === "all") 
                                && <option key={option.activity_id} value={option.activity_id}>
                                    {option.display_name}
                                </option>
                            ))}

                        </TextField>
                    </Stack>
                    <Box margin={2} />

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            type="number"
                            fullWidth
                            label="Activity Duration"
                            {...getFieldProps('activity_duration')}
                            error={Boolean(touched.activity_duration && errors.activity_duration)}
                            helperText={touched.activity_duration && errors.activity_duration}
                            endAdornment={<InputAdornment position="end">mins</InputAdornment>}
                        />
                        <TextField
                            fullWidth
                            type="number"
                            label="Rest Duration"
                            {...getFieldProps('rest_duration')}
                            error={Boolean(touched.rest_duration && errors.rest_duration)}
                            helperText={touched.rest_duration && errors.rest_duration}
                            endAdornment={<InputAdornment position="end">mins</InputAdornment>}
                        />
                        <TextField
                            type="number"
                            fullWidth
                            label="Calorie Burnt"
                            {...getFieldProps('calories_burnt')}
                            error={Boolean(touched.calories_burnt && errors.calories_burnt)}
                            helperText={touched.calories_burnt && errors.calories_burnt}
                            endAdornment={<InputAdornment position="end">Kcal</InputAdornment>}
                        />
                    </Stack>
                    <Box margin={2} />
                </Grid>

                <Grid item container direction="row" justifyContent="center">
                    <Button variant='contained' onClick={() => {
                        handleScheduleActivity();
                    }}>
                        Schedule
                    </Button>                            
                </Grid>
            </Form>
        </FormikProvider>
    );
}
