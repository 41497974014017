import { useEffect, useState } from "react";
import { Container, Grid, Box, Stack, FormLabel, RadioGroup, FormControlLabel, 
    Radio, Divider, Button, Typography, Accordion,AccordionDetails,AccordionSummary, Card, Checkbox, FormGroup, SwipeableDrawer, FormControl, Tabs, Tab
} from "@mui/material";
import { LoadingButton, TabPanel, TabContext } from "@mui/lab";

import { useCookies } from "react-cookie";

import { Field } from "formik";
import {useDispatch, useSelector} from 'react-redux';
import {MEAL_TYPE, ONBOARDING_FORM_STEPS, ORDER_TYPE, PAYMENT_MODE, PAYMENT_STATUS, SNACKBAR_SEVERITY} from '../../../constants/app_constants';
import { setPaymentMode, setPaymentStatus, getClientSubscriptionCartDetails, getClientSubscriptionPlanDetails, getClientSubscriptionCartTotalAmount, getActiveClientAddress, getActiveClient, setActiveClientLunchAddress, setActiveClientDinnerAddress, getActiveClientLunchAddress, getActiveClientDinnerAddress, getClientOrderType, getClientMealPreference } from "../../../store/coach/reducer";
import { setOnboardingCurrentStep } from "../../../store/onboarding/reducer";
import { formatDate } from "../../../utils/date";
import Iconify from '../../../components/Iconify';
import { confirmOrder, fetchClientAddress, fetchClientSubscriptionCart } from "../../../data/network/client/api";
import AddAddressForm from "./AddAddressForm";
import { getUserId } from "../../../store/user/reducer";
import { setSnackbarMessage, setSnackbarVisibilityStatus } from "../../../store/layout/reducer";
import { ADDRESS_SELECTION } from "../../../constants/message_constants";

function a11yProps(index ) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function SubscriptionDetailsContainer(){

    const [selectedPaymentMode, setSelectedPaymentMode] = useState(PAYMENT_MODE.OFFLINE);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const activeClient = useSelector(state => getActiveClient(state));

    const dispatch = useDispatch(); 
    const subscriptionPlanDetails = useSelector((state) => getClientSubscriptionPlanDetails(state));
    const subscriptionCartDetails = useSelector((state) => getClientSubscriptionCartDetails(state));
    const grandTotal = useSelector((state) => getClientSubscriptionCartTotalAmount(state));
    const partnerId = useSelector((state) => getUserId(state));

    const [expanded, setExpanded] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const addressList = useSelector(state => getActiveClientAddress(state));
    const lunchAddress = useSelector(state => getActiveClientLunchAddress(state));
    const dinnerAddress = useSelector(state => getActiveClientDinnerAddress(state));

    const orderType = useSelector(state => getClientOrderType(state));

    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const [value, setValue] = useState(null); 
    const [requiredAddress, setRequiredAddress] = useState(['lunch','dinner']); 

    const mealPreferenceDetails = useSelector(state => getClientMealPreference(state));
    
    useEffect(() => {
        if(activeClient != null){
            fetchClientAddress(dispatch, cookies, activeClient.user_id);
            fetchClientSubscriptionCart(dispatch, cookies, {
                'user_id' : activeClient.user_id,
            })
        }
    },[]);

    useEffect(() => {
        try{

            if(subscriptionCartDetails !== null && subscriptionCartDetails.items !== null && subscriptionCartDetails.items[0].meal_plan){
                console.log(123);
                const mealTypes = subscriptionCartDetails.items[0].meal_plan.meal_types;
                if(!mealTypes.includes(1) && !mealTypes.includes(2) ){
                    setValue(1);
                    console.log("here");
                    setRequiredAddress(['dinner']);
                }else{
                    setValue(0);
                }
            }
        }catch(e){
            console.log(e);
        }
    },[subscriptionCartDetails])

    const handlePayment = () => {

        if(orderType === ORDER_TYPE.ZERO_DELIVERY){
            
            confirmOrder(dispatch, cookies, {
                "payment_mode" : "POST_PAID",
                "user_id" : activeClient.user_id,
                "partner_id" : partnerId,
                "order_type": ORDER_TYPE.ZERO_DELIVERY
            });

        }else{

            let validateAddress = false;

            if(requiredAddress){
                requiredAddress.forEach(e => {

                    if(e === "lunch" && lunchAddress !== null){ 
                        validateAddress = true
                    }else{
                        validateAddress = false
                    }

                    if(e === "dinner" && dinnerAddress !== null){
                        console.log(dinnerAddress);
                        validateAddress = true
                    }else{
                        validateAddress = false;
                    }
                });
            }

            if(validateAddress){
                setIsSubmitting(true);
                dispatch(setPaymentMode({mode: selectedPaymentMode}));
                dispatch(setPaymentStatus({status: PAYMENT_STATUS.INITIAL}));
    
                dispatch(setOnboardingCurrentStep({step: ONBOARDING_FORM_STEPS.PAYMENT_DETAILS}));
    
                switch(selectedPaymentMode){
                    case PAYMENT_MODE.OFFLINE:
                        break;
                    case PAYMENT_MODE.ONLINE:
                        break;
                    default:
                        break;
                }
                setIsSubmitting(false);
    
            }else{
                dispatch(setSnackbarMessage({'message': ADDRESS_SELECTION.MISSING, 'severity': SNACKBAR_SEVERITY.ERROR}));
                dispatch(setSnackbarVisibilityStatus({'status': true }));
            }
        }
    }

    const getMealTypesLabel = (mealTypes) => {
        const mealTypeLabel = [ ];

        if(mealTypes.includes(1)){ 
            mealTypeLabel.push(MEAL_TYPE.BREAKFAST); 
        }

        if(mealTypes.includes(2)){ 
            mealTypeLabel.push(MEAL_TYPE.LUNCH); 
        }

        if(mealTypes.includes(3)){ 
            mealTypeLabel.push(MEAL_TYPE.SNACKS); 
        }

        if(mealTypes.includes(4)){ 
            mealTypeLabel.push(MEAL_TYPE.DINNER); 
        }

        return mealTypeLabel.toString();
    }

    const getMealPreferenceLabel = (mealPreference) => {
        if(mealPreference === "vegetarian"){
            return "Vegetarian";
        }

        if(mealPreference === "non-vegetarian"){
            return "Non Vegetarian";
        }

        if(mealPreference === "eggeterian"){
            return "Eggeterian";
        }
    }

    const handleChange = () => {
        setExpanded(true);
    }

    const toggleAddAddressModal = (anchor , open ) => (event ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event ).key === 'Tab' ||
            (event ).key === 'Shift')
        ) {
            return;
        }
    
        setIsDrawerOpen(open); 
    };

    const handleTabChange = (e, i) => {
        setValue(i);
    }

    const handleLunchAddressChange = (e) => {
        dispatch(setActiveClientLunchAddress({address: e.target.value}));
        fetchClientSubscriptionCart(dispatch, cookies, {
            'user_id' : activeClient.user_id,
            'lunch_address_id' : lunchAddress,
            'dinner_address_id' : dinnerAddress
        });
    }

    const handleDinnerAddressChange = (e) => { 
        dispatch(setActiveClientDinnerAddress({address: e.target.value}));
        fetchClientSubscriptionCart(dispatch, cookies, {
            'user_id' : activeClient.user_id,
            'lunch_address_id' : lunchAddress,
            'dinner_address_id' : dinnerAddress
        });
    }

    const showCartError = (e) => {
        //
    };

    return (
        <Grid container justifyContent="start" alignItems="start" direction="column">
            <Box padding={1} /> 
                
            <Card  sx={{padding:1}}>
                <Grid container direction="column">
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <h4>Meal Plan Details</h4>
                        <Button onClick={(e) => {
                            dispatch(setOnboardingCurrentStep({step: ONBOARDING_FORM_STEPS.PREFERENCE_SELECTION}));
                        }}>
                            Edit
                        </Button>
                    </Grid>
                    <Grid padding={2} > 
                        {
                            subscriptionCartDetails != null && 
                            subscriptionCartDetails.items.map((e) => 
                                <Grid className="subscription-details-container"  container padding ={0} key={ `subscription_cart_item_${e.cart_item_id}` } >
                                    <Grid className="subscription-details-item" container justifyContent="space-between" alignItems="center" direction="row" margin={1} >
                                        <Typography paddingLeft={4}>Plan Name</Typography> 
                                        <Typography paddingRight={4}>{e.display_name}</Typography> 
                                    </Grid>
                                    <Grid className="subscription-details-item"  container justifyContent="space-between" alignItems="center" direction="row" margin={1}  >
                                        <Typography paddingLeft={4}>Start Date</Typography> 
                                        <Typography paddingRight={4}>{e.meal_plan.active_from != null ? formatDate(e.meal_plan.active_from, "DD MMM YYYY") : "" }</Typography> 
                                    </Grid>
                                    <Grid className="subscription-details-item"  container justifyContent="space-between" alignItems="center" direction="row" margin={1} >
                                        <Typography paddingLeft={4}>End Date</Typography> 
                                        <Typography paddingRight={4}>{e.meal_plan.active_to != null ? formatDate(e.meal_plan.active_to, "DD MMM YYYY") : "" }</Typography> 
                                    </Grid>
                                    <Grid className="subscription-details-item"  container justifyContent="space-between" alignItems="center" direction="row"   margin={1}>
                                        <Typography paddingLeft={4}>Meal Preference</Typography> 
                                        <Typography paddingRight={4}>
                                            {
                                                getMealPreferenceLabel(mealPreferenceDetails.mealPreference)
                                                // e.meal_plan.meal_types != null 
                                                // ? getMealTypesLabel(e.meal_plan.meal_types ) 
                                                // : "" 
                                            }
                                        </Typography> 
                                    </Grid>
                                    <Grid className="subscription-details-item"  container justifyContent="space-between" alignItems="center" direction="row"   margin={1}>
                                        <Typography paddingLeft={4}>Meal Types</Typography> 
                                        <Typography paddingRight={4}>
                                            {
                                                e.meal_plan.meal_types != null 
                                                ? getMealTypesLabel(e.meal_plan.meal_types ) 
                                                : "" 
                                            }
                                        </Typography> 
                                    </Grid>

                                </Grid>
                            )
                        }
                    </Grid>   
                </Grid>
            </Card>
            <Box padding={1} /> 

            {
                orderType === ORDER_TYPE.SUBSCRIPTION
                ? <Grid container direction="column">
                    
                    <Card style={{padding: 2}}>
                        <Grid container direction="column" sx={{padding: 2}}> 
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <h4>Address Selection</h4>
                                { 
                                    addressList && addressList.length>0
                                    ? <Button variant="contained" onClick={() => {
                                            setIsDrawerOpen(true);
                                        }}>
                                            Add new Address
                                        </Button>
                                    : null
                                }
                            </Grid>

                            { 
                                ( !addressList || (addressList && addressList.length === 0))
                                ? <Grid container justifyContent="center" alignItems="center" pt={5}  >
                                    <Button variant="contained" style={{width:'30%'}} onClick={() => {
                                        setIsDrawerOpen(true);
                                    }}>
                                        Add new Address
                                    </Button></Grid>     
                                : <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                                            {
                                                (subscriptionCartDetails && subscriptionCartDetails.items && (subscriptionCartDetails.items[0].meal_plan.meal_types.includes(1) 
                                                || subscriptionCartDetails.items[0].meal_plan.meal_types.includes(2))) 
                                                ? <Tab label="Lunch" value={0} {...a11yProps(0)} />
                                                : null
                                            }
                                            {
                                                (subscriptionCartDetails && subscriptionCartDetails.items && (subscriptionCartDetails.items[0].meal_plan.meal_types.includes(3) 
                                                || subscriptionCartDetails.items[0].meal_plan.meal_types.includes(4))) 
                                                ? <Tab label="Dinner" value={1} {...a11yProps(1)} />
                                                : null
                                            }
                                        </Tabs>
                                    </Box>
                                    {
                                    (subscriptionCartDetails && subscriptionCartDetails.items && (subscriptionCartDetails.items[0].meal_plan.meal_types.includes(1) 
                                    || subscriptionCartDetails.items[0].meal_plan.meal_types.includes(2))) 
                                    ? <TabPanel value={0}  > 
                                            <Grid container padding={2}  style={{padding: 2}}> 
                                                <FormControl> 
                                                    <RadioGroup 
                                                        name="radio-buttons-group" 
                                                        value={lunchAddress}
                                                        onChange={handleLunchAddressChange}>
                                                    {
                                                        addressList && addressList.map(e => 
                                                            <FormControlLabel key={e.address_id} value={e.address_id} control={<Radio />}
                                                                label = {`${e.address_nickname} - ${e.address1}, ${e.address2}, ${e.city}, ${e.state}, ${e.pincode} - ${e.mobile_no}`}
                                                            />
                                                        )
                                                    }  
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid> 
                                        </TabPanel>
                                        : null
                                    }
                                    {
                                        (subscriptionCartDetails && subscriptionCartDetails.items && (subscriptionCartDetails.items[0].meal_plan.meal_types.includes(3) 
                                        || subscriptionCartDetails.items[0].meal_plan.meal_types.includes(4))) 
                                        ? <TabPanel value={1}   >
                                                <Grid container padding={2}  style={{padding: 2}}> 
                                                    <FormControl> 
                                                        <RadioGroup 
                                                            name="radio-buttons-group" 
                                                            value={dinnerAddress}
                                                            onChange={handleDinnerAddressChange}>
                
                                                        {
                                                            addressList && addressList.map(e => 
                                                                <FormControlLabel key={e.address_id} value={e.address_id} control={<Radio />}
                                                                    label = {`${e.address_nickname} - ${e.address1}, ${e.address2}, ${e.city}, ${e.state}, ${e.pincode} - ${e.mobile_no}`}
                                                                />
                                                            )
                                                        }  
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid> 
                                            </TabPanel> 

                                    : null
                                }
                                </TabContext>
                            }
                            <SwipeableDrawer
                                anchor={'bottom'}
                                open={isDrawerOpen}
                                onClose={toggleAddAddressModal( 'bottom', false)}
                                onOpen={toggleAddAddressModal( 'bottom', true)}
                                sx={{zIndex: 1001}}
                                style={{zIndex: 10011}}
                            >
                                <AddAddressForm type={value} handleClose={toggleAddAddressModal('bottom', false)} /> 
                            </SwipeableDrawer>
                        </Grid>
                    </Card>
                </Grid>
                : null
            }

            <Box padding={1} />

            {
                orderType === ORDER_TYPE.SUBSCRIPTION
                ? <Card sx={{padding:1}}>
                <Grid container direction="column">
                    <h4>Price Breakup</h4>
                    <Grid container padding={2} > 
                        {
                            subscriptionCartDetails != null && 
                            subscriptionCartDetails?.price_breakup.map((e) => {
                                    if(e.name !== "grand_total"){
                                        return <Grid container justifyContent="space-between" alignItems="center" padding={0} key={  `subscription_price_breakup_${e.name}` } >
                                            <Typography paddingLeft={4}>{e.display_name}</Typography>
                                            <Typography paddingRight={4}>₹ {e.value}</Typography>
                                        </Grid> 
                                    }
                                    return <span />
    
                                }
                            )
                        }
                        <Divider fullWidth />
                        <Grid container justifyContent="space-between" alignItems="center" padding={0} >
                            <Typography paddingLeft={4}><b>Grand Total</b></Typography>
                            <Typography paddingRight={4}><b>₹ {grandTotal}</b> </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                </Card>
                : null
            }
            
            <Box padding={1 } />

            {
                orderType === ORDER_TYPE.SUBSCRIPTION
                ? <Stack direction="column" spacing={2}>

                <Grid justifyContent="center" alignItems="center">
                    <h4> Payment Mode </h4>
                </Grid>

                <Grid justifyContent="center" alignItems="center" paddingLeft={2}>
                    <RadioGroup

                        row
                        aria-labelledby="payment-mode-label"
                        name="row-radio-buttons-group"                >
                        <FormControlLabel value={PAYMENT_MODE.OFFLINE} control={<Radio />} label="Offline"
                            checked={selectedPaymentMode === PAYMENT_MODE.OFFLINE}
                            onChange={(e) => {
                                setSelectedPaymentMode(PAYMENT_MODE.OFFLINE);
                            }}

                        />
                        <FormControlLabel value={PAYMENT_MODE.ONLINE} control={<Radio />} label="Online" 
                            checked={selectedPaymentMode === PAYMENT_MODE.ONLINE}
                            onChange={(e) => {
                                setSelectedPaymentMode(PAYMENT_MODE.ONLINE);
                            }}

                        />
                    </RadioGroup>
                </Grid> 
                </Stack>
                : null
            }

            <Box padding={2} />

            {
                orderType === ORDER_TYPE.SUBSCRIPTION
                ? <Grid  justifyContent="center" alignItems="center" container>
                    <Box padding={2} style={{width:'30%'}}>
                        {
                            (subscriptionCartDetails && subscriptionCartDetails.is_serviceable)
                            ? <LoadingButton fullWidth size="large" onClick={() => handlePayment()} variant="contained" loading={isSubmitting}>
                                Pay Now
                            </LoadingButton> 
                            : <LoadingButton disbaled fullWidth size="large" onClick={() => showCartError()} variant="contained" loading={isSubmitting}>
                                Pay Now
                            </LoadingButton> 
                        }
                    </Box>
                </Grid>
                : null
            }

            {
                orderType === ORDER_TYPE.ZERO_DELIVERY
                ?<Grid  justifyContent="center" alignItems="center" container>
                    <Box padding={2} style={{width:'30%'}}>
                    {
                        (subscriptionCartDetails.is_serviceable)
                        ? <LoadingButton fullWidth size="large" onClick={() => handlePayment()} variant="contained" loading={isSubmitting}>
                            Continue
                        </LoadingButton> 
                        : <LoadingButton disabled fullWidth size="large" onClick={() => showCartError()} variant="contained" loading={isSubmitting}>
                            Continue
                        </LoadingButton>
                    }
                    </Box>
                </Grid>
                : null
            }

        </Grid>
    );

}