import { useSelector, useDispatch } from 'react-redux';  
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikProvider, useFormik } from 'formik';
import { useState,useEffect  } from 'react';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// material
import { Grid, Button, Stack, Typography, TextField, Box, InputAdornment, DialogContent, Dialog } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components 
import { getActiveClient, getClientJournalEditDetail } from '../../../../../store/coach/reducer'; 
import { updateUserDailyLog } from '../../../../../data/network/journal/api';
import { formatDate } from '../../../../../utils/date';
import { DAILY_LOG_TYPE } from '../../../../../constants/app_constants';

export default function ExternalMealForm( {date, handleClose} ) {
    const theme = useTheme();
    const activeClient = useSelector(state => getActiveClient(state));
    
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const dispatch = useDispatch(); 
    const clientJournalEditDetail = useSelector(state => getClientJournalEditDetail(state)); 

    const [startDateValue, setStartDateValue] =  useState(
        new Date() 
    );

    const handleStartDateChange = (newValue ) => {
        setStartDateValue(newValue); 
    };


    const initialValues = {
        meals: [
          {
            name: '',
            qty: '',
          },
          {
            name: '',
            qty: '',
          },
        ],
    };

    return (  
        <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
                await new Promise((r) => setTimeout(r, 500));
                
                const formData = [];

                values.meals.forEach(e => {
                    if(e.name && e.name !== ""){
                        formData.push({name: e.name, qty: e.qty})
                    }
                });

                if(formData.length > 0){
                    updateUserDailyLog(dispatch, cookies, {
                        user_id : activeClient.user_id,
                        log_type : DAILY_LOG_TYPE.EXTERNAL_MEAL_ITEMS,
                        data: [{
                            'date' : formatDate(startDateValue),
                            'val' : JSON.stringify(formData, null)
                        }],
                        follow_up_id: clientJournalEditDetail.id
                     },
                     formatDate(new Date(clientJournalEditDetail.from_date)),
                     formatDate(new Date(clientJournalEditDetail.to_date))
                    );
                }

                handleClose();
                
            }}
        >
            {({ values }) => (
                <Form>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                label="Select Date" 
                                inputFormat="dd MMM yyyy"
                                value={startDateValue}
                                minDate={new Date(clientJournalEditDetail.from_date)}
                                maxDate={new Date(clientJournalEditDetail.to_date)}
                                onChange={handleStartDateChange}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        
                        </LocalizationProvider>
                         
                    </Stack>
                    <Box margin={2} />
                <FieldArray name="meals">
                    {({ insert, remove, push }) => (
                    <div>

                        <Grid item container direction="column" justifyContent="center">
                            {values.meals && values.meals.length > 0 &&
                                values.meals.map((meal, index) => ( 
                                    
                                        <Stack key={index} direction={{ xs: 'column', sm: 'row' }} spacing={2} mt={2}>
                                            <Field
                                                className="external-meal-form-field"
                                                name={`meals.${index}.name`}
                                                placeholder="Item name"
                                                type="text"
                                            />

                                            <Field
                                                className="external-meal-form-field"
                                                type="text"
                                                fullWidth 
                                                label="Quantity"
                                                placeholder="Item quantity"
                                                name={`meals.${index}.qty`}
                                            />

                                            <Button
                                                type="button"
                                                className="secondary"
                                                onClick={() => remove(index)} >
                                                X
                                            </Button>
                                        </Stack>  
 
                                ))
                            }
                        </Grid>
                        <Grid item container direction="row" justifyContent="flex-start" mt={4}>
                            <Button                                  
                                type="button"
                                className="secondary"
                                onClick={() => push({ name: '', qty: '' })}
                            >
                                Add Row
                            </Button>
                        </Grid>
                    </div>
                    
                    )}
                </FieldArray>


                
                <Grid item container direction="row" justifyContent="center" mt={10}>
                    <Button variant='contained' type="submit">
                        Create
                    </Button>                            
                </Grid>

                </Form>
            )}
        </Formik>
 

    );
}
