/* eslint-disable camelcase */
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Grid,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Pagination,
  Button,
  Drawer,
  Box,
} from '@mui/material';
import { useCookies } from 'react-cookie';

import { useDispatch, useSelector } from 'react-redux';
import { getUserId, getActiveCorporateClient } from '../../store/user/reducer';
import { getCorporateInvoiceList } from '../../store/corporate/reducer';
import { fetchCorporateInvoice } from '../../data/network/corporate/api';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, FilterContainer } from '../../sections/@dashboard/user';
import { CorporateBillingDetail } from './CorporateBillingDetail';
// mock
import ExportCSV from '../../components/ExportCSV';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'invoice_id', label: 'Invoice Id', alignRight: false },
  { id: 'invoice_date', label: 'Invoice Date', alignRight: false },
  { id: 'due_date', label: 'Due Date', alignRight: false },
  { id: 'payment_amount', label: 'Payment Amount', alignRight: false },
  { id: 'payment_mode', label: 'Payme Mode', alignRight: false },
  { id: 'payment_reference_no', label: 'Payment Reference No', alignRight: false },
  { id: 'transaction_date', label: 'Transaction Date', alignRight: false },
  { id: 'transaction_id', label: 'Transaction Id', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (array !== undefined && array != null && array.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_user) => _user.first_name && _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
  }

  return [];
}

export default function CorporateBilling() {
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [open, setOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [filterName, setFilterName] = useState('');

  const INVOICELIST = useSelector(state => getCorporateInvoiceList(state));
  const dispatch = useDispatch();
  const partnerId = useSelector(state => getUserId(state));
  const activeClient = useSelector(state => getActiveCorporateClient(state));
  const [cookies] = useCookies(['mobile', 'session', 'access_token']);

  useEffect(() => {
    if (partnerId != null) {
      fetchCorporateInvoice(dispatch, cookies, partnerId, activeClient, page);
    }
  }, [partnerId, page, activeClient])


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = INVOICELIST.data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filteredUsers = applySortFilter(INVOICELIST.data, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;

  const headers = [
    { label: "Invoice Id", key: "invoice_id" },
    { label: "Invoice Date", key: "invoice_date" },
    { label: "Due Date", key: "due_date" },
    { label: "Payment Amount", key: "payment_amount" },
    { label: "Payment Mode", key: "payment_mode" },
    { label: "Payment Reference Mode", key: "payment_reference_no" },
    { label: "Transaction Date", key: "transaction_date" },
    { label: "Transaction Id", key: "transaction_id" },
    { label: "Status", key: "status" },
  ];

  function invoiceData() {
    const data = [];
    if (INVOICELIST.data) {
      for (let i = 0; i < INVOICELIST.data.length; i += 1) {
        data[i] = {
          'invoice_id': `${INVOICELIST.data[i].invoice_id}`,
          'invoice_date': INVOICELIST.data[i].invoice_date,
          'due_date': INVOICELIST.data[i].due_date,
          'payment_amount': INVOICELIST.data[i].payment_details.payment_amount,
          'payment_mode': INVOICELIST.data[i].payment_details.payment_mode,
          'payment_reference_no': INVOICELIST.data[i].payment_details.payment_reference_no,
          'transaction_date': INVOICELIST.data[i].payment_details.transaction_date,
          'transaction_id': INVOICELIST.data[i].payment_details.transaction_id,
          'status': INVOICELIST.data[i].payment_details.status,
        };
      }
    }
    return data;
  }

  return (
    <Page title="Billing & Invoice">
      <Container maxWidth="xl">
        <Grid container justifyContent="space-between" padding={2}>
          <Typography variant="h4" gutterBottom>
            {/* Billing & Invoice */}
          </Typography>
          <ExportCSV csvHeaders={headers}
            csvData={invoiceData()}
            fileName="invoice.csv" />
        </Grid>
        <FilterContainer filterKey={INVOICELIST.filter_params} filterMethod={fetchCorporateInvoice} />
        <Card className="glass-box">
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, minHeight: 400 }}>
              {
                INVOICELIST.data &&
                INVOICELIST.data.length > 0
                ?
                <Table size='small'>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={INVOICELIST.data.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {INVOICELIST.data.map((row) => {
                      const { due_date, invoice_date, invoice_id, payment_details, price_breakup } = row;

                      return (
                        <TableRow
                          hover
                          key={invoice_id}
                          tabIndex={-1}
                          role="checkbox"
                        // selected={isItemSelected}
                        // aria-checked={isItemSelected}
                        >
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {invoice_id}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="center">{invoice_date}</TableCell>
                          <TableCell align="center">{due_date}</TableCell>
                          <TableCell align="center">{payment_details?.payment_amount}</TableCell>
                          <TableCell align="center">{payment_details?.payment_mode}</TableCell>
                          <TableCell align="center">{payment_details?.payment_reference_no}</TableCell>
                          <TableCell align="center">{payment_details?.transaction_date}</TableCell>
                          <TableCell align="center">{payment_details?.transaction_id}</TableCell>
                          <TableCell align="center">{payment_details?.status}</TableCell>
                          <TableCell align="center">
                            <Button size='small' color='info' variant='contained' 
                            style={{ padding: '2px 10px', minWidth: 'auto', fontSize: '12px'}}
                            onClick={() => {
                              setOpen(true);
                              setSelectedInvoice(row)
                            }}
                            >View</Button>
                            <CorporateBillingDetail data={selectedInvoice} visible={open} setOpen={setOpen} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>

                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
                : <Typography variant='h6' sx={{display: 'flex', justifyContent: 'center', marginTop: 4}}>No Data Available</Typography>
              }
            </TableContainer>
          </Scrollbar>


        </Card>
        <Pagination count={INVOICELIST?.pagination?.total_pages} variant="outlined" shape="rounded" onChange={handleChangePage} />
      </Container>
    </Page>
  );
}
