import { useTheme,Grid, Container, Typography  } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Page from "../components/Page";

export default function ClientApp() {
    const theme = useTheme();
  
    const navigate = useNavigate();
  
    return (
      <Page title="Client App">
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Hi, Welcome back
          </Typography>
        </Container>
    </Page>
    );
}