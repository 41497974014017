import { useState, useEffect,useRef } from "react";
import { Box, Button, Paper, Card, Grid, Stack, TextField, styled, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Collapse, Stepper, Step, StepLabel, TablePagination, TableFooter, Modal } from "@mui/material";
 import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { Link as RouterLink } from 'react-router-dom';

import ExportCSV from "../../../components/ExportCSV";
import Scrollbar from "../../../components/Scrollbar";
import FilterContainer from "./FilterContainer";
import { CoachClientDetail } from "./CoachClientDetail";
import { formatDate, getAge } from "../../../utils/date";
import { fetchClientList } from "../../../data/network/client/api";
import { ClientOnboardingForm, UserMoreMenu } from "../../../sections/@dashboard/user";
import { getClientList, getClientListData } from "../../../store/coach/reducer";
import { getUserId } from "../../../store/user/reducer";
import Iconify from "../../../components/Iconify";

export const CoachClientList = () => {

    const StackStyled = styled(Stack)(({ theme }) => ({
        background: '#fff',
        padding: '20px',
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
            gap: 20,
            flexDirection: 'column'
        },
        '& .MuiBox-root': {
            [theme.breakpoints.down('md')]: {
                marginLeft: 0,
                border: 'none',
                flexWrap: 'wrap',
            },
            display: 'flex',
            alignItems: 'center',
            flexBasis: '25%',
            '&:not(:last-child)': {
                borderRight: '1px solid #ccc',
                [theme.breakpoints.down('md')]: {
                    borderRight: 'none',
                },
            },
            '& .icon': {
                fontSize: '3rem',
                marginRight: '20px',
                '& .MuiBox-root': {
                    border: 'none',
                }
            },
            '& .details': {
                display: 'flex',
                flexDirection: 'column',
                '& .MuiTypography-caption': {
                    textTransform: 'uppercase',
                }
            }
        },
        '& .MuiOutlinedInput-input': {
            padding: '10px 14px',
        }
    }))
    
    const headers = [
        { label: "Id", key: "id" },
        { label: "Date", key: "date" },
        { label: "Org", key: "org" },
        { label: "Meal Name", key: "meal_name" },
        { label: "Meal Qty", key: "meal_qty" },
        { label: "Status", key: "status" },
        { label: "Scheduled For", key: "scheduled_for" },
        { label: "Delivered At", key: "delivery_dt" },
    ];

    const [openModal, setModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(10);
    const [open, setOpen] = useState(false);  
    const [selectedRow, setSelectedRow] = useState(null);
    const [filter, setFilter] = useState('');
    const [isModalVisible, setModalVisible] = useState(false);

    const tableRef = useRef();
    const _paginationRef = useRef();

    const dispatch = useDispatch();
    const [cookies] = useCookies(['mobile', 'access_token', 'session']);
    
    const USERLIST = useSelector(state => getClientListData(state));
    const partnerId = useSelector(state => getUserId(state)); 

    function Row(props) {
        const { row } = props;
        const [rowExpanded, setRowExpanded] = useState(false);
        
        const id = row.id;
        const dob = row.dob;
        const subscription = row.subscription;

        const age = getAge(dob);
        

        console.log(row.subscription);
        const mealPlan =  subscription.parent_meal_plan.display_name;
        const subscriptionStartDate =  formatDate(subscription.meal_plan.active_from, "DD MMM YYYY");
        const subscriptionEndDate =  formatDate(subscription.meal_plan.active_to, "DD MMM YYYY");
        const todayObj = new Date();
        const endDateObj = new Date(subscription.meal_plan.active_to);

        return (
          <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              
                <TableCell component="th" scope="row">{id}</TableCell>
                <TableCell align="center">{`${row.first_name} ${row.last_name}`} </TableCell>
                <TableCell align="center">{row.mobile}</TableCell>
                <TableCell align="center">{age}</TableCell>
                <TableCell align="center">{row.gender}</TableCell>
                <TableCell align="center">{`${subscription.order_type}`}</TableCell>
                <TableCell align="center">{mealPlan}</TableCell>
                <TableCell align="center">{`${subscriptionStartDate  } - ${  subscriptionEndDate}` }</TableCell>
                <TableCell align="center" className="">{todayObj < endDateObj ? <Button variant="outlined" color="success">ACTIVE</Button> : <Button variant="outlined" disabled>INACTIVE</Button>}</TableCell>
                {/* <TableCell align="center">
                    {status} 
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setRowExpanded(!rowExpanded)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>                                    
                </TableCell> */}
                <TableCell align="center">
                    {/* {status} */}

                    {/* <Button style={{ padding: '2px 10px', minWidth: 'auto', fontSize: '12px', marginRight: '5px' }} size='small' color='info' variant='contained'
                        onClick={() => {
                        setOpen(true);
                        setSelectedRow(row);
                    }}
                    >View</Button> */}

                    {/* <Button style={{ padding: '2px 10px', minWidth: 'auto', fontSize: '12px', marginRight: '5px' }} size='small' color='warning' variant='contained'
                        onClick={() => {
                        setOpen(true);
                        setSelectedRow(row);
                    }}
                    >Print label</Button> */}
                    
                    {/* <CoachClientDetail data={selectedRow} visible={open} setOpen={setOpen} /> */}
                </TableCell>
                <TableCell align="right">
                    <UserMoreMenu clientId={id} mealPlan={subscription.meal_plan} />
                </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                <Collapse in={rowExpanded} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1, backgroundColor: '#ffffffcc', padding:1 }}>

                    <Stepper activeStep={statuses!=null ? statuses.length : 0} alternativeLabel>
                        {statuses && statuses.map((item) => (
                            <Step key={item.id} sx={{
                                '.MuiStepLabel-IconContainer': {
                                  fill: 'black',
                              }}}>
                                <StepLabel sx={{fontSize: '3px'}}>{item.status}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                  </Box>
                </Collapse>
              </TableCell>
            </TableRow> */}
          </>
        );
    }

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
        fetchClientList(dispatch, cookies, newPage, count, filter);

    };

    const handleFilterApiCall = (filterBy) => {
        setFilter(filterBy);
        setPage(1);
        fetchClientList(dispatch, cookies, 1, count, filterBy);
    }

    const handleModalClose = () => {
        setModalVisible(false);
    }

    const clientData = () => {
        const data = [];
        if (USERLIST && USERLIST.data) {
          for (let i = 0; i < USERLIST.data.length; i += 1) {
            // data[i] = {
            //   'id': `${USERLIST.data[i].id}`,
            //   'date': `${USERLIST.data[i].date}`,
            //   'org': USERLIST.data[i].org,
            //   'meal_name': USERLIST.data[i].meal_name,
            //   'meal_qty': USERLIST.data[i].meal_qty,
            //   'status': USERLIST.data[i].status,
            //   'scheduled_for': USERLIST.data[i].scheduled_for,
            //   'delivery_dt': USERLIST.data[i].delivery_dt,
            // };
          }
        }
        return data;
    }

    useEffect(() => {
        if (partnerId !== null){
            fetchClientList(dispatch, cookies, page, count, filter);
        }
    },[]);

    return (
        <>
            <Box sx={{ pb: 1, pt:2 }} />
            {/* <StackStyled className="glass-box" direction='row' spacing={2}>
                {ORDERSTATS && ORDERSTATS && ORDERSTATS.data.map((ele) => (
                    <Box key={ele?.label}>
                        <div className='icon'>
                            {getIcon(ele?.label)}
                        </div>
                        <div className='details'>
                            <Typography variant='h6'>{ele?.value}</Typography>
                            <Typography variant='caption'>{ele?.label}</Typography>
                        </div>
                    </Box>
                )
                )}
                <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            inputFormat="YYYY/MM/DD"
                            value={startDate}
                            onChange={handleStartDate}
                            renderInput={(params) => <TextField {...params} sx={{ width: 170 }} />}
                        />
                        <DesktopDatePicker
                            inputFormat="YYYY/MM/DD"
                            value={endDate}
                            onChange={handleEndDate}
                            renderInput={(params) => <TextField {...params} sx={{ width: 170 }} />}
                        />
                        <Button variant="contained" sx={{ ml: 1 }} onClick={handleSearch}>Go</Button>
                    </LocalizationProvider>
                </Box>
            </StackStyled> */}
            <Box margin={4} />
            <Grid container justifyContent="space-between" sx={{ pb: 2 }}>
                <Grid item justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant="h4" gutterBottom>
                        All Clients
                    </Typography>
                </Grid>
                <Grid item direction={'row'} justifyContent={'space-between'} alignItems={'center'} columnGap={1}>
                    <ExportCSV csvHeaders={headers}
                        csvData={clientData()}
                        fileName="clients.csv" />
                    <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => setModalVisible(true)} sx={{marginLeft:1}}>
                        Subscribe New User
                    </Button>
                </Grid>
            </Grid>

            <Modal
                open={isModalVisible}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{zIndex:99}}
            >
                <Box>
                    <ClientOnboardingForm handleClose={handleModalClose}/>
                </Box>
            </Modal>
            {USERLIST && <FilterContainer filterKey={USERLIST.filter_params} filterMethod={handleFilterApiCall} />}

            <Card className="glass-box">
                <Scrollbar>

                <TableContainer className="glass-box" >
                    {
                        USERLIST &&
                        USERLIST.data &&
                        USERLIST.data.length > 0
                        ?
                            <Table className="glass-box" ref={tableRef} size="small" aria-label="collapsible table">
                                <TableHead className="glass-box">
                                <TableRow>
                                    <TableCell />
                                    <TableCell align="center">Name</TableCell>
                                    <TableCell align="center">Mobile</TableCell>
                                    <TableCell align="center">Age</TableCell>
                                    {/* <TableCell align="center">Meal Qty</TableCell> */}
                                    <TableCell align="center">Gender</TableCell>
                                    <TableCell align="center">Order Type</TableCell>
                                    <TableCell align="center">Meal Plan</TableCell>
                                    <TableCell align="center">Subscription</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center" />
                                </TableRow>
                                </TableHead>
                                <TableBody className="glass-box">
                                {USERLIST.data &&
                                USERLIST.data.length > 0 && USERLIST.data.map((row, _id) => (
                                    <Row key={_id} row={row} />
                                ))}
                                </TableBody>
                            </Table>
                        : <Typography variant='h6' sx={{display: 'flex', justifyContent: 'center'}}>No Data Available</Typography>
                    }   
                    </TableContainer>

                </Scrollbar>
            </Card>
            <Pagination ref={_paginationRef} page={page} count={USERLIST?.pagination?.total_pages} variant="outlined" shape="rounded" onChange={handleChangePage} />

        </>

    );
}