import {navigationConfig, staticNavs} from '../constants/navigation_menu';

export function getSideNavigationMenuConfig(role) {
    const navs = [];
    const staticNavigations = [];
    if(role){
        navigationConfig.forEach(val => {
            if(val.visibility.includes("all")){
                navs.push(val);
            }else if(val.visibility.some(item => (item.name === role.name))){
            // }else if(val.visibility.includes(role.name) ){
                navs.push(val);
            }
        });
        staticNavs.forEach(val => {
            if(val.visibility.includes("all")){
                staticNavigations.push(val);
            }else if(val.visibility.some(item => (item.name === role.name))){
            // }else if(val.visibility.includes(role.name) ){
                staticNavigations.push(val);
            }
        });
    } 

    return [navs, staticNavigations];
}
