import { useState, useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { filter } from 'lodash';
import { useCookies } from 'react-cookie';
import {  Card, Table, Box, Button, Grid, Modal, Stack, Typography, TableRow,
    TableBody,Container,
    TableCell, TableContainer, Pagination} from "@mui/material";
import { getPartnerMealList } from "../../../store/user/reducer";
import MealListCard from "./meal_list/MealListCard";
import CreateMealForm from "./CreateMealForm";
import { setMealDetailModalVisibility, setSelectedMeal } from "../../../store/meal/reducer";
// import { mealList } from "../../../constants/meal_constants";
import ExportCSV from '../../../components/ExportCSV';
import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from '../../../components/SearchNotFound';
import MealListHead from "./meal_list/MealListHead";
import { fetchPartnerMeals } from "../../../data/network/dish/api";
import FilterContainer from "./meal_list/FilterContainer";



export default function MealList() {

    const TABLE_HEAD = [
        { id: 'id', label: '#', alignRight: false },
        { id: 'name', label: 'Name', alignRight: false },
        { id: 'action', label: 'Action', alignRight: false },
    ];

    const headers = [
        { label: "Id", key: "meal_id" },
        { label: "Name", key: "name" },
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);
    const mealList = useSelector((state) => getPartnerMealList(state));

    const [page, setPage] = useState(1);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');

    const showMealDetailModal = () => {
        dispatch(setMealDetailModalVisibility({data: true}));
    };

    const handleClose = () => {
        dispatch(setMealDetailModalVisibility({data: false}));
    }

    
    const handleRequestSort = (_event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
        const newSelecteds = mealList.data.map((n) => n.name);
        setSelected(newSelecteds);
        return;
        }
        setSelected([]);
    };

    const handleChangePage = (_event, newPage) => {
        console.log(newPage);
        setPage(newPage);
    };

    
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
        return -1;
        }
        if (b[orderBy] > a[orderBy]) {
        return 1;
        }
        return 0;
    }
    
    function getComparator(order, orderBy) {
        return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }
    
    function applySortFilter(array, comparator, query) {
        if (array !== undefined && array != null && array.length > 0) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return filter(array, (_user) => _user.name && _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
        }
        return stabilizedThis.map((el) => el[0]);
        }
    
        return [];
    }

    function mealData() {
        const data = [];
        if (mealList.data) {
          for (let i = 0; i < mealList.data.length; i += 1) {
            data[i] = {
              'id': `${mealList.data[i].meal_id}`,
              'name': `${mealList.data[i].name}`,
            };
          }
        }
        return data;
    }

    
    const filteredMeals = applySortFilter(mealList.data, getComparator(order, orderBy), filterName);
    const isMealNotFound = filteredMeals.length === 0;

  
    useEffect(() => {
        // fetchPartnerDish(dispatch, cookies);
        fetchPartnerMeals(dispatch, cookies, page); 
    },[]);

    useEffect(() => {
        // fetchPartnerDish(dispatch, cookies);
        fetchPartnerMeals(dispatch, cookies, page); 
    },[page]);

    return  (
        <Container maxWidth="xl">
            
            {/* <Grid container >
                {
                    mealList && mealList.map((e) => <Grid padding={2} container justifyContent={'space-evenly'} alignItems={'center'} flexDirection={'row'} item xs={6} sm={4} md={3} xl={2} lg={2} onClick={() => {
                        dispatch(setSelectedMeal({data: e}));
                        showMealDetailModal();
                    }} sx={{cursor: 'pointer'}}>
                            <MealListCard showMealDetailModal={showMealDetailModal} meal={e} />
                        </Grid>)
                } 
                
            </Grid> */}

            <Grid container justifyContent="space-between" sx={{ pb: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Meal List
                </Typography>
                <ExportCSV csvHeaders={headers}
                    csvData={mealData()}
                    fileName="meals.csv" />
            </Grid>
            <FilterContainer filterKey={mealList.filter_params} filterMethod={fetchPartnerMeals} />
            <Card className="glass-box">
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                    {
                        mealList.data &&
                        mealList.data.length > 0
                        ?
                        <Table size='small'>
                            <MealListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={mealList.data.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                            {mealList.data.map((row) => {
                                const mealId = row.meal_id;
                                const name = row.name;
                                const isItemSelected = false;
                                // const isItemSelected = selected.indexOf(mobile) !== -1;

                                return (
                                <TableRow
                                    hover
                                    key={mealId}
                                    tabIndex={-1}
                                    role="checkbox"
                                    selected={isItemSelected}
                                    aria-checked={isItemSelected}
                                >
                                    <TableCell component="th" scope="row" padding="none">
                                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                        <Typography variant="subtitle2" noWrap>
                                        {mealId}
                                        </Typography>
                                    </Stack>
                                    </TableCell>
                                    <TableCell align="center">{name}</TableCell>
                                    {/* <TableCell align="center">{mobile}</TableCell>
                                    <TableCell align="center">{age}</TableCell>
                                    <TableCell align="center">{gender}</TableCell>
                                    <TableCell align="center">{bmi}</TableCell> */}
                                    <TableCell align="center">
                                    <Button style={{ padding: '2px 10px', minWidth: 'auto', fontSize: '12px', marginRight: '5px' }} size='small' color='info' variant='contained'
                                        onClick={() => {
                                            dispatch(setSelectedMeal({data: row}));
                                            showMealDetailModal();
                                        // setOpen(true);
                                        // setSelectedInvoice(row)
                                        }}
                                    >View</Button>
                                    {/* <Button style={{ padding: '2px 10px', minWidth: 'auto', fontSize: '12px' }} size='small' color='error' variant='contained'
                                        onClick={() => {
                                            // 
                                        }}
                                    >
                                        Delete</Button> */}
                                    {/* <CorporateUserDetail data={selectedInvoice} visible={open} setOpen={setOpen} /> */}
                                    </TableCell>
                                    {/* <Modal
                                        open={openModal}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                        >
                                        <Box sx={style}>
                                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                            Are you sure you want to delete ?
                                            </Typography>
                                            <Box id="modal-modal-description" sx={{ mt: 2 }}>
                                                <Button sx={{ mr: 1 }} color='error' variant='contained'
                                                    onClick={() => handleDelete(id)}
                                                >
                                                    Delete</Button>
                                                <Button variant='contained' onClick={handleClose}>Cancel</Button>
                                            </Box>

                                        </Box>
                                    </Modal> */}
                                </TableRow>
                                );
                            })}
                            </TableBody>

                            {isMealNotFound && (
                            <TableBody>
                                <TableRow>
                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                    <SearchNotFound searchQuery={filterName} />
                                </TableCell>
                                </TableRow>
                            </TableBody>
                            )}
                        </Table>
                        : <Typography variant='h6' sx={{display: 'flex', justifyContent: 'center'}}>No Data Available</Typography>
                    }
                    </TableContainer>
                </Scrollbar>
            </Card>
            <Box margin={1} />
            <Pagination count={mealList?.pagination?.total_pages} variant="outlined" shape="rounded" onChange={handleChangePage} />

        </Container>
    )
}