import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';

// material
import { Grid, Button, Container, Box, Typography, Card, IconButton, Avatar, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Iconify from '../../../components/Iconify';
import { getActiveClient } from '../../../store/coach/reducer';
// mocks_
import account from '../../../_mock/account';
import { fetchClientReport } from '../../../data/network/client/api'; 
import DailyLogReport from './DailyLogReport';
import UpcomingMealList from './UpcomingMealList';
import UpcomingActivityList from './UpcomingActivity';
import ConsumptionActivityReport from './ConsumptionActivityReport';
import ProgressChart from './ProgressChart';
// ----------------------------------------------------------------------

export default function UserReport() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const clientDetails = useSelector(state => getActiveClient(state));

    const [reportType, setReportType] = useState("weekly");

    const handleReportTypeChange = (event, newValue) => {
        if(newValue !== null){
            setReportType(newValue);
        }
    };

    useEffect(() => {
        console.log(clientDetails);
        if(clientDetails != null ){ 
            fetchClientReport(dispatch, cookies, {
                "user_id" : clientDetails.user_id,
                "report_type" : reportType
            })
        }
    },[reportType])

    return (
        <Grid container direction="column">
            <Grid container direction="row" justifyContent="space-between" alignItems="center" margin={2}>
                <Grid item>
                    <Typography variant="h4">
                        User Report
                    </Typography>
                </Grid>

                <Grid item>
                    <ToggleButtonGroup
                        color="primary"
                        value={reportType}
                        exclusive
                        onChange={handleReportTypeChange}>
                        <ToggleButton value="daily">Daily</ToggleButton>
                        <ToggleButton value="weekly">Weekly</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>

            <Box margin={2} />
            <Grid  container item xs={12} md={10} lg={10} xl={10}>
                <ConsumptionActivityReport />
            </Grid>
            <Box margin={2} />
            
            <Grid container direction="row" justifyContent="space-between" alignItems="center" >
                <Grid item xs={12} md={6} lg={6} xl={6} mt={2} px={1} >
                    <UpcomingMealList />
                </Grid>
                <Grid item xs={12} md={5} lg={6} xl={6} mt={2} px={1}>
                    <UpcomingActivityList />
                </Grid>
            </Grid>
            <Box margin={2} />

            <Grid container item xs={12} md={10} lg={10} xl={10} >
                <DailyLogReport />
            </Grid>

            <Box margin={2} />
            <Grid container item xs={12} md={8} lg={8} xl={8}>
                <ProgressChart />
            </Grid>
            
            <Box margin={1} />
        </Grid>
    );
}
