import {  ONBOARDING_FORM_STEPS, SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { setOnboardingCurrentStep  } from '../../../store/onboarding/reducer';
import { setActiveClient, setClientDetailSubmitted,  setClientJournal , setClientJournalEditDetail
} from '../../../store/coach/reducer';
import axios from '../../../utils/axios-configure';
import { API_GET_USER_DAILY_LOG, API_UPDATE_USER_DAILY_LOG, API_CREATE_USER_JOURNAL, API_GET_USER_JOURNAL, API_UPDATE_USER_ACTIVITY_STATUS
} from "../endpoints";

import {getAccessTokenFromCookie} from '../session/api'
import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import { setPartnerJournal } from '../../../store/user/reducer';
import { JOURNAL_FETCH, JOURNAL_UPDATE } from '../../../constants/message_constants';
import { fetchActivitySchedule } from '../client/api';


export const fetchPartnerJournalEntries = (dispatch, cookies, data) => {
  // partnerId = 3;
    dispatch(setLoadingStatus({status: true}));  

    let url = `${API_GET_USER_JOURNAL}?active=true`;

    if(data.start_date){
      url += `&start_date=${data.start_date}`       
    }

    if(data.end_date){
      url += `&end_date=${data.end_date}` 
    }

    if(data.client_id){
      url += `&user_id=${data.client_id}` 
    }

    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    if(accessToken != null){
        axios({
            method: "get",
            url,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
          })
          .then((response) => {
            // handle success
            if(response.status === 200){
              dispatch(setPartnerJournal({data: response.data.data}));
            }else{
              dispatch(setPartnerJournal({data: []}));
            } 
            dispatch(setLoadingStatus({status: false}));  

            return true;
          })
          .catch((err) => {
            dispatch(setPartnerJournal({data: []})); 
            dispatch(setLoadingStatus({status: false}));  
          });
    }else{
        dispatch(setPartnerJournal({data: []}));
        dispatch(setLoadingStatus({status: false}));  
      }
}

export const fetchUserJournal = (dispatch, cookies, data) => {
  // partnerId = 3;
    dispatch(setLoadingStatus({status: true}));  

    let url = `${API_GET_USER_JOURNAL}?active=true`;

    if(data.client_id ){
      url += `&user_id=${data.client_id}`
    }

    if(data.start_date){
      url += `&start_date=${data.start_date}`       
    }

    if(data.end_date){
      url += `&end_date=${data.end_date}` 
    }

    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    if(accessToken != null){
        axios({
            method: "get",
            url,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
          })
          .then((response) => {
            // handle success
            if(response.status === 200){
                dispatch(setClientJournal({data: response.data.data}));
            }else{
              dispatch(setClientJournal({data: {}}));
            } 
            dispatch(setLoadingStatus({status: false}));  

            return true;
          })
          .catch((err) => {
            dispatch(setClientJournal({data: {}})); 
            dispatch(setLoadingStatus({status: false}));  
          });
    }else{
        dispatch(setClientJournal({data: {}}));
        dispatch(setLoadingStatus({status: false}));  
      }
}
 
export const createUserJournal = (dispatch, cookies, data, createCallback) => {
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    dispatch(setLoadingStatus({status: true}));  
    if(accessToken != null){
      axios({
        method: "post",
        url: `${API_CREATE_USER_JOURNAL}`,
        data,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
      })
      .then((response) => {
        // handle success
        if(response.status === 200){
            dispatch(setClientJournalEditDetail({data: response.data.data[0]}));
            fetchPartnerJournalEntries(dispatch, cookies, {});
            if(createCallback){
              createCallback(data.user_id, response.data.data[0].id)
            }
        }else{
          dispatch(setClientJournalEditDetail({data: null}));
          dispatch(setLoadingStatus({status: false}));  
        }
        return true;
      })
      .catch((err) => {
        dispatch(setClientJournalEditDetail({data: null}));
        dispatch(setLoadingStatus({status: false}));  
      });
    }else{
      dispatch(setClientJournalEditDetail({data: null}));
      dispatch(setLoadingStatus({status: false}));  
    }
}

export const updateUserDailyLog = (dispatch, cookies, data, startDate, endDate) => {
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  dispatch(setLoadingStatus({status: true}));  
  if(accessToken != null){
      axios({
          method: "post",
          url: `${API_UPDATE_USER_DAILY_LOG}`,
          data,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
          // handle success
          if(response.status === 200){ 
            fetchUserDailyLog(dispatch, cookies, {
              user_id : data.user_id,
              start_date : startDate,
              end_date: endDate,
              type: data.type
            })
            dispatch(setSnackbarMessage({'message': JOURNAL_UPDATE.DAILY_LOG_SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));     
          }else{ 
            dispatch(setSnackbarMessage({'message': JOURNAL_UPDATE.DAILY_LOG_ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));     
            //
          }
          dispatch(setLoadingStatus({status: false}));  
          return true;
        })
        .catch((err) => { 
          dispatch(setSnackbarMessage({'message': JOURNAL_UPDATE.DAILY_LOG_ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
          dispatch(setSnackbarVisibilityStatus({'status': true }));     
        // console.log(response);
        });
  }else{
    dispatch(setLoadingStatus({status: false}));   
    dispatch(setSnackbarMessage({'message': JOURNAL_UPDATE.DAILY_LOG_ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
    dispatch(setSnackbarVisibilityStatus({'status': true }));     
  } 
}

export const fetchUserDailyLog = (dispatch, cookies, data) => {
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  dispatch(setLoadingStatus({status: true}));  

  let url = `${API_GET_USER_DAILY_LOG}?user_id=${data.user_id}`;

  if(data.date){
    url += `&date=${data.date}`;
  }

  if(data.start_date){
    url += `&start_date=${data.start_date}`;
  }

  if(data.end_date){
    url += `&end_date=${data.end_date}`;
  }

  if(data.type){
    url += `&type=${data.type}`;
  }

  if(accessToken != null){
    axios({
        method: "get",
        url ,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
      })
      .then((response) => {
        // handle success
        if(response.status === 200){ 
          dispatch(setClientJournal({data : response.data.data}));
          // dispatch(setActiveClient({data:response.data.data}));
        } else{
          dispatch(setSnackbarMessage({'message': JOURNAL_FETCH.DAILY_LOG_ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
          dispatch(setSnackbarVisibilityStatus({'status': true }));       
        }
        // dispatch(setLoadingStatus({status: false}));  
        return true;
      })
      .catch((err) => { 
        dispatch(setLoadingStatus({status: false}));  
        dispatch(setSnackbarMessage({'message': JOURNAL_FETCH.DAILY_LOG_ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));     

      });
  }
  dispatch(setLoadingStatus({status: false}));  
} 

export const updateClientActivityStatus = (dispatch, cookies, data) => {
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  dispatch(setLoadingStatus({status: true}));  

  if(accessToken != null){
    axios({
        method: "post",
        url:`${API_UPDATE_USER_ACTIVITY_STATUS}` ,
        data,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
      })
      .then((response) => {
        // handle success
        if(response.status === 200){ 
          // dispatch(setClientJournal({data : response.data.data}));
          // dispatch(setActiveClient({data:response.data.data}));
          dispatch(setSnackbarMessage({'message': JOURNAL_UPDATE.DAILY_LOG_SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
          dispatch(setSnackbarVisibilityStatus({'status': true }));    
          fetchActivitySchedule(dispatch, cookies, {
            user_id: data.user_id,
            start_date : data.start_date,
            end_date: data.end_date
          })   
        } else{
          dispatch(setSnackbarMessage({'message': JOURNAL_UPDATE.DAILY_LOG_ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
          dispatch(setSnackbarVisibilityStatus({'status': true }));       
        }
        // dispatch(setLoadingStatus({status: false}));  
        return true;
      })
      .catch((err) => { 
        dispatch(setLoadingStatus({status: false}));  
        dispatch(setSnackbarMessage({'message': JOURNAL_UPDATE.DAILY_LOG_ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));     

      });
  }
  dispatch(setLoadingStatus({status: false}));  
} 