import { createSlice } from '@reduxjs/toolkit';
import { formatDate } from '../../utils/date';
import {SNACKBAR_SEVERITY, STATUS } from '../../constants/app_constants';

const activitySlice = createSlice({
  name: 'activity',
  initialState: {
    activityList: [],
    addActivityStatus : STATUS.LOADING,
    newActivity: null,
    activityCategoryList: [],
  },
  reducers: {
    setActivityList(state, action) {
      state.activityList = action.payload.data;
    },
    resetActivityList(state, action){
      state.activityList = [];
    }, 
    setAddActivityStatus(state, action){
      state.addActivityStatus = action.payload.status;
    },
    setNewActivity(state, action){
      state.newActivity = action.payload.data;
    },
    setActivityCategoryList(state, action){
      state.activityCategoryList = action.payload.data;
    },
    resetAddActivityStatus(state, action){
      state.addActivityStatus = STATUS.LOADING;
      state.newActivity = null;
    }
  }
})
 

export const getActivityList = (state) => {
    try{
        return state.activity.activityList;
    }catch(e){
        return false;
    } 
} 

export const getActivityAddedStatus = (state) => {
  try{
      return state.activity.addActivityStatus;
  }catch(e){
      return false;
  } 
} 

export const getActivityAddedDetails = (state) => {
  try{
      return state.activity.newActivity;
  }catch(e){
      return false;
  }
} 

export const getActivityCategoryList = (state) => {
  try{
      return state.activity.activityCategoryList;
  }catch(e){
      return false;
  } 
} 

export const { setActivityList,resetActivityList, setNewActivity,setActivityCategoryList, setAddActivityStatus, resetAddActivityStatus } = activitySlice.actions

export default activitySlice.reducer