export default function Pagination(theme) {
    return {
      MuiPagination: {
        styleOverrides: {
          root: {
            marginTop: '30px',
            '.MuiPaginationItem-root': {
                background: '#D5FFF6',
            },
            '.MuiPaginationItem-root.Mui-selected': {
                background: '#d5fff6',
                color: '#000',
                ':hover': {
                    background: '#d5fff6',
                }
            }
          }
        }
      }
    };
  }
  