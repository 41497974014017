import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PropTypes } from 'prop-types';
// material
import { Grid, Button, Container, Stack, Typography, Card, IconButton, Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Iconify from '../../../components/Iconify';
import { getActiveClient } from '../../../store/coach/reducer';
import { getAge } from '../../../utils/date';
// mocks_
import account from '../../../_mock/account';
// ----------------------------------------------------------------------

UserListCard.propTypes = { 
    clientDetails: PropTypes.object.isRequired,
};

export default function UserListCard({clientDetails}) {
    const theme = useTheme();  
    return (
            <Card className='glass-box' sx={{padding:2, borderRadius: 2}}>
                
                <Grid container direction="column"  > 
                     
                    <Grid item container direction="row" justifyContent="center">
                        <IconButton >
                            <Avatar src={account.photoURL} alt="photoURL" style={{width:120, height:120}} />
                        </IconButton>
                    </Grid>
                    <Grid item container direction="row" justifyContent="center">
                        <Typography  gutterBottom>
                            {clientDetails && `${clientDetails.first_name} ${clientDetails.last_name}`}
                        </Typography>
                        
                    </Grid>
                    <Grid container direction="row" justifyContent="center">                                
                        <Typography gutterBottom sx={{fontSize: '0.8rem'}}>
                            {
                                clientDetails && clientDetails.subscription && clientDetails.subscription.meal_plan && 
                                clientDetails.subscription.meal_plan.parent_meal_plan && clientDetails.subscription.meal_plan.parent_meal_plan.display_name
                            }
                        </Typography>
                    </Grid>

                     
                    
                </Grid>
            </Card>
    );
}
