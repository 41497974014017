import { alpha, AppBar, Box, IconButton, Stack, styled } from "@mui/material";
import Footer from "../../components/Footer";
import Iconify from "../../components/Iconify";

const RootStyle = styled('div')(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0 ),
 
}));
  
export default function DashboardFooter( ) {
    return (
      <RootStyle>   
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <Footer />
          </Stack>
       </RootStyle>
    );
  }