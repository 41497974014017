import { useSelector, useDispatch } from 'react-redux';  
import { Form, FormikProvider, useFormik } from 'formik';
import { useState, useEffect  } from 'react';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// material
import { Grid, Button, Stack, Typography, TextField, Box, InputAdornment, DialogContent, Dialog, FormControl, InputLabel, Select, Chip, OutlinedInput, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components 
import { getClientMealOptions, getClientSubscriptionPlanDetails } from '../../../store/coach/reducer'; 
import { addActivity, fetchActivity } from '../../../data/network/activity/api'; 
import { scheduleUserActivity, fetchActivitySchedule, fetchMealPlanSchedule, addMealPlanSchedule } from '../../../data/network/client/api';
import { getActivityList } from '../../../store/activity/reducer';
import { formatDate } from '../../../utils/date';
import { calendarMealType, mealPreferences } from '../../../constants/app_constants';
import { getPartnerDish, getPartnerMealList } from '../../../store/user/reducer';

export default function ScheduleMealForm( {userId, date, handleClose, visibleRange} ) {
    const theme = useTheme(); 
 
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const dispatch = useDispatch(); 
    
    const [mealDate, setMealDate] = useState(new Date(date)); 
    const [selectedDish, setSelectedDish] = useState([]); 
    const [selectedMeals, setSelectedMeals] = useState([]); 
    const [mealOptions, setMealOptions] = useState([]); 

    const subscribedPlanDetails = useSelector(state => getClientSubscriptionPlanDetails(state));

    const clientMealOptions = useSelector(state => getClientMealOptions(state));
    const partnerMealOptions = useSelector(state => getPartnerDish(state));

    const mealList = useSelector(state => getPartnerMealList(state));
    const mealListExtracted = mealList?.data

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
    }; 

    useEffect(() => {
        console.log(subscribedPlanDetails);
    }, [subscribedPlanDetails])

    useEffect(() => {
        // console.log(clientMealOptions);
        setMealOptions(clientMealOptions);
        try{
        //     const subscriptionType = activeClient.subscription.order_type;
        //     if(subscriptionType === "ZERO_DELIVERY"){
        //         setMealOptions(partnerMealOptions);
        //     }
        }catch(e) {
            console.log(e);
        }
    },[clientMealOptions, userId])
  
    const handleScheduleMeal = () => {
        try{
            console.log(selectedMeals);
            // alert(selectedMeals[0].meal_id);
        }catch(e) {
            console.log(e);
        }

        addMealPlanSchedule(dispatch, cookies, {
            "meal_type": formik.values.meal_type,
            "preference": formik.values.meal_preference,
            "meal_ids" : selectedMeals,
            // "meal_id" : selectedMeals.map(String),
            // "meal_id" : selectedMeals[0].meal_id,
            // "meal_id" : 1,
            'date': date,
            'user_id': userId , 
        }, false);

        fetchMealPlanSchedule(dispatch, cookies, {
            user_id: userId,
            "start_date" : formatDate(visibleRange.start_date, "yyyy-MM-DD"),
            "end_date" : formatDate(visibleRange.end_date, "yyyy-MM-DD"), 
        });

        handleClose();
    };

    const handleMealDateChange = (newValue) => {
        setMealDate(newValue);
    }

    const ActivitySchema = Yup.object().shape({
        dish_id: Yup.string().required('Dish is required'),
    });
    
    const formik = useFormik({
        initialValues: {
            meal_preference: subscribedPlanDetails.meal_preference === 1 ? 'vegetarian' : 'non-vegetarian',
            meal_id: (mealListExtracted != null && mealListExtracted[0]) ? mealListExtracted[0].meal_id : '',
            meal_type: (subscribedPlanDetails != null && subscribedPlanDetails.meal_types[0]) ? subscribedPlanDetails.meal_types[0] : '',
            date : mealDate
        },
        validationSchema: ActivitySchema,
        onSubmit: () => {
  
        },
    });

    const handleChange = (event) => {
        const {
          target: { value },
        } = event; 
  
        setSelectedMeals(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (  
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid item container direction="column" justifyContent="center">                                    
                    <Box margin={2} />
                    <Stack direction={'row'} spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                // label=""
                                inputFormat="dd MMM yyyy"
                                value={mealDate}
                                onChange={handleMealDateChange}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </LocalizationProvider>
                    </Stack>
                    {/* <Box margin={1} />
                    <Stack direction={'row'} spacing={2}>
                        <TextField 
                            fullWidth
                            select
                            disabled
                            label="Meal Preference"
                            {...getFieldProps('meal_preference')}
                            error={Boolean(touched.meal_preference && errors.meal_preference)}
                            helperText={touched.meal_preference && errors.meal_preference}
                            SelectProps={{
                                native: true,
                            }}
                            variant="filled" >
                            {mealPreferences != null && mealPreferences.map((option) => (
                                <option key={option.text} value={option.text}>
                                    {option.display_name}
                                </option>
                            ))}
                        </TextField>
                    </Stack> */}
                    <Box margin={1} />
                    <Stack direction={'row'} spacing={2}>
                        <TextField 
                        fullWidth
                            select
                            label="Select Meal Type"
                            {...getFieldProps('meal_type')}
                            error={Boolean(touched.meal_type && errors.meal_type)}
                            helperText={touched.meal_type && errors.meal_type}
                            SelectProps={{
                                native: true,
                            }}
                            // variant="filled"
                        >
                            {calendarMealType != null && calendarMealType.map((option) => (

                                subscribedPlanDetails && subscribedPlanDetails.meal_types
                                && subscribedPlanDetails.meal_types.includes(option.id) &&
                                    <option key={option.id} value={option.id}>
                                        {option.text}
                                    </option>
                            ))}
                        </TextField> 
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                label="Meal Date"
                                inputFormat="dd MMM yyyy"
                                value={mealDate}
                                onChange={handleMealDateChange}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </LocalizationProvider> */}
                    </Stack>
                    <Box margin={1} />
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="select-dishes-label">Select Meal</InputLabel>
                            <Select
                                labelId="select-dishes-label"
                                id="select-dishes"
                                multiple
                                value={selectedMeals}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-dish" label="Dish" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}> 
                                    {
                                        selected && selected.map((value) =>   
                                        mealListExtracted && mealListExtracted.map((element) => ( 
                                            (element.meal_id === value) 
                                            ? <Chip key={element.meal_id} label={element.display_name} /> 
                                            : null
                                        ))
                                        ) 
                                    }
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                            { mealListExtracted && mealListExtracted.length>0 && mealListExtracted.map((e) => (
                                <MenuItem
                                    key={e.meal_id}
                                    value={e.meal_id} 
                                >
                                    {e.display_name}
                                </MenuItem>
                            ))} 
                            </Select>
                        </FormControl>
                    </Stack>
                    <Box margin={2} />
                    
                </Grid>

                <Grid item container direction="row" justifyContent="center">
                    <Button variant='contained' onClick={() => {
                        handleScheduleMeal();
                    }}>
                        Schedule
                    </Button>                            
                </Grid>
            </Form>
        </FormikProvider>
    );
}
