import { Box, Container } from "@mui/material";
import Page from "../../../components/Page";
import { StoreStaffList } from "./StoreStaffList";

export const StoreStaff = () => {

    return (
        <Page title="Staff">
            <Container size="xl">
                <Box margin={1} />
                <StoreStaffList />
            </Container>
        </Page>
    );
}