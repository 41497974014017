// @mui
import { Card, Box, Divider, Grid, Typography} from '@mui/material';
import { useEffect, useState, useRef } from 'react';

import { useSelector } from 'react-redux';
import AliceCarousel from 'react-alice-carousel';
// import Swiper , { Navigation, Pagination } from 'swiper';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.css";

// import { SwiperSlide } from 'swiper/react';
import 'react-alice-carousel/lib/alice-carousel.css';
import NutritionChart  from './NutritionChart';
import ActivityChart  from './ActivityChart';
import { getActiveClientReport } from '../../../store/coach/reducer';

import '../../../assets/css/Report.css';

// import 'swiper/swiper.scss';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// ----------------------------------------------------------------------
 
export default function ConsumptionActivityReport() {

  const handleDragStart = (e) => e.preventDefault();

  const clientReport = useSelector(state => getActiveClientReport(state));

  const [items, setItems] = useState([]);

  SwiperCore.use([Navigation, Pagination]);

  useEffect(() => {

    const cards = [];

    if(clientReport && clientReport !== null && clientReport.activity_report !== null){
      clientReport.activity_report.slice().reverse().forEach((element, i) => {
        cards.push(
          <Card className='glass-box' margin={2} padding={2} key={element.id} onDragStart={handleDragStart} sx={{padding: 2}}>
            <Typography variant="h5">
              {element.label}
            </Typography>
            <Box margin={2} />
            <NutritionChart data={clientReport.consumption_report[i]} />
            <Box margin={2} />
            <ActivityChart  data={clientReport.activity_report[i]}/>
          </Card>
        );
      });
    }
    setItems(cards);
  },[clientReport]);


  // const navigationPrevRef = useRef(null)
  // const navigationNextRef = useRef(null)

  return (

      <Swiper
        slidesPerView={3}
        spaceBetween={10}
        slidesPerGroup={3}
        // centeredSlides
        // effect={"coverflow"}
        // grabCursor
        loop={false}
        // loopFillGroupWithBlank
        pagination={{
          clickable: true
        }}
        navigation
      //   navigation={{
      //     prevEl: navigationPrevRef.current,
      //     nextEl: navigationNextRef.current,
      //   }}
         
      //  onBeforeInit={(swiper) => {
      //       swiper.params.navigation.prevEl = navigationPrevRef.current;
      //       swiper.params.navigation.nextEl = navigationNextRef.current;
      //  }}
        modules={[Pagination, Navigation]}
        className="consumption-swiper">
          {
            items.map((e) => {
              return <SwiperSlide>
                {e}
              </SwiperSlide>
            })
          }
        {/* <div ref={navigationPrevRef} />
        <div ref={navigationNextRef} /> */}
      </Swiper>
  );
}