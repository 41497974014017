import { Container } from "@mui/material";
import Page from "../../../components/Page";
import { StoreSalesList } from "./StoreSalesList";

export const StoreSales = () => {

    return (
        <Page title="Sales">
            <Container size="xl">
                <StoreSalesList />
            </Container>
        </Page>
    );
}