
import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import { setCorporateUserList, setCorporateInvoiceList, setCorporateTransactionList, setCorporateReport, setCorporateFeedback, setCorporateOrder, setCorporateMealSchedule, setCorporateVarietyReport, setCorporateMealMenu, setCorporateMealList } from '../../../store/corporate/reducer';
import axios from '../../../utils/axios-configure';
import { API_GET_CORPORATE_FEEDBACK, API_GET_CORPORATE_INVOICE, API_GET_CORPORATE_ORDER, API_GET_CORPORATE_REPORT, API_GET_CORPORATE_TRANSACTION, API_GET_CORPORATE_USERS, API_GET_CORPORATE_MEAL_SCHEDULE, API_GET_CORPORATE_MEAL_MENU, API_GET_CORPORATE_MEAL_LIST } from '../endpoints';
import { getAccessTokenFromCookie } from "../session/api";
import { SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { USER_DELETE, USER_UPDATE, MEAL_UPDATE } from '../../../constants/message_constants';


export const fetchCorporateUsers = (dispatch, cookies, data, activeClient, page = 1, filterBy = { key: '', value: '' }) => {
  dispatch(setLoadingStatus({ status: true }));
  const accessToken = getAccessTokenFromCookie(dispatch, cookies);
  if (accessToken != null) {
    axios({
      method: "get",
      url: `${API_GET_CORPORATE_USERS}?page=${page}&count=10&corporate_id=${activeClient?.id}&filter=${filterBy?.key}&term=${filterBy?.value}`,
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
    })
      .then((response) => {
        // handle success
        if (response.status === 200) {
          dispatch(setCorporateUserList({ data: response.data }));
        } else {
          //
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
      })
      .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
      });
  } else {
    dispatch(setLoadingStatus({ status: false }));
    //
  }
}

export const fetchCorporateInvoice = (dispatch, cookies, data, activeClient, page = 1, filterBy = { key: '', value: '', startDate: '', endDate: '' }) => {
  dispatch(setLoadingStatus({ status: true }));
  const accessToken = getAccessTokenFromCookie(dispatch, cookies);
  if (accessToken != null) {
    axios({
      method: "get",
      url: `${API_GET_CORPORATE_INVOICE}?page=${page}&count=20&corporate_id=${activeClient?.id}&filter=${filterBy?.key}&term=${filterBy?.value}&start_date=${filterBy?.startDate}&end_date=${filterBy?.endDate}`,
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
    })
      .then((response) => {
        // handle success
        if (response.status === 200) {
          dispatch(setCorporateInvoiceList({ data: response.data }));
        } else {
          //
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
      })
      .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
      });
  } else {
    //
    dispatch(setLoadingStatus({ status: false }));
  }
}

export const fetchCorporateTransaction = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({ status: true }));
  const accessToken = getAccessTokenFromCookie(dispatch, cookies);
  if (accessToken != null) {
    axios({
      method: "get",
      url: `${API_GET_CORPORATE_TRANSACTION}`,
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
    })
      .then((response) => {
        // handle success
        if (response.status === 200) {
          dispatch(setCorporateTransactionList({ data: response.data.data }));
        } else {
          //
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
      })
      .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
      });
  } else {
    //
    dispatch(setLoadingStatus({ status: false }));
  }
}

export const fetchCorporateStatsReport = (dispatch, cookies, activeClient, startDate, endDate) => {
  dispatch(setLoadingStatus({ status: true }));
  const accessToken = getAccessTokenFromCookie(dispatch, cookies);
  if (accessToken != null) {
    axios({
      method: "get",
      url: `${API_GET_CORPORATE_REPORT}/stats?corporate_id=${activeClient?.id}&start_date=${startDate}&end_date=${endDate}`,
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
    })
      .then((response) => {
        // handle success
        if (response.status === 200) {
          dispatch(setCorporateReport({ data: response.data.data }));
        } else {
          //
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
      })
      .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
      });
  } else {
    //
    dispatch(setLoadingStatus({ status: false }));
  }
}

export const fetchCorporateVarietyReport = (dispatch, cookies, activeClient, startDate, endDate) => {
  dispatch(setLoadingStatus({ status: true }));
  const accessToken = getAccessTokenFromCookie(dispatch, cookies);
  if (accessToken != null) {
    axios({
      method: "get",
      url: `${API_GET_CORPORATE_REPORT}/variety?corporate_id=${activeClient?.id}&start_date=${startDate}&end_date=${endDate}`,
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
    })
      .then((response) => {
        // handle success
        if (response.status === 200) {
          dispatch(setCorporateVarietyReport({ data: response.data.data }));
        } else {
          //
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
      })
      .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
      });
  } else {
    //
    dispatch(setLoadingStatus({ status: false }));
  }
}

export const fetchCorporateFeedback = (dispatch, cookies, activeClient, startDate, endDate) => {
  dispatch(setLoadingStatus({ status: true }));
  const accessToken = getAccessTokenFromCookie(dispatch, cookies);
  if (accessToken != null) {
    axios({
      method: "get",
      url: `${API_GET_CORPORATE_FEEDBACK}?corporate_id=${activeClient?.id}&start_date=${startDate}&end_date=${endDate}`,
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
    })
      .then((response) => {
        // handle success
        if (response.status === 200) {
          dispatch(setCorporateFeedback({ data: response.data.data }));
        } else {
          //
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
      })
      .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
      });
  } else {
    //
    dispatch(setLoadingStatus({ status: false }));
  }
}



export const fetchCorporateOrders = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({ status: true }));
  const accessToken = getAccessTokenFromCookie(dispatch, cookies);

  if (accessToken != null) {
    axios({
      method: "get",
      url: `${API_GET_CORPORATE_ORDER}`,
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
    })
      .then((response) => {
        // handle success
        if (response.status === 200) {
          dispatch(setCorporateOrder({ data: response.data.data }));
        } else {
          //
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
      })
      .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
      });
  } else {
    //
    dispatch(setLoadingStatus({ status: false }));
  }
}

export const deleteCorporateUser = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({ status: true }));

  const accessToken = getAccessTokenFromCookie(dispatch, cookies);

  if (accessToken != null) {
    axios({
      method: "delete",
      url: `${API_GET_CORPORATE_USERS}`,
      data,
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
    })
      .then((response) => {
        // handle success
        if (response.status === 200) {

          dispatch(setSnackbarMessage({ 'message': USER_DELETE.SUCCESS, 'severity': SNACKBAR_SEVERITY.SUCCESS }));
          dispatch(setSnackbarVisibilityStatus({ 'status': true }));
        } else {
          dispatch(setSnackbarMessage({ 'message': USER_DELETE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
          dispatch(setSnackbarVisibilityStatus({ 'status': true }));
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
      })
      .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
        dispatch(setSnackbarMessage({ 'message': USER_DELETE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
        dispatch(setSnackbarVisibilityStatus({ 'status': true }));
      });
  } else {
    dispatch(setLoadingStatus({ status: false }));
    dispatch(setSnackbarMessage({ 'message': USER_DELETE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
    dispatch(setSnackbarVisibilityStatus({ 'status': true }));
  }
}

export const updateCorporateUser = (dispatch, cookies, data, activeClient) => {
  const accessToken = getAccessTokenFromCookie(dispatch, cookies);
  dispatch(setLoadingStatus({ status: true }));
  if (accessToken != null) {
    axios({
      method: "post",
      url: `${API_GET_CORPORATE_USERS}?corporate_id=${activeClient?.id}`,
      data,
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
    })
      .then((response) => {
        // handle success
        if (response.status === 200) {

          dispatch(setSnackbarMessage({ 'message': USER_UPDATE.SUCCESS, 'severity': SNACKBAR_SEVERITY.SUCCESS }));
          dispatch(setSnackbarVisibilityStatus({ 'status': true }));
          fetchCorporateUsers(dispatch, cookies, '', activeClient)
        } else {
          dispatch(setSnackbarMessage({ 'message': USER_UPDATE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
          dispatch(setSnackbarVisibilityStatus({ 'status': true }));
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
      })
      .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
        dispatch(setSnackbarMessage({ 'message': USER_UPDATE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
        dispatch(setSnackbarVisibilityStatus({ 'status': true }));
      });
  }
  dispatch(setLoadingStatus({ status: false }));
}

export const fetchCorporateMealSchedule = (dispatch, cookies, data, activeClient, startDate=null, endDate=null) => {
  dispatch(setLoadingStatus({ status: true }));
  const accessToken = getAccessTokenFromCookie(dispatch, cookies);
  let url = `${API_GET_CORPORATE_MEAL_SCHEDULE}?corporate_id=${activeClient?.id}`

  if (startDate != null) {
    url += `&startDate=${startDate}`;
  }

  if (endDate != null) {
      url += `&end_date=${endDate}`;
  }

  if (accessToken != null) {
    axios({
      method: "get",
      url,
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
    })
      .then((response) => {
        // handle success
        if (response.status === 200) {
          dispatch(setCorporateMealSchedule({ data: response.data.data }));
        } else {
          //
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
      })
      .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
      });
  } else {
    dispatch(setLoadingStatus({ status: false }));
    //
  }
}

export const fetchCorporateMealMenu = (dispatch, cookies, clientName, date, serviceId) => {
  dispatch(setLoadingStatus({ status: true }));
  const accessToken = getAccessTokenFromCookie(dispatch, cookies);
  if (accessToken != null) {
    axios({
      method: "get",
      url: `${API_GET_CORPORATE_MEAL_MENU}?org=${clientName?.name}&date=${date}&service_id=${serviceId}`,
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
    })
      .then((response) => {
        // handle success
        if (response.status === 200) {
          dispatch(setCorporateMealMenu({ data: response.data.data }));
        } else {
          //
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
      })
      .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
      });
  } else {
    dispatch(setLoadingStatus({ status: false }));
    //
  }
}


export const fetchCorporateMealList = (dispatch, cookies, clientName, date, serviceId, term='') => {
  dispatch(setLoadingStatus({ status: true }));
  const accessToken = getAccessTokenFromCookie(dispatch, cookies);
  if (accessToken != null) {
    let url = `${API_GET_CORPORATE_MEAL_LIST}?corporate_id=${clientName?.id}&date=${date}&service_id=${serviceId}&count=1999`;
    if (term !== ''){
      url = `${url}&term=${term}`
    }
    axios({
      method: "get",
      url,
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
    })
      .then((response) => {
        // handle success
        if (response.status === 200) {
          dispatch(setCorporateMealList({ data: response.data.data }));
        } else {
          //
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
      })
      .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
      });
  } else {
    dispatch(setLoadingStatus({ status: false }));
    //
  }
}


export const createUpdateCorporateMealMenu = (dispatch, cookies, data, activeClient) => {
  const accessToken = getAccessTokenFromCookie(dispatch, cookies);
  dispatch(setLoadingStatus({ status: true }));
  if (accessToken != null) {
    axios({
      method: "post",
      url: `${API_GET_CORPORATE_MEAL_SCHEDULE}?corporate_id=${activeClient?.id}`,
      data,
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
    })
      .then((response) => {
        // handle success
        if (response.status === 200) {

          dispatch(setSnackbarMessage({ 'message': MEAL_UPDATE.SUCCESS, 'severity': SNACKBAR_SEVERITY.SUCCESS }));
          dispatch(setSnackbarVisibilityStatus({ 'status': true }));
          fetchCorporateMealSchedule(dispatch, cookies, '', activeClient)
        } else {
          dispatch(setSnackbarMessage({ 'message': MEAL_UPDATE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
          dispatch(setSnackbarVisibilityStatus({ 'status': true }));
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
      })
      .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
        dispatch(setSnackbarMessage({ 'message': MEAL_UPDATE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
        dispatch(setSnackbarVisibilityStatus({ 'status': true }));
      });
  }
  dispatch(setLoadingStatus({ status: false }));
}