import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, Typography, Container } from '@mui/material';
// components
import { BaseOptionChart } from '../../../components/chart';

// ----------------------------------------------------------------------
 
export default function ActivityChart( {data} ) {

  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  
  useEffect(() => {

    setChartLabels([
      'Pending',
      'Completed',
    ]); 
    setChartData([data.not_completed_activity_count, data.completed_activity_count]);
  },[data]);

  const chartOptions = merge(BaseOptionChart(), {
    // plotOptions: { bar: { columnWidth: '16%' } },
    // fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    // xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} %`;
          }
          return y;
        },
      },
    },
    legend: {
      position: 'bottom'
    }
  });

  return (
    <Box>
      <Typography variant="body1">Activity</Typography>
      <Box  dir="ltr">
        <ReactApexChart type="pie" series={chartData} options={chartOptions} height={200} />
      </Box>
    </Box>
  );
}
