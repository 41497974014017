import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import ClientLayout from './layouts/client';
import LogoOnlyLayout from './layouts/LogoOnlyLayout'; 
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Profile from './pages/Profile';


import ClientApp from './pages/ClientApp';
import ScheduleMeal from './pages/coach/ScheduleMeal';
import ScheduleActivity from './pages/coach/ScheduleActivity';
import Journal from './pages/coach/Journal'; 
import PartnerReport from './pages/coach/Report';
import PartnerActivity from './pages/coach/Activity';

import CorporateDashboard from './pages/corporate/CorporateDashboard';
import CorporateProfile from './pages/corporate/CorporateProfile';
import CorporateSpashScreen from './pages/corporate/CorporateSpashScreen';
import CorporateUser from './pages/corporate/CorporateUser';
import CorporateReport from './pages/corporate/CorporateReport';
import CorporateFeedback from './pages/corporate/CorporateFeedback';
import CorporateBilling from './pages/corporate/CorporateBilling';
import CorporateCalendar from './pages/corporate/CorporateCalendar';
import CorporateFaqs from './pages/corporate/CorporateFaqs';
import CorporateTermConditions from './pages/corporate/CorporateTermConditions';
import Dish from './pages/coach/Dish';
import KitchenReport from './pages/kitchen/Report';
import KitchenOrderManifest from './pages/kitchen/ManifestOrders';
import KitchenSubscriptions from './pages/kitchen/Subscriptions';
import CorporateInvoice from './pages/corporate/CorporateInvoice';
import UserProfile from './pages/coach/UserProfile';
import Meals from './pages/coach/Meals';
import { StoreOrder } from './pages/store/order/StoreOrder';
import { StoreStock } from './pages/store/stock/StoreStock';
import { StoreStaff } from './pages/store/staff/StoreStaff';
import { StoreSales } from './pages/store/sales/StoreSales';
import { StoreExpenses } from './pages/store/expense/StoreExpenses';
import { StoreVendor } from './pages/store/vendor/StoreVendor';
import { StoreInvoices } from './pages/store/invoice/StoreInvoice';
import { StoreMenu } from './pages/store/menu/StoreMenu';
import { StoreRoles } from './pages/store/roles/StoreRoles';
import { CoachClient } from './pages/coach/client/CoachClient';
import { MealPlan } from './pages/coach/meal_plan/MealPlan';
import CorporateVendorMenu from './pages/corporate_vendor/CorporateVendorMenu';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
      ],
    },
    {
      path: '/client',
      element: <ClientLayout />,
      children: [
        { path: 'home', element: <ClientApp /> },
        // { path: 'schedule_meal/:user_id', element: <ScheduleMeal /> },
        // { path: 'schedule_activity/:user_id', element: <ScheduleActivity /> },
        // { path: 'report', element: <Report /> },
        // { path: 'journal', element: <Journal /> },
      ],
    },
    {
      path: '/coach',
      element: <DashboardLayout />,
      children: [  
        { path: '', element: <Navigate to="client" /> },
        { path: 'client_old', element: <User /> },
        { path: 'client', element: <CoachClient /> },
        // { path: 'profile', element: <UserProfile /> },
        { path: 'report', element: <PartnerReport /> },
        { path: 'schedule_meal', element: <ScheduleMeal /> },
        { path: 'schedule_activity', element: <ScheduleActivity /> },
        { path: 'activity', element: <PartnerActivity /> },
        { path: 'journal', element: <Journal /> },
        { path: 'dish', element: <Dish /> },
        { path: 'meals', element: <Meals /> },
        { path: 'meal_plan', element: <MealPlan /> },
      ],
    },
    {
      path: '/coach',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'profile', element: <UserProfile /> }
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }, 
      ],
    },
    {
      path: '/profile',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Profile /> }, 
      ],
    },
    {
      path: '/corporate/',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <CorporateSpashScreen /> }, 
        { path: 'dashboard', element: <CorporateDashboard /> }, 
        { path: 'profile', element: <CorporateProfile /> }, 
        { path: 'users', element: <CorporateUser /> }, 
        { path: 'report', element: <CorporateReport /> }, 
        { path: 'invoices', element: <CorporateInvoice /> }, 
        { path: 'feedback', element: <CorporateFeedback /> },
        { path: 'billing', element: <CorporateBilling /> },
        { path: 'calendar', element: <CorporateCalendar /> },
        { path: 'faqs', element: <CorporateFaqs /> },
        { path: 'terms', element: <CorporateTermConditions /> },
      ],
    },
    {
      path: '/kitchen/',
      element: <DashboardLayout />,
      children: [
        { path: 'report', element: <KitchenReport /> },
        { path: 'orders/manifest', element: <KitchenOrderManifest /> },
        { path: 'subscription', element: <KitchenSubscriptions /> },
      ],
    },
    {
      path: '/store/',
      element: <DashboardLayout />,
      children: [
        { path: 'orders', element: <StoreOrder /> },
        { path: 'menu', element: <StoreMenu /> },
        { path: 'invoices', element: <StoreInvoices /> },
        { path: 'vendor', element: <StoreVendor /> },
        { path: 'expenses', element: <StoreExpenses /> },
        { path: 'sales', element: <StoreSales /> },
        { path: 'staff', element: <StoreStaff /> },
        { path: 'stock', element: <StoreStock /> },
        { path: 'roles', element: <StoreRoles /> },
        { path: 'faqs', element: <CorporateFaqs /> },
        { path: 'terms', element: <CorporateTermConditions /> },
      ],
    },
    {
      path: '/corporate_vendor/',
      element: <DashboardLayout />,
      children: [
        { path: 'menu', element: <CorporateVendorMenu /> },
        { path: '', element: <Navigate to="/corporate_vendor/menu" replace />}
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
