import { useSelector, useDispatch } from 'react-redux';  
import { Form, FormikProvider, useFormik } from 'formik';
import { useState  } from 'react';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';

// material
import { Grid, Button, Stack, Typography, TextField, Box, InputAdornment, DialogContent, Dialog, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { addDays, subDays } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import { formatDate } from '../../../utils/date';
import { getActiveClient } from '../../../store/coach/reducer';
import { pauseClientSubscription } from '../../../data/network/client/api';

// components 

export default function PauseSubscriptionForm( {clientId, mealPlan, handleClose} ) {
    const theme = useTheme();
    // const clientDetails = useSelector(state => getActiveClient(state));
 
    // const activityCategoryList = useSelector(state => getActivityCategoryList(state));
     

    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const dispatch = useDispatch(); 
    
    const [startDateValue, setStartDateValue] =  useState(
        new Date() 
    );

    const [endDateValue, setEndDateValue] =  useState(
        new Date() 
    );

    const handleStartDateChange = (newValue ) => {
        setStartDateValue(newValue);
        setEndDateValue(newValue);
    };

    const handleEndDateChange = (newValue ) => {
        setEndDateValue(newValue);
    };

    const handlePauseSubscription = () => {

        pauseClientSubscription(dispatch, cookies, {
            "user_id" : clientId,
            "meal_plan_id" : mealPlan.id,
            "start_date" : formatDate(startDateValue, "YYYY-MM-DD"),
            "end_date" : formatDate(endDateValue, "YYYY-MM-DD"),
        });

        
        
        handleClose(); 
    };

    const ActivitySchema = Yup.object().shape({
        start_date: Yup.string().required('Title is required'),
        end_date: Yup.string().required('Description is required'), 
    });

    const formik = useFormik({
        initialValues: {
          start_date: addDays(new Date(),1),
          end_date: '',
      },
        validationSchema: ActivitySchema,
        onSubmit: () => {
  
        },
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (  
        
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

                        <Grid  sx={{padding:2, borderRadius: 2}}> 
                            <Grid container direction="column"  > 
                                <Grid item container direction="row" justifyContent="flex-start">
                                    <Typography variant="h6" gutterBottom>
                                        Pause Subscription
                                    </Typography>
                                </Grid> 
                                <Grid item container direction="column" justifyContent="center">
                                    
                                    <Box margin={2} />
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <MobileDatePicker
                                                label="Start Date"
                                                inputFormat="dd MMM yyyy"
                                                value={startDateValue}
                                                minDate={addDays(new Date(),1)}
                                                // maxDate={subDays(new Date(mealPlan.active_to), 1)}
                                                onChange={handleStartDateChange}
                                                renderInput={(params) => <TextField fullWidth {...params} />}
                                            />
                                        
                                        </LocalizationProvider>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <MobileDatePicker
                                                label="End Date"
                                                inputFormat="dd MMM yyyy"
                                                value={endDateValue}
                                                onChange={handleEndDateChange}
                                                renderInput={(params) => <TextField fullWidth {...params} />}
                                            />
                                        
                                        </LocalizationProvider>
                                    </Stack>
                                    <Box margin={2} />
                                     

                                    <Grid item container direction="row" justifyContent="center">
                                        <Button variant='contained' onClick={() => {
                                            handlePauseSubscription();
                                        }}>
                                            Pause
                                        </Button>                            
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                </FormikProvider>  

    );
}
