import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Typography, Chip, Grid, Container, Box, Stack, Card, Table, Autocomplete, Paper, TableContainer, styled, Divider, Button, Select, FormControl, TextField, Modal, InputLabel, MenuItem, IconButton, TableRow, TableCell, Collapse, TableHead, TableBody } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {BrowserView, MobileView, isMobile} from 'react-device-detect';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { func } from 'prop-types';
import { useForm, Controller } from 'react-hook-form';


import { fetchCorporateMealSchedule, fetchCorporateMealMenu, createUpdateCorporateMealMenu, fetchCorporateMealList } from '../../data/network/corporate/api';
import { getUserId, getActiveCorporateClient, getUser } from '../../store/user/reducer';
import { getCorporateMealSchedule, getCorporateMealMenu, getCorporateMealList } from '../../store/corporate/reducer';
import { formatDate } from '../../utils/date';
// components
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import '../../assets/css/MealCalendar.css';

export default function CorporateCalendar() {
    const [formData, setformData] = useState([{
        meal_id: '',
        display_name: '',
        quantity: '',
    }]);
    const [date, setDate] = useState(formatDate(Date()));
    const [service, setService] = useState('');
    const menuLists = useSelector(state => getCorporateMealMenu(state));
    const mealList = useSelector(state => getCorporateMealList(state));

    const {control} = useForm(); 

    const [optionsList, setOptionsList] = useState([{"label": "No Meal Options Found"}]);

    const [openModal, setOpenModal] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = (clickedDate) => {
        setOpenModal(true);
        setDate(clickedDate);
    }
    const handleClose = () => {
        setformData([{
            meal_id: '',
            display_name: '',
            quantity: '',
        }])
        setDate(null);
        setService('');
        setOpenModal(false);
    }
    const appointments = [];
    const [data, setData] = useState(appointments);
    const [selectedData, setSelectedData] = useState([])
    const dispatch = useDispatch();
    const partnerId = useSelector(state => getUserId(state));
    const activeClient = useSelector(state => getActiveCorporateClient(state));
    const mealSchedule = useSelector(state => getCorporateMealSchedule(state));
    const partnerDetails = useSelector(state => getUser(state));
    const [cookies] = useCookies(['mobile', 'session', 'access_token']);

    const tableRef = useRef();

    const headerToolbar = {
        left:'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
    }

    const mobileHeaderToolbar = {
        left: 'prev,next today',
        right: 'title'
    }

    const ModalBoxStyled = styled(Box)(({theme}) => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        background: '#fff',
        borderRadius: '5px',
        padding: '20px',
        maxHeight: '120vh',
        overflow: 'visible',
        [theme.breakpoints.down('md')]: {
            width: '90%',
         },
        '& .box': {
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
        },
        '& .items': {
            display: 'flex',
            gap: '10px',
        },
        '& .container': {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            marginBottom: '10px',
            flex: 1,
        },
        '& .MuiInputBase-root': {
            flex: 1,
            width: 'auto',
        }
    }))

    const handleAdd = () => {
        setformData([...formData, { meal_id: '', quantity: '', display_name: '' }])
    }

    const handleDelete = (index) => {
        const newArray = [...formData];
        newArray.splice(index, 1);
        setformData(newArray);
    };

    const handleChange = (event) => {
        const id = event.target.value
        setService(id);
        if (partnerId != null) {
            // fetchCorporateMealMenu(dispatch, cookies, activeClient, date, id);
            fetchCorporateMealList(dispatch, cookies, activeClient, date, id);
        }
        // setformData([{ dish_id: '', quantity: '', }])
        setformData([{ meal_id: '', quantity: '', display_name: ''}]);
    }

    const handleStartDate = (newValue) => {
        setDate(formatDate(newValue));
    };

    const handleEventChange = (event, index) => {
        const { name, value } = event.target;

        const onChangeValue = [...formData];

        onChangeValue[index][name] = value;
        setformData(onChangeValue);
    };

    const handleMealSelectChange = (event, data, index) => {
        const { label, id, name } = data;
        const onChangeValue = [...formData];
        onChangeValue[index][name] = id;
        onChangeValue[index].display_name = label;
        setformData(onChangeValue);
    };

    const handleSubmit = () => {
        const data = {
            "corporate_id": activeClient?.id,
            "data": [
                {
                    "date": date,
                    "service_id": service,
                    "menu_options": formData,
                }
            ]
        }
        if (partnerId != null) {
            createUpdateCorporateMealMenu(dispatch, cookies, data, activeClient);
        }
        handleClose();
    }

    const handleInputChange = (event, value, reason) => {
        console.log(event);
        console.log(value);
        console.log(reason);
    };

    const onSelectMeal = (data, index) => {
        const onChangeValue = [...formData];
        onChangeValue[index].meal_id = data.meal_id;
        onChangeValue[index].display_name = data.display_name;
        setformData(onChangeValue);
    }

    const onInputChange = (event) => {
        const value = event.target.value;
        if (value && value.length > 3) {
            console.log(value);
            // setOptionsList([]);
            fetchCorporateMealList(dispatch, cookies, activeClient, date, data?.service_id, value);
        //   getData(value);
        } else {
        //   setOptions([]);
        }
    };

    const StackStyled = styled(Stack)(({theme}) => ({
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            '& .fc': {
                width: '100%',
            },
            '& .fc .fc-toolbar-title': {
                fontSize: '16px',
            }
        },
    }))

    const BoxStyled = styled('div')(({theme}) => ({
        display: 'flex',
        flexDirection: 'column',
        width: '25%',
        [theme.breakpoints.down('md')]: {
           width: '100%',
           marginLeft: '0px !important',
        },
        '& .event': {
            alignSelf: 'end',
            background: '#1a252f',
            [theme.breakpoints.down('md')]: {
                marginTop: '20px',
             },
        },
        '& .MuiCard-root': {
            padding: '15px',
            borderLeft: '6px solid #FDAA2E',
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            marginTop: '30px',
            '&.breakfast': {
                borderLeft: '6px solid #FDAA2E',
                '& .MuiDivider-root': {
                    borderColor: '#FDAA2E',
                }
            },
            '&.lunch': {
                borderLeft: '6px solid #2AC3A7',
                '& .MuiDivider-root': {
                    borderColor: '#2AC3A7',
                }
            },
            '&.dinner': {
                borderLeft: '6px solid #447CFF',
                '& .MuiDivider-root': {
                    borderColor: '#447CFF',
                }
            },
            '&.allday, &.snacks': {
                borderLeft: '6px solid #5C5C5C',
                '& .MuiDivider-root': {
                    borderColor: '#5C5C5C',
                }
            },
            '& .time': {
                display: 'flex',
                alignItems: 'center',
                gap: '1px',
                background: '#F5F6F8',
                padding: '5px',
                borderRadius: '10px',
            },
            '& .MuiTable-root th, .MuiTable-root td': {
                padding: '5px',
                fontSize: '12px',
                textAlign: 'left',
            },
            '& .menu': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                '& .MuiTypography-root': {
                    fontSize: '12px',
                    fontWeight: 'bold',
                },
                '& .MuiDivider-root': {
                    flex: 1,
                }

            }
        }
    }));

    const SlotStyled = styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        background: '#FFF6E8',
        alignItems: 'center',
        padding: '0 5px',
        borderLeft: '4px solid #FDAA2E',
        '&.breakfast': {
            borderLeft: '4px solid #FDAA2E',
            background: '#FFF6E8',
        },
        '&.lunch': {
            borderLeft: '4px solid #2AC3A7',
            background: '#F2FFFA',
        },
        '&.dinner': {
            borderLeft: '4px solid #447CFF',
            background: '#FAFBFF',
        },
        '&.allday, &.snacks': {
            borderLeft: '4px solid #5C5C5C',
            background: '#F9F9F9',
        },
        '& .MuiTypography-body1': {
            fontSize: '11px',
            color: '#000',
            fontWeight: 'bold',
        },
        '& .MuiTypography-caption': {
            fontSize: '11px',
            color: '#6D6D6D',
        },
    }));

    const renderEventContent = (eventInfo) => {
        const mealType = eventInfo?.event?.title.replace(' ', '').trim().toLowerCase();
        return (
            <SlotStyled className={mealType}>
                {/* <Typography variant='caption'>{eventInfo?.event?._def?.extendedProps?.service_start_time?.slice(0, 5)}</Typography> */}
                <Typography variant='body1'>{eventInfo?.event?.title} ({eventInfo?.event?._def?.extendedProps?.menu_options?.length})</Typography>
            </SlotStyled>
        )
    }

    useEffect(() => {
        if (mealSchedule && mealSchedule != null && mealSchedule.length >0) {
            const todayDate = new Date().toISOString().replace(/T.*$/, '');
            const data = mealSchedule.filter((ele) => ele.date === todayDate);
            setSelectedData(data)
        }
    }, [mealSchedule])

    useEffect(() => {
        if (partnerId != null) {
            fetchCorporateMealSchedule(dispatch, cookies, partnerId, activeClient);
        }
    }, [partnerId, activeClient])

    useEffect(() => {

        if (mealSchedule && mealSchedule != null) {
            mealSchedule.forEach(element => {
                appointments.push({
                    id: element.service_id,
                    extendedProps: {
                        ...element
                    },
                    title: element.service_label,
                    start: new Date(element.date),
                })
            });
            setData(appointments);
        }
    }, [mealSchedule]);

    useEffect(() => {
        const mealOptionList = []
        for (let i = 0; i < mealList.length; i += 1) {
            mealOptionList.push({label: mealList[i].display_name, id:mealList[i].meal_id, name: 'meal_id'})
        }
        setOptionsList(mealOptionList);
    },[mealList]);

    const handleDateClick = (arg) => { 
        const data = mealSchedule.filter((ele) => ele.date === arg.dateStr);
        setSelectedData(data);
        // handleOpen(arg.dateStr);

        const s = document.querySelectorAll('[data-date]');
        s.forEach((e) => {
            const x = e.getAttribute('data-date');
            if (x === arg.dateStr){
                e.classList.add('fc-selected-date');
            }else{
                e.classList.remove('fc-selected-date');
            }
        });

    }

    const handleEventClick = (arg) => {
        handleEdit(arg?.event?._def?.extendedProps);
    }

    const handleQuantityChange = (arg, index) => {
        const onChangeValue = [...formData];
        onChangeValue[index].quantity = arg.target.value;
        setformData(onChangeValue);
    }

    const handleEdit = (data) => {
        if (partnerId != null) {
            // fetchCorporateMealMenu(dispatch, cookies, activeClient, date, data?.service_id);
            fetchCorporateMealList(dispatch, cookies, activeClient, date, data?.service_id);
        }
        const items = data?.menu_options.map((ele) => ({
            quantity: ele?.total_quantity,
            meal_id: ele?.meal_id,
            display_name: ele?.display_name,
        }))
        setDate(data?.date);
        setService(data?.service_id);
        setformData(items)
        // setOpenModal(true);
    }

    function Row(props) {
        const { row } = props;
        const [rowExpanded, setRowExpanded] = useState(false);

        const id = row.meal_id;
        const displayName = row.display_name;
        const totalQuantity = row.total_quantity;
        const consumed = row.consumed;
        const description = row.description;

        const dishList = row.meal_details?.dishes;

        const _d = [];
        if (dishList && dishList.length > 0) {
            dishList.forEach((dish) => {
                let qty = "" 
                if (dish.portion_quantity != null){
                    qty = dish.portion_quantity
                }else if (dish.portion_size != null){
                    qty = dish.portion_size
                }
    
                _d.push({
                    'name': dish.display_name,
                    'qty' : qty,
                    'unit': dish.portion_unit
                })
            })
        }
        
        const StyledTableCell = withStyles((theme) => ({
            head: {
              backgroundColor: theme.palette.common.black,
              color: theme.palette.common.white,
            },
            body: {
              fontSize: 14,
            },
          }))(TableCell);
          
          const StyledTableRow = withStyles((theme) => ({
            root: {
              '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
              },
            },
          }))(TableRow);

        return (
          <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell component="th" scope="row">
                    {id}
                </TableCell>
                <TableCell align="center">
                    <Typography variant="body1" onClick={() => setRowExpanded(!rowExpanded)} sx={{cursor:'pointer'}}>{displayName}</Typography>
                </TableCell>
                <TableCell align="center">{totalQuantity}</TableCell>
                <TableCell align="center">{consumed}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                <Collapse in={rowExpanded} timeout="auto" unmountOnExit>
                  {/* <Box sx={{ margin: 1, backgroundColor: '#ffffffcc', padding:1 }}> */}
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                            <StyledTableCell align="right">Dish</StyledTableCell>
                            <StyledTableCell align="right">Serving Size</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_d.map((e) => (
                            <StyledTableRow key={e.name}>
                                <StyledTableCell align="right">{e.name}</StyledTableCell>
                                <StyledTableCell align="right">{e.qty} {e.unit}</StyledTableCell>
                            </StyledTableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </TableContainer>
                  {/* </Box> */}
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        );
    }

    return (
        <Page title="Event">
            <Container maxWidth="xl">
                <Grid container justifyContent="" padding={2}>
                    {/* <Iconify icon='game-icons:meal' width={30} height={30}/> */}
                    <Typography variant="h4" gutterBottom marginLeft={2}>
                        {/* Meal Calendar */}
                    </Typography>
                </Grid>
                <StackStyled direction="row" spacing={2}>
                        <FullCalendar
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            initialView= {isMobile ? 'timeGridDay' : 'dayGridMonth'}
                            events={data}
                            eventContent={renderEventContent}
                            // eventClick={handleEventClick}
                            dateClick={handleDateClick}
                            selectable
                            headerToolbar = {isMobile ? mobileHeaderToolbar : headerToolbar}
                        />                    
                    <BoxStyled>
                        {
                            partnerDetails && partnerDetails.mobile_no === '7978901253' &&
                           <Button variant='contained' className='event' onClick={() => handleOpen(formatDate(Date()))}>Add Schedule</Button>
                        }

                        {selectedData?.map((meal) => {
                            const mealType = meal?.service_label.replace(' ', '').trim().toLowerCase();
                            const startTime = meal?.service_start_time?.slice(0, 5);
                            const endTime = meal?.service_end_time?.slice(0, 5);
                            return (
                                <Card className={mealType} key={meal?.service_id}>
                                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                                        <Typography variant='h6'>{meal?.service_label}</Typography> 
                                        <Typography variant='h6'>{formatDate(new Date(meal?.date), "dd, MM, yyyy")}</Typography> 
                                        {/* <IconButton onClick={() => handleEdit(meal)}><Iconify icon='basil:edit-solid' /></IconButton> */}
                                        <Typography variant='caption' className='time'> <Iconify icon="ion:time-outline" /> {startTime} - {endTime}</Typography>
                                    </Box>
                                    <Box className='menu'>
                                        <Typography sx={{ pt: 1, pb: 1 }}>Menu Options</Typography>
                                        <Divider />
                                    </Box>

                                    <Table ref={tableRef} aria-label="collapsible table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">#ID</TableCell>
                                                <TableCell align="center">Meal Name</TableCell>
                                                <TableCell align="center">Total Qty</TableCell>
                                                <TableCell align="center">Consumed</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {meal?.menu_options.map((menuOption) => {
                                                    return <Row key={menuOption.meal_id} row={menuOption} />
                                                })
                                            }
                                        </TableBody>

                                    </Table>

                                </Card>

                            )
                        })}
                        <Modal
                            open={openModal}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <ModalBoxStyled>
                                <Typography id="modal-modal-title" sx={{ mb: 3 }} variant="h6" component="h2">
                                    Select Meal to schedule.
                                </Typography>
                                <Box className='container'>
                                    <Box className='container'>
                                        {/* <Typography> Date</Typography> */}
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopDatePicker
                                                inputFormat="YYYY/MM/DD"
                                                value={date}
                                                onChange={handleStartDate}
                                                renderInput={(params) => <TextField {...params} sx={{ width: 300 }} />}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box className='container'>
                                        {/* <Typography> Service</Typography> */}

                                        {/* TODO : Update service list to fetch from api */}
                                        <FormControl fullWidth>
                                            <InputLabel id="service-label">Select Service</InputLabel>
                                            <Select
                                                labelId="service-label"
                                                value={service}
                                                onChange={handleChange}
                                                label="Select Service"
                                                sx={{ width: 300 }}
                                                disabled={!date}
                                            >
                                                <MenuItem value={3}>Breakfast</MenuItem>
                                                <MenuItem value={1}>Lunch</MenuItem>
                                                <MenuItem value={4}>Dinner</MenuItem>
                                                <MenuItem value={5}>Snacks</MenuItem>
                                                <MenuItem value={7}>Tuck Shop</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>

                                <Box className='box'>
                                    {formData.map((ele, index) => {
                                        return (
                                            <Box className='items' key={index}>

                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={optionsList}
                                                    // onInputChange={handleInputChange}
                                                    onChange={(event, data) => handleMealSelectChange(event, data, index)}
                                                    getOptionLabel={(option) => option.label}
                                                    style={{ width: 600 }}
                                                    value={{label: ele?.display_name, id: ele?.meal_id, name: 'meal_id'}}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" {...props} key={option.index}>
                                                            {option.label}
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Select a Meal" variant="outlined" />
                                                    )}
                                                />

                                                {/* <Controller
                                                    render={({ onChange, ...props }) => (
                                                        <Autocomplete
                                                            options={mealList}
                                                            getOptionLabel={(option) => {
                                                                return option?.display_name || option;
                                                            }}
                                                            defaultValue={ele?.display_name }
                                                            renderInput={(params) => (
                                                                <TextField {...params} label="Choose a meal" variant="outlined" onChange={onInputChange} />
                                                            )}
                                                            onChange={(e, data) => onSelectMeal(data, index)}
                                                            {...props}
                                                        />
                                                    )}
                                                    onChange={([, data]) => data}
                                                    // defaultValue={{ code: "AF", label: "Afghanistan", phone: "93" }}
                                                    name="meal_id"
                                                    control={control}
                                                /> */}
                                                {/* <FormControl fullWidth>
                                                    <InputLabel id="item-label">Select Meal</InputLabel>
                                                    <Select
                                                        labelId="item-label"
                                                        value={ele?.meal_id}
                                                        onChange={(event) => handleEventChange(event, index)}
                                                        name="meal_id"
                                                        // onSelect={(event) => handleEventChange(event, index)}
                                                        label="Select Meal"
                                                        sx={{ width: 100 }}
                                                    >
                                                       
                                                        {mealList && mealList.map((list, i) => {
                                                            return <MenuItem key={i} value={list.meal_id}>{list.display_name}</MenuItem>
                                                            // return <MenuItem key={`${list.id}_${index}`} value={list.id} >{list.display_name}</MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl> */}
                                                <FormControl fullWidth>
                                                    {/* <InputLabel id="item-quantity">Quantity</InputLabel> */}
                                                    <TextField 
                                                        id="item-quantity"
                                                        name="quantity"
                                                        sx={{width: 300, }}
                                                        defaultValue={ele?.quantity}
                                                        // onInputChange
                                                        onBlur={(event) => handleQuantityChange(event, index)}
                                                        // onChange={(event) => handleQuantityChange(event, index)}
                                                        label="Select Quantity"
                                                    />

                                                    {/* <Select
                                                        labelId="item-quantity"
                                                        value={ele?.quantity}
                                                        onChange={(event) => handleEventChange(event, index)}
                                                        name="quantity"
                                                        sx={{ width: 100 }}
                                                        label="Select Quantity"
                                                    >
                                                        {menuLists && menuLists[0]?.items?.filter((current) => current.id === ele?.meal_id).map((list, index) => {
                                                            const maxQuantity = [...Array(list?.max_quantity).keys()];
                                                            return maxQuantity.map((qty) => <MenuItem key={`${list.id}_${index}`} value={qty + 1} >{qty + 1}</MenuItem>)
                                                        })}
                                                    </Select> */}
                                                </FormControl>

                                                <IconButton onClick={handleAdd}><Iconify icon='zondicons:add-solid' /></IconButton>
                                                {formData.length > 1 && <IconButton onClick={() => handleDelete(index)} ><Iconify icon='ep:remove-filled' /></IconButton>}
                                            </Box>
                                        )
                                    })}


                                </Box>
                                <Box sx={{ mt: 2 }}>
                                    <Button sx={{ mr: 1 }} variant='contained' onClick={handleSubmit} > Submit</Button>
                                    <Button variant='contained' color='error' onClick={handleClose} >Cancel</Button>
                                </Box>

                            </ModalBoxStyled>
                        </Modal>
                    </BoxStyled>
                </StackStyled>
            </Container>
        </Page>
    )
}