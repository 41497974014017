import { useSelector, useDispatch } from 'react-redux';  
import { Form, FormikProvider, useFormik } from 'formik';
import { useState  } from 'react';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';

// material
import { Grid, Button, Stack, Typography, TextField, Box, InputAdornment, DialogContent, Dialog, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components 
import { getActiveClient } from '../../../../store/coach/reducer'; 
import { addPartnerDish } from '../../../../data/network/dish/api'; 
import { resetAddActivityStatus } from '../../../../store/activity/reducer';
import { getPartnerDish, getUserId } from '../../../../store/user/reducer';
import {dishCategoryList} from '../../../../constants/app_constants';

export default function CreateDishForm( {handleClose} ) {
    const theme = useTheme();
    const clientDetails = useSelector(state => getActiveClient(state));
    const partnerId = useSelector((state) => getUserId(state));

    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const dispatch = useDispatch();
    
    const [startDateValue, setStartDateValue] =  useState(
        new Date()
    );

    const [endDateValue, setEndDateValue] =  useState(
        new Date()
    );
  
    const handleCreateActivity = () => {
        addPartnerDish(dispatch, cookies, {
            // 'display_name': formik.values.name.replace(' ','_').toLowerCase(),
            'display_name': formik.values.name,
            'description': formik.values.description,
            'ingredients': "",
            'sku': formik.values.sku,
            'effective_price': formik.values.effective_price.toString(),
            'mark_price': formik.values.mark_price.toString(),
            'meal_effective_price' : formik.values.meal_effective_price.toString(),
            'meal_mark_price' : formik.values.meal_mark_price.toString(),
            'is_new_arrival' : 1,
            'portion_size': "1",
            'image_url' : "",
            'extra_info': "",
            'categories' : ["veg"],
            'type' : formik.values.type,
            'dish_nutrition_info' : [{
                "name" : "calories",
                "value" : formik.values.calories.toString()
            },{
                "name" : "protein",
                "value" : formik.values.protein.toString()
            },{
                "name" : "fat",
                "value" : formik.values.fat.toString()
            },{
                "name" : "fiber",
                "value" : formik.values.fiber.toString()
            },{
                "name" : "carbohydrates",
                "value" : formik.values.carbohydrates.toString()
            }]
        });
        dispatch(resetAddActivityStatus());
        handleClose();
//        handleClose();
    };

    const dishType = [
        {
            "display_name"  : "Public",
            "value" : "public"
        },
        {
            "display_name"  : "Private",
            "value" : "private"
        }
    ];
    

    const ActivitySchema = Yup.object().shape({
        name: Yup.string().required('name is required')  ,
        description: Yup.string().required('Description is required')  ,
        ingredients:Yup.string(),
        sku: Yup.string(), 
        effective_price: Yup.string().required('Effective Price is required')  ,
        mark_price: Yup.string().required('Mark Price is required'),
        meal_effective_price: Yup.string().required('Meal Effective Price is required')  ,
        meal_mark_price: Yup.string().required('Meal Mark Price is required'),
        // category: Yup.string().required('Category is required'),
        calories: Yup.string().required('Calories is required'),
        fat: Yup.string().required('Fat is required'),
        protein: Yup.string().required('Protein is required'),
        carbohydrates: Yup.string().required('Carbohydrates is required'),
        fiber: Yup.string().required('Fiber is required'),
        type: Yup.string().required('Type is required'),
    });

    const formik = useFormik({
        initialValues: {
          name: '',
          description: '' , 
          ingredients: '' ,
          sku: '', 
          effective_price: '' ,
          mark_price: '',
        //   category: '',
          calories: '',
          fat: '',
          fiber: '',
          protein: '',
          carbohydrates: '',
          type: 'public'
      },
        validationSchema: ActivitySchema,
        onSubmit: () => {
  
        },
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (  
        
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

                        <Grid  sx={{padding:2, borderRadius: 2}}> 
                            <Grid container direction="column"  > 
                                <Grid item container direction="row" justifyContent="flex-start">
                                    <Typography variant="h6" gutterBottom>
                                        Add new dish
                                    </Typography>
                                </Grid>
                                <Grid item container direction="column" justifyContent="center">
                                    <Box margin={2} />
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <TextField
                                            fullWidth 
                                            label="Name"
                                            {...getFieldProps('name')}
                                            error={Boolean(touched.name && errors.name)}
                                            helperText={touched.name && errors.name}
                                        />
                                    </Stack>
                                    <Box margin={2} />
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <TextField
                                            fullWidth
                                            label="Description"
                                            {...getFieldProps('description')}
                                            error={Boolean(touched.description && errors.description)}
                                            helperText={touched.description && errors.description}
                                        />
                                        
                                    </Stack>
                                    <Box margin={2} />
                                    {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        
                                        <TextField
                                            fullWidth
                                            label="Ingredients"
                                            {...getFieldProps('ingredients')}
                                            error={Boolean(touched.ingredients && errors.ingredients)}
                                            helperText={touched.ingredients && errors.ingredients}
                                        />
                                    </Stack>
                                    <Box margin={2} /> */}
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <TextField
                                            fullWidth
                                            label="SKU"
                                            {...getFieldProps('sku')}
                                            error={Boolean(touched.sku && errors.sku)}
                                            helperText={touched.sku && errors.sku}
                                        />
                                    </Stack>
                                    <Box margin={2} />
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <TextField
                                            type="number"
                                            fullWidth
                                            label="Effective Price"
                                            {...getFieldProps('effective_price')}
                                            error={Boolean(touched.effective_price && errors.effective_price)}
                                            helperText={touched.effective_price && errors.effective_price}
                                            startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                        />
                                        <TextField
                                            fullWidth
                                            type="number"
                                            label="Mark Price"
                                            {...getFieldProps('mark_price')}
                                            error={Boolean(touched.mark_price && errors.mark_price)}
                                            helperText={touched.mark_price && errors.mark_price}
                                            startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                        />
                                    </Stack>
                                    <Box margin={2} />
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <TextField
                                            type="number"
                                            fullWidth
                                            label="Meal Effective Price"
                                            {...getFieldProps('meal_effective_price')}
                                            error={Boolean(touched.meal_effective_price && errors.meal_effective_price)}
                                            helperText={touched.meal_effective_price && errors.meal_effective_price}
                                            startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                        />
                                        <TextField
                                            fullWidth
                                            type="number"
                                            label="Meal Mark Price"
                                            {...getFieldProps('meal_mark_price')}
                                            error={Boolean(touched.meal_mark_price && errors.meal_mark_price)}
                                            helperText={touched.meal_mark_price && errors.meal_mark_price}
                                            startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                        />
                                    </Stack>
                                    <Box margin={2} />
                                    {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <TextField
                                            select
                                            fullWidth
                                            label="Category"
                                            {...getFieldProps('category')}
                                            error={Boolean(touched.category && errors.category)}
                                            helperText={touched.category && errors.category}
                                        >
                                            {dishCategoryList.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.display_name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Stack>
                                    <Box margin={2} /> */}
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            label="Protein"
                                            {...getFieldProps('protein')}
                                            error={Boolean(touched.protein && errors.protein)}
                                            helperText={touched.protein && errors.protein}
                                        />
                                        <TextField
                                            type="number"
                                            fullWidth
                                            label="Fat"
                                            {...getFieldProps('fat')}
                                            error={Boolean(touched.fat && errors.fat)}
                                            helperText={touched.fat && errors.fat}
                                        />
                                    </Stack>
                                    <Box margin={2} />
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <TextField
                                            type="number"
                                            fullWidth
                                            label="Carbohydrates"
                                            {...getFieldProps('carbohydrates')}
                                            error={Boolean(touched.carbohydrates && errors.carbohydrates)}
                                            helperText={touched.carbohydrates && errors.carbohydrates}
                                        />
                                        <TextField
                                            fullWidth
                                            type="number"
                                            label="Fiber"
                                            {...getFieldProps('fiber')}
                                            error={Boolean(touched.fiber && errors.fiber)}
                                            helperText={touched.fiber && errors.fiber}
                                        />
                                    </Stack>
                                    <Box margin={2} />
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <TextField
                                            type="number"
                                            fullWidth
                                            label="Calories"
                                            {...getFieldProps('calories')}
                                            error={Boolean(touched.calories && errors.calories)}
                                            helperText={touched.calories && errors.calories}
                                        />
                                    </Stack>
                                    <Box margin={2} />


                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <TextField
                                            select
                                            fullWidth
                                            defaultValue="public"
                                            label="Type"
                                            {...getFieldProps('type')}
                                            error={Boolean(touched.type && errors.type)}
                                            helperText={touched.type && errors.type}
                                        >
                                            {dishType.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.display_name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Stack>
                                    <Box margin={2} />

                                    <Grid item container direction="row" justifyContent="center">
                                        <Button variant='contained' onClick={() => {
                                            handleCreateActivity();
                                        }}>
                                            Create
                                        </Button>                            
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                </FormikProvider>  

    );
}
