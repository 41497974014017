import { useEffect, useState } from 'react';
import { subDays, addDays } from 'date-fns';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Card, Stack, Link, Button, Box } from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { getUserId, getActiveCorporateClient } from '../../store/user/reducer';
import { getCorporateFeedback, getCorporateFeedbackStats } from '../../store/corporate/reducer';
import { formatDate } from '../../utils/date';
import { fetchCorporateFeedback } from '../../data/network/corporate/api';
import CorporateFeedbackList from '../../sections/corporate/feedback/CorporateFeedbackList';
import DishFeedbackWidget from '../../sections/corporate/feedback/DishFeedbackWidget';
 
// sections

// ----------------------------------------------------------------------

export default function CorporateFeedback() {
  const theme = useTheme();

  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const partnerId = useSelector(state => getUserId(state));
  const feedbackStats = useSelector(state => getCorporateFeedbackStats(state));
  const activeClient = useSelector(state => getActiveCorporateClient(state));
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);
	const [open, setOpen] = useState(false);

  const [filterSelection, setFilterSelection] = useState([{
    startDate: subDays(new Date(), 7),
    endDate: addDays(new Date(), 7),
    key: 'selection'
  }]);


  useEffect(() => {
    console.log("New Req");
    if(partnerId != null){
      fetchCorporateFeedback(dispatch, cookies, activeClient, formatDate(filterSelection[0].startDate), formatDate(filterSelection[0].endDate)); 
    }
  },[partnerId, activeClient, filterSelection])

  useEffect(() => { 
    if(partnerId != null){
      const _startDate = formatDate(filterSelection[0].startDate);
      const _endDate = formatDate(filterSelection[0].endDate);
      fetchCorporateFeedback(dispatch, cookies, activeClient, _startDate, _endDate); 
    }
  },[])

  const handleDateFilterSelection = (selectedDateRange) => {
    // console.log(selectedDateRange);
    if(new Date(selectedDateRange.startDate).setHours(0,0,0,0) !== new Date(selectedDateRange.endDate).setHours(0,0,0,0)){
      setOpen(false);
    }
    setFilterSelection([selectedDateRange]); 
  }
  
  return (
    <Page title="Feedback">
      <Container maxWidth="xl"> 

        <Box margin={4} />
        
          <Grid className="corporate-feedback-container"  container direction="row" justifyContent="start" padding={0}  >
            <Grid  item container direction="row" justifyContent={'flex-end'} alignItems="center" >
                <div><b>Showing results for</b></div>
                <Box margin={2} />        
                { 
                  open 
                  ? <DateRangePicker
                    onChange={item => handleDateFilterSelection(item.selection)}
                    showSelectionPreview 
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={filterSelection}
                    direction="vertical"
                  />
                  :<Box>
                    <span>
                    {`  
                      ${formatDate(filterSelection[0].startDate, "DD MMM YYYY")}  
                      to  
                      ${formatDate(filterSelection[0].endDate, "DD MMM YYYY")}
                    `
                    } 
                    </span>
                     
                  </Box>
                } 

                <Box margin={2} />
                { 
                    (!open)
                    ?<Button 
                      variant="outlined"
                      onClick={() =>{
                        setOpen(true);
                      }}>Change
                    </Button>
                      :null
                }
              </Grid>
            
          </Grid>
          {/* { 
              (open)
              ? <Button variant='contained' onClick={() =>{
                  setOpen(false);
                  $('html, body').animate({
                    scrollTop: $("#corporate-feedback-data-container").offset().top
                  }, 500);
                }}>Apply
              </Button>
            : null
            }
          */}

        <Card sx={{padding: 4, marginTop: 4}}  className="glass-box">
            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    All Feedback
                </Typography> 
            </Stack> */}
            <CorporateFeedbackList />
        </Card>

        <Card sx={{padding: 4, marginTop:4}} id="corporate-feedback-data-container"  className="glass-box">
 
          <Grid container padding ={0} spacing={3}>

            {/* <Grid item xs={12} sm={6} md={4}>
              <SummaryWidget title="Total Orders" total={report != null ? report.total_orders : 0} color="info"  icon={'fa:cutlery'}  />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <SummaryWidget title="Total Users" total={report != null ? report.total_users_served: 0} color="info" icon={'ant-design:user-outlined'} />
            </Grid>


            <Grid item xs={12} sm={6} md={4}>
              <SummaryWidget title="Tomorrow Prediction" total={report != null ? report.tomorrow_prediction : 0} color="info" icon={'ant-design:line-chart-outlined'} />
            </Grid> */}

            <Grid item xs={12} sm={6} md={3}>
              <DishFeedbackWidget  title="Most Rated Dish" value={ feedbackStats != null ? feedbackStats.most_rated_dish : " "} color="info" icon={'ant-design:star-outlined'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <DishFeedbackWidget title="Most Liked Dish" value={  feedbackStats != null ? feedbackStats.most_liked_dish : " "} color="info" icon={'ant-design:like-outlined'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <DishFeedbackWidget  title="Least Liked Dish" value={  feedbackStats != null ? feedbackStats.least_liked_dish : " "} color="info" icon={'ant-design:dislike-outlined'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <DishFeedbackWidget  title="Most Active User" value={  feedbackStats != null ? feedbackStats.most_active_user : " "} color="info" icon={'ant-design:user-outlined'} />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              {/* <DishSummaryWidget title="Most Ordered Item" value= {report != null ? report.most_liked_dish.display_name: "Dish name "}  color="info"  img={'https://static.toiimg.com/photo/76942221.cms'}  /> */}
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              {/* <DishSummaryWidget title="Least Ordered Item" value= {report != null ? report.least_liked_dish.display_name: "Dish name "} color="info" img={'https://static.toiimg.com/photo/76942221.cms'}  /> */}
            </Grid>

          </Grid> 

        </Card>
      </Container>

    </Page>
  );
}
