import { useSelector, useDispatch } from 'react-redux';  
import { Form, FormikProvider, useFormik } from 'formik';
import { useState, useEffect  } from 'react';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// material
import { Grid, Button, Stack, Typography, TextField, Box, InputAdornment, DialogContent, Dialog } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components 
import { getActiveClient, getClientActivitySchedule } from '../../../store/coach/reducer'; 
import { addActivity, fetchActivity } from '../../../data/network/activity/api'; 
import { scheduleUserActivity, fetchActivitySchedule, removeScheduledUserActivity } from '../../../data/network/client/api';
import { getActivityList } from '../../../store/activity/reducer';
import { formatDate } from '../../../utils/date';

export default function ScheduleActivityForm( {userId, date, selectedActivityId, handleClose, visibleRange, activityScheduleId} ) {
    const theme = useTheme(); 
 
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const dispatch = useDispatch(); 
    const [activityDate, setActivityDate] = useState(new Date(date)); 
    const activityList = useSelector(state => getActivityList(state));
    const activitySchedule = useSelector(state => getClientActivitySchedule(state));

    const [activityMetaId, setActivityMetaId] = useState(selectedActivityId);

    useEffect(() => {
        // fetchActivity(dispatch, cookies);
        // getScheduledActivityDetail();
    },[])
  
    const handleScheduleActivity = () => {
        scheduleUserActivity(dispatch, cookies, {
            'activity_schedule_id': activityScheduleId,
            'activity_meta_id': activityMetaId,
            'date': formatDate(activityDate, "yyyy-MM-DD"),
            'user_id': userId,
            'standard_duration': formik.values.activity_duration ,
            'standard_burned_calories': formik.values.calories_burnt , 
            'standard_rest_duration': formik.values.rest_duration
        }, false);

        fetchActivitySchedule(dispatch, cookies, {
            user_id: userId,
            // "start_date" : formatDate(visibleRange.start_date, "yyyy-MM-DD"),
            // "end_date" : formatDate(visibleRange.end_date, "yyyy-MM-DD"), 
        });

        handleClose();
    };

    const handleActivityDateChange = (newValue) => {
        setActivityDate(newValue);
    }

    const handleRemoveScheduleActivity = () => {
        removeScheduledUserActivity(dispatch, cookies,  activityScheduleId );

        fetchActivitySchedule(dispatch, cookies, {
            user_id: userId,
            // "start_date" : formatDate(visibleRange.start_date, "yyyy-MM-DD"),
            // "end_date" : formatDate(visibleRange.end_date, "yyyy-MM-DD"), 
        });

        handleClose();
    };

    const ActivitySchema = Yup.object().shape({
        activity_meta_id: Yup.string().required('Activity is required'),
        activity_duration: Yup.string().required('Activity Duration is required')  ,
        calories_burnt: Yup.string().required('Calories Burnt is required')  , 
        rest_duration: Yup.string().required('Rest Duration is required')  , 
    }); 

    
    const getScheduledActivityDetail = () => {
        if(activitySchedule !== null){
            activitySchedule.forEach(element => {
                if(element.schedule_id === parseInt(activityScheduleId,10)){
                    formik.setFieldValue('activity_duration', element.standard_duration);
                    formik.setFieldValue('calories_burnt', element.standard_burned_calories);
                    formik.setFieldValue('rest_duration', element.standard_rest_duration);
                }
            });
        }
    }

    const formik = useFormik({
        initialValues: {
            activity_meta_id: selectedActivityId,
            activity_duration: '',
            calories_burnt:  '',
            rest_duration: '' 
        },
        validationSchema: ActivitySchema,
        onSubmit: () => {
  
        },
    });

    const handleActivityChange = (e) => { 
        setActivityMetaId(e.target.value);
        if(activityList !== null){
            activityList.forEach(element => {
                if(element.activity_id === parseInt(e.target.value,10)){

                    formik.setFieldValue('activity_duration', element.standard_duration);
                    formik.setFieldValue('calories_burnt', element.standard_burned_calories);
                    formik.setFieldValue('rest_duration', element.standard_rest_duration);

                }
            });
        }
    }


    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (  
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid item container direction="column" justifyContent="center">                                    
                    <Box margin={2} />

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth 
                            select
                            label="Select Activity"
                            onChange={handleActivityChange}
                            // {...getFieldProps('activity_meta_id')}
                            error={Boolean(touched.activity_meta_id && errors.activity_meta_id)}
                            helperText={touched.activity_meta_id && errors.activity_meta_id}
                            SelectProps={{
                                native: true,
                            }}
                            variant="filled"
                        >
                            {activityList != null && activityList.map((option) => (
                                <option key={option.activity_id} value={option.activity_id}>
                                    {option.display_name}
                                </option>
                            ))}
                        </TextField>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                label="Activity Date"
                                inputFormat="dd MMM yyyy"
                                value={activityDate}
                                onChange={handleActivityDateChange}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </LocalizationProvider>
                    </Stack>
                    <Box margin={2} />

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            type="number"
                            fullWidth
                            label="Activity Duration"
                            {...getFieldProps('activity_duration')}
                            error={Boolean(touched.activity_duration && errors.activity_duration)}
                            helperText={touched.activity_duration && errors.activity_duration}
                            endAdornment={<InputAdornment position="end">mins</InputAdornment>}
                        />
                        <TextField
                            fullWidth
                            type="number"
                            label="Rest Duration"
                            {...getFieldProps('rest_duration')}
                            error={Boolean(touched.rest_duration && errors.rest_duration)}
                            helperText={touched.rest_duration && errors.rest_duration}
                            endAdornment={<InputAdornment position="end">mins</InputAdornment>}
                        />
                        <TextField
                            type="number"
                            fullWidth
                            label="Calorie Burnt"
                            {...getFieldProps('calories_burnt')}
                            error={Boolean(touched.calories_burnt && errors.calories_burnt)}
                            helperText={touched.calories_burnt && errors.calories_burnt}
                            endAdornment={<InputAdornment position="end">Kcal</InputAdornment>}
                        />
                    </Stack>
                    <Box margin={2} />
                </Grid>

                <Grid item container direction="row" justifyContent="center">
                    <Button variant='outlined' onClick={() => {
                        handleRemoveScheduleActivity();
                    }}>
                        Remove
                    </Button>
                    <Box margin={2} />
                    <Button variant='contained' onClick={() => {
                        handleScheduleActivity();
                    }}>
                        Save
                    </Button>                            
                </Grid>
            </Form>
        </FormikProvider>
    );
}
