 // @mui
import { Card, CardHeader, Table, TableBody, Paper, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getActiveClientReport } from '../../../store/coach/reducer';

// ----------------------------------------------------------------------

export default function DailyActivityReport() {

    const clientReport = useSelector(state => getActiveClientReport(state));

    const [data, setData] = useState([]);
 

    useEffect(() => {

        const chartData = [];

        if(clientReport && clientReport !== null && clientReport.daily_logs !== null){
            console.log(clientReport.daily_logs.water);
            if(clientReport.daily_logs.water !== null){
                clientReport.daily_logs.water.forEach((element, i) => {
                    if(!chartData[i]){
                        chartData[i] = {}
                    }
                    chartData[i].label = element.label;
                    chartData[i].water = element.value;
                });
            }

            if(clientReport.daily_logs.sleep !== null){
                clientReport.daily_logs.sleep.forEach((element, i) => {
                    if(!chartData[i]){
                        chartData[i] = {}
                    }
                    chartData[i].sleep = element.value;                
                });
            }
        }
        
        setData(chartData);

    },[clientReport]);

    return (
        <Card className="glass-box w-100">
            <CardHeader title="Daily Logs" />

            <TableContainer >
                <Table aria-label="daily-logs">
                    <TableHead>
                        <TableRow>
                            <TableCell   sx={{width:'15%'}}> </TableCell>
                            <TableCell align="center"   sx={{width:'15%'}}>Water (glass)</TableCell>
                            <TableCell align="center"   sx={{width:'15%'}}>Sleep (hours)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow
                            key={row.label}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row"  sx={{width:'15%'}}>
                                {row.label}
                            </TableCell>
                            <TableCell align="center"  sx={{width:'15%'}}>{row.water}</TableCell>
                            <TableCell align="center"  sx={{width:'15%'}}>{row.sleep}</TableCell> 
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
}
