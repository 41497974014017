import { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { getSession } from '../../data/network/session/api';
import { getLoginStatus, getUser, setActiveCorporateClient } from '../../store/user/reducer';
import { fetchUserDetails } from '../../data/network/user/api';
import DashboardFooter from './DashboardFooter';
import { APP_BAR_DESKTOP, APP_BAR_MOBILE } from '../../constants/layout_constants';

// ----------------------------------------------------------------------


const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  height: '100vh',
  // backgroundImage: `url('../../static/bg1.jpg')`,
  // backgroundAttachment: 'fixed' 
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  // minHeight: '100vh',
  // flexGrow: 1,
  // overflow: 'auto',
  // minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  // [theme.breakpoints.up('lg')]: {
  //   paddingTop: APP_BAR_DESKTOP + 24,
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2)
  // }
}));

const MainContent = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  // minHeight: '100vh',
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
  const navigate = useNavigate();
  const isLoggedIn = useSelector(state => getLoginStatus(state));
  const userDetails = useSelector(state => getUser(state));

  useEffect(() =>{ 
    getSession(dispatch, cookies);
  },[])

  useEffect(() =>{ 
    if(!isLoggedIn){
      navigate('/');
    }else{
      fetchUserDetails(dispatch, cookies);
    }
  },[isLoggedIn])

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <MainContent>
          <Outlet /> 
        </MainContent>
        {/* <DashboardFooter /> */}
      </MainStyle>
    </RootStyle>
  );
}
