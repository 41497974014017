
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Stack, Typography, Box, TextField, Select, MenuItem, Button, styled, DialogTitle, Dialog } from '@mui/material';
import { useCookies } from 'react-cookie';
import { formatDate } from '../../../utils/date';
import { getActiveStoreId } from '../../../store/partner_store/reducer';
import { STOCK_UNITS } from '../../../constants/app_constants';
import { updateStoreStock } from '../../../data/network/store/stock';

const TextFieldStyle = styled(TextField)(() => ({
    '& .MuiInputBase-input': {
        padding: '8px 14px',
    }
}));

const SelectStyle = styled(Select)(() => ({
    '& .MuiSelect-select': {
        padding: '8px 14px',
    }
}));


export const StoreStockDetail = ({ data, visible, setOpen, editable=true, create=true }) => {
    const [editEnable, setEditEnable] = useState(false);
    const [stockData, setStockData] = useState({});
    const [date, setDate] = useState(null);

    const dispatch = useDispatch();
    const [cookies] = useCookies(['mobile', 'session', 'access_token']);

    const storeId = useSelector((state) => getActiveStoreId(state));
    
    useEffect(() => {
        setStockData(data);
        setEditEnable(!editable);

        console.log(data);
    }, [data])

    const handleChange = (event) => {
        if (event.target.name === 'name') {
            setStockData({ ...stockData, 'display_name': event.target.value, [event.target.name]: event.target.value })
        }else{
            setStockData({ ...stockData, [event.target.name]: event.target.value })
        }
    }

    const handleDate = (newValue) => {
        setDate(formatDate(newValue));
        setStockData({ ...stockData, dob: formatDate(newValue) })
    }

    const handleUpdate = () => {
        console.log(stockData);
        updateStoreStock(dispatch, cookies, storeId, stockData);
        setOpen(false);
    }
    return (
        <Dialog  
            // anchor='right'
            open={visible}
            onClose={() => setOpen(false)}
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle>
                <Box sx={{ pb: 2 }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h6'>Detail View</Typography>
                    <Button size='small' color='info' onClick={() => setEditEnable(false)}>Edit</Button>
                </Box>
            </DialogTitle>
            <Stack sx={{ width: 'md' }} style={{ padding: '20px', gap: '10px' }}>
                {/* <Box sx={{ pb: 2 }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h6'>Detail View</Typography>
                    <Button size='small' color='info' onClick={() => setEditEnable(false)}>Edit</Button>
                </Box> */}
                
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Name</Typography>
                    <TextFieldStyle disabled={editEnable} name='name' value={stockData?.display_name} onChange={handleChange} />
                </Box>
                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Display Name</Typography>
                    <TextFieldStyle disabled={editEnable} name='display_name' value={stockData?.display_name} onChange={handleChange} />
                </Box> */}
                {!create && <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>SKU</Typography>
                    <TextFieldStyle disabled name='sku' value={stockData?.sku} onChange={handleChange} />
                </Box>}
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Primary Unit</Typography>
                    <SelectStyle
                        value={stockData?.primary_unit ?? ""}
                        onChange={handleChange}
                        displayEmpty
                        disabled={editEnable}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ width: 220 }}
                        name="primary_unit"
                    >
                        {
                            Object.keys(STOCK_UNITS).map((e) => {
                                return <MenuItem value={e}>{e}</MenuItem>
                            })
                        }
                    </SelectStyle>
                    {/* <TextFieldStyle disabled={editEnable} name='primary_unit' value={stockData?.primary_unit} onChange={handleChange} /> */}
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Secondary Unit</Typography>
                    <SelectStyle
                        value={stockData?.secondary_unit ?? ""}
                        onChange={handleChange}
                        displayEmpty
                        disabled={editEnable}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ width: 220 }}
                        name="secondary_unit"
                    >
                        {
                            Object.keys(STOCK_UNITS).map((e) => {
                                return <MenuItem value={e}>{e}</MenuItem>
                            })
                        }
                    </SelectStyle>
                    {/* <TextFieldStyle disabled={editEnable} name='primary_unit' value={stockData?.primary_unit} onChange={handleChange} /> */}
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>In Stock</Typography>
                    <TextFieldStyle disabled={editEnable} name='in_stock' value={stockData?.in_stock} onChange={handleChange} />
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Min threshold stock</Typography>
                    <TextFieldStyle disabled={editEnable} name='minimum_threshold_stock' value={stockData?.minimum_threshold_stock} onChange={handleChange} />
                </Box>

                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Scheduled For</Typography>
                    <Typography variant="body1">{orderData?.scheduled_for}</Typography>

                    <SelectStyle
                        value={orderData?.scheduled_for}
                        onChange={handleChange}
                        displayEmpty
                        disabled={editEnable}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ width: 220 }}
                        name="scheduled_for"
                    >
                        <MenuItem value="Breakfast" >Breakfast</MenuItem>
                        <MenuItem value="Lunch" >Lunch</MenuItem>
                        <MenuItem value="Snacks" >Snacks</MenuItem>
                        <MenuItem value="Dinner" >Dinner</MenuItem>
                    </SelectStyle>
                </Box> */}
                {/*                 
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Status</Typography>
                    <TextFieldStyle disabled={editEnable} name='status' type='text' value={stockData?.status} onChange={handleChange} />
                </Box> */}
                
                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Delivered at</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            inputFormat="YYYY/MM/DD"
                            value={date}
                            onChange={handleDate}
                            disabled={editEnable}
                            renderInput={(params) => <TextFieldStyle {...params} sx={{ width: 220 }} />}
                        />
                    </LocalizationProvider>
                </Box>
                 */}

                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Assign POC</Typography>
                    <SelectStyle
                        value={orderData?.poc}
                        onChange={handleChange}
                        displayEmpty
                        // disabled={editEnable}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ width: 220 }}
                        name="poc"
                    >
                        <MenuItem value="Breakfast" >Breakfast</MenuItem>
                    </SelectStyle>
                </Box> */}

                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Assign Chef</Typography>
                    <SelectStyle
                        value={orderData?.chef}
                        onChange={handleChange}
                        displayEmpty
                        // disabled={editEnable}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ width: 220 }}
                        name="chef"
                    >
                        <MenuItem value="Breakfast" >Breakfast</MenuItem>
                    </SelectStyle>
                </Box> */}


                {/* {<Button sx={{ mt: 2, pt: 1, pb: 1 }} size='small' variant='contained' onClick={handleUpdate}>Update</Button>} */}
           
                {/* <Button sx={{ mt: 2, pt: 1, pb: 1 }} size='small' variant='contained'>Print Label</Button> */}

                {!editEnable && <Button sx={{ mt: 2, pt: 1, pb: 1 }} size='small' variant='contained' onClick={handleUpdate}>
                    {create ? <Typography>Create</Typography>
                     : <Typography>Update</Typography>
                    }
                </Button>}
            </Stack>
        </Dialog>
    )

}