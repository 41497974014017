import { createSlice } from '@reduxjs/toolkit'

const otpSlice = createSlice({
  name: 'otp',
  initialState: {
    sendPartnerOTPResponse: null,
    verifyPartnerOTPResponse: null,
    sendOTPResponse: null,
    verifyOTPResponse: null,
  },
  reducers: {
    setSendPartnerOTPResponse(state, action) {
      state.sendPartnerOTPResponse = action.payload.data;
    },
    setVerifyPartnerOTPResponse(state, action) {
      state.verifyPartnerOTPResponse = action.payload.data;
    },
    setSendOTPResponse(state, action) {
      state.sendOTPResponse = action.payload.data;
    },
    setVerifyOTPResponse(state, action) {
      state.verifyOTPResponse = action.payload.data;
    },
    clearPartnerOTPState(state,action){
      state.sendPartnerOTPResponse = null;
      state.verifyPartnerOTPResponse = null;
    },
    clearOTPState(state,action){
      state.sendOTPResponse = null;
      state.verifyOTPResponse = null;
    }
  }
})

export const getSendPartnerOTPResponse = (state) => {
  if(state.otp != null && state.otp !== undefined){
    return state.otp.sendPartnerOTPResponse;
  }
  return null;
}

export const getVerifyPartnerOTPResponse = (state) => {

  if(state.otp != null && state.otp !== undefined){
    return state.otp.verifyPartnerOTPResponse;
  }
  return null;
} 


export const getPartnerOTPSentStatus = (state) => {

  try {
      return state.otp.sendPartnerOTPResponse.success;        
  } catch (error) {
      return false;        
  }
}

export const getPartnerOTPVerificationStatus = (state) => {
  if(state.otp != null && state.otp !== undefined && state.otp.verifyPartnerOTPResponse !== null){
    return state.otp.verifyPartnerOTPResponse.success;
  }
  return false;        
}

export const getClientId = (state) => {
  try {
    return state.otp.verifyPartnerOTPResponse.client_id;
  } catch (error) {
    return null;
  }
}

export const getClientSecret = (state) => {
  try {
    return state.otp.verifyPartnerOTPResponse.client_secret;    
  } catch (error) {
    return null;
  }
}

export const getPartnerId = (state) => {
  try {
    return state.otp.verifyPartnerOTPResponse.partner.id;
  } catch (error) {
    return null;
  }
}


export const getSendOTPResponse = (state) => {
  if(state.otp != null && state.otp !== undefined){
    return state.otp.sendOTPResponse;
  }
  return null;
}

export const getVerifyOTPResponse = (state) => {

  if(state.otp != null && state.otp !== undefined){
    return state.otp.verifyOTPResponse;
  }
  return null;
} 

export const getOTPSentStatus = (state) => {

    try {
        return state.otp.sendOTPResponse.success;        
    } catch (error) {
        return false;        
    }
}

export const getOTPVerificationStatus = (state) => {
    if(state.otp != null && state.otp !== undefined && state.otp.verifyOTPResponse !== null){
      return state.otp.verifyOTPResponse.success;
    }
    return false;        
}



export const { setSendPartnerOTPResponse, setVerifyPartnerOTPResponse, setSendOTPResponse,
   setVerifyOTPResponse, clearOTPState,  clearPartnerOTPState, mockOTPVerified } = otpSlice.actions
export default otpSlice.reducer
