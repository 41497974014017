import { ENV } from "../../constants/app_constants";

export const API_URL_PRODUCTION="https://api.the-bytes.com/";
export const API_URL_STAGING="https://inventory.the-bytes.com/";
// export const API_BASE_URL="http://35.154.122.166/api/";
// export const API_BASE_URL="https://kitchen.greckle.io/";
// export const API_BASE_URL="https://staging.greckle.io/";
// export const API_BASE_URL="http://127.0.0.1:5000/";

export const API_BASE_URL = (ENV === 'production') ? API_URL_PRODUCTION : API_URL_STAGING

// authentication
export const API_SEND_PARTNER_OTP="v1/partner/otp";
export const API_VERIFY_PARTNER_OTP="v1/partner/otp";
export const API_GENERATE_ACCESS_TOKEN="v1/partner/oauth/token";
export const API_CREATE_SESSION = "v1/partner/sessions/add";
export const API_GET_SESSION="v1/partner/sessions";

// Profile
export const API_GET_USER_DETAILS="v1/partner/";
export const API_UPDATE_USER_DETAILS="v1/partner/";

// Dish
export const API_GET_PARTNER_DISH = "v1/partner_dish";
export const API_UPDATE_PARTNER_DISH = "v1/partner_dish";
export const API_ADD_PARTNER_DISH = "v1/partner_dish";
export const API_UPDATE_PARTNER_DISH_RECIPE = "v1/partner_dish/recipe";

// Meal
export const API_GET_PARTNER_MEAL = "v1/partner/meals";
export const API_UPDATE_PARTNER_MEAL = "v1/partner/meals";

// Client
export const API_SEND_OTP="v1/otp";
export const API_VERIFY_OTP="v1/otp";
export const API_GET_CLIENT_LIST = "v1/partner/client/list";
export const API_FETCH_CLIENT_DETAILS = "v1/partner/client/get_details";
export const API_UPDATE_CLIENT_DETAILS = "v1/partner/client/update_details";
export const API_GET_CLIENT_SUBSCRIPTION = "v1/partner/client/get_subscription";
export const API_POST_CHECK_CLIENT_SUBSCRIPTION = "v1/partner/client/check_subscription";
export const API_GET_CLIENT_SUBSCRIPTION_CART = "v1/partner/client/get_subscription_cart";
export const API_FETCH_CLIENT_ADDRESS = "v1/partner/client/address";
export const API_ADD_CLIENT_ADDRESS = "v1/partner/client/address";

// Client Subscription
export const API_CLIENT_SUBSCRIBE_PLAN = "v1/partner/client/subscribe";
export const API_SUBSCRIBE_CLIENT = "v1/partner/client/subscribe";
export const API_CONFIRM_CLIENT_SUBSCRIPTION = "v1/partner/client/confirm_subscription";
export const API_PAUSE_CLIENT_SUBSCRIPTION = "v1/partner/client/subscription/pause";
export const API_RESUME_CLIENT_SUBSCRIPTION = "v1/partner/client/subscription/resume";

// Kitchen
export const API_GET_KITCHEN_STATS = "v1/partner/kitchen/stats";


// Order
export const API_GENERATE_ORDER_ID = "v1/partner/client/generate_order_id";
export const API_ADD_TRANSACTION = "v1/partner/client/add_transaction";

// export const API_GET_SUBSCRIPTION_PLAN_LIST = "v1/partner/subscription_plan/get_list";
export const API_GET_SUBSCRIPTION_PLAN_LIST = "v1/partner/client/get_subscription_plans";

// Meal Schedule
export const API_GET_CLIENT_MEAL_OPTIONS="v1/partner/client/meal_plan_dish_options";
export const API_GET_CLIENT_MEAL_SCHEDULE="v1/partner/client/meal_plan_schedule";
export const API_POST_CLIENT_MEAL_SCHEDULE="v1/partner/client/meal_plan_schedule";
export const API_DELETE_CLIENT_MEAL_SCHEDULE="v1/partner/client/meal_plan_schedule";
// export const API_UPDATE_CLIENT_MEAL_SCHEDULE="v1/partner/client/meal_plan_schedule/update";
export const API_UPDATE_CLIENT_MEAL_SCHEDULE="v1/partner/client/meal_plan_schedule";

// Activity
export const API_GET_ACTIVITY_LIST = "v1/partner/activity/meta";
export const API_ADD_ACTIVITY = "v1/partner/activity/meta";
export const API_GET_ACTIVITY_CATEGORY_LIST = "v1/partner/activity/category";
export const API_GET_CLIENT_ACTIVITY_SCHEDULE="v1/partner/client/activity/schedule";
export const API_POST_CLIENT_ACTIVITY_SCHEDULE="v1/partner/client/activity/schedule";
export const API_DELETE_CLIENT_ACTIVITY_SCHEDULE = "v1/partner/client/activity/schedule";
export const API_UPDATE_USER_ACTIVITY_STATUS = "v1/partner/client/activity/status";

// User Journal
export const API_GET_USER_JOURNAL="v1/follow_ups";
export const API_CREATE_USER_JOURNAL="v1/follow_ups";
export const API_GET_USER_DAILY_LOG="v1/partner/client/daily_log";
export const API_UPDATE_USER_DAILY_LOG="v1/partner/client/daily_log";

// Client Report
export const API_GET_CLIENT_REPORT = "v1/partner/client/report";

// Corporate Users
export const API_GET_CORPORATE_USERS ="v1/partner/corporate/users";
export const API_GET_CORPORATE_INVOICE ="v1/partner/corporate/invoice";
export const API_GET_CORPORATE_TRANSACTION ="v1/partner/corporate/transaction";
export const API_GET_CORPORATE_REPORT ="v1/partner/corporate/report";
export const API_GET_CORPORATE_FEEDBACK ="v1/partner/corporate/feedback";
export const API_GET_CORPORATE_ORDER="v1/partner/corporate/orders";
export const API_GET_CORPORATE_PARTNER="v1/partner";
export const API_GET_CORPORATE_MEAL_SCHEDULE="v1/partner/corporate/meal/schedule";
export const API_GET_CORPORATE_MEAL_MENU="v1/menu";
export const API_GET_CORPORATE_MEAL_LIST="v1/partner/meals";

// Store
export const API_GET_STORE_ORDER = "v1/partner/store/orders";
export const API_UPDATE_STORE_ORDER = "v1/partner/store/order";
export const API_GET_STORE_ORDER_STATS = "v1/partner/store/orders/stat";

export const API_GET_STORE_STAFF = "v1/partner/store/staff";
export const API_UPDATE_STORE_STAFF = "v1/partner/store/staff";
export const API_DELETE_STORE_STAFF = "v1/partner/store/staff";

export const API_GET_STORE_STOCK = "v1/partner/store/items";
export const API_UPDATE_STORE_STOCK = "v1/partner/store/items";
export const API_DELETE_STORE_STOCK = "v1/partner/store/items";
export const API_GET_STORE_STOCK_STATS = "v1/partner/store/items/stat";

export const API_GET_STORE_VENDOR = "v1/partner/store/vendors";
export const API_UPDATE_STORE_VENDOR = "v1/partner/store/vendors";
export const API_DELETE_STORE_VENDOR = "v1/partner/store/vendors";
export const API_GET_STORE_VENDOR_STATS = "v1/partner/store/vendor/stat";

export const API_GET_STORE_EXPENSE = "v1/partner/store/purchase_orders";
export const API_UPDATE_STORE_EXPENSE = "v1/partner/store/purchase_orders";
export const API_DELETE_STORE_EXPENSE = "v1/partner/store/purchase_orders";
export const API_GET_STORE_EXPENSE_STATS = "v1/partner/store/purchase_orders/stat";

export const API_GET_STORE_ROLES = "v1/partner/store/roles";
export const API_UPDATE_STORE_ROLES = "v1/partner/store/roles";

export const API_GET_STORE_MENU = "v1/partner/store/menu";
export const API_GET_STORE_DISH = "v1/partner/store/dish";
export const API_UPDATE_STORE_MENU = "v1/partner/store/menu";
export const API_DELETE_STORE_MENU = "v1/partner/store/menu";
export const API_GET_STORE_MENU_STATS = "v1/partner/store/menu/stat";

export const API_GET_STORE_SALES = "v1/partner/store/report";
export const API_UPDATE_STORE_SALES = "v1/partner/store/sales";
export const API_GET_STORE_SALES_STATS = "v1/partner/store/sales/stat";

export const API_GET_STORE_INVOICE = "v1/partner/invoice/sales";
export const API_UPDATE_STORE_INVOICE = "v1/partner/invoice/sales";
export const API_GET_STORE_INVOICE_STATS = "v1/partner/store/invoice/stat";

