import { Container } from "@mui/material";

import Page from "../../../components/Page";
import { StoreRoleList } from "./StoreRoleList";

export const StoreRoles = () => {
    return (
        <Page title="Store Roles">
            <Container size="xl">
                <StoreRoleList />
            </Container>
        </Page>
    );
}