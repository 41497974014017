export const mealList = [
    {
        "meal_id": 1,
        "display_name": "Rice Paneer Meal",
        "description": "Test",
        "name": "",
        "is_private": false,
        "dishes": [
            {
                "dish_id": 5,
                "portion_count": 3,
                "portion_weight": 250,
                "weight_unit": "GM"
            },
            {
                "dish_id": 10,
                "portion_count": 1,
                "portion_weight": 250,
                "weight_unit": "GM"
            }
        ]
    },
    {
        "meal_id": 3,
        "display_name": "Rice Paneer Meal 2",
        "description": "Test",
        "name": "",
        "is_private": false,
        "dishes": [
            {
                "dish_id": 5,
                "portion_count": 3,
                "portion_weight": 250,
                "weight_unit": "GM"
            },
            {
                "dish_id": 10,
                "portion_count": 1,
                "portion_weight": 250,
                "weight_unit": "GM"
            }
        ]
    },
    {
        "meal_id": 4,
        "display_name": "Rice Paneer Meal 3",
        "description": "Test",
        "name": "",
        "is_private": false,
        "dishes": [
            {
                "dish_id": 5,
                "portion_count": 3,
                "portion_weight": 250,
                "weight_unit": "GM"
            },
            {
                "dish_id": 10,
                "portion_count": 1,
                "portion_weight": 250,
                "weight_unit": "GM"
            }
        ]
    },
    {
        "meal_id": 5,
        "display_name": "Rice Paneer Meal 4",
        "description": "Test",
        "name": "",
        "is_private": true,
        "dishes": [
            {
                "dish_id": 5,
                "portion_count": 3,
                "portion_weight": 250,
                "weight_unit": "GM"
            },
            {
                "dish_id": 10,
                "portion_count": 1,
                "portion_weight": 250,
                "weight_unit": "GM"
            }
        ]
    },
    {
        "meal_id": 6,
        "display_name": "Rice Paneer Meal 5",
        "description": "Test",
        "name": "",
        "is_private": false,
        "dishes": [
            {
                "dish_id": 5,
                "portion_count": 3,
                "portion_weight": 250,
                "weight_unit": "GM"
            },
            {
                "dish_id": 10,
                "portion_count": 1,
                "portion_weight": 250,
                "weight_unit": "GM"
            }
        ]
    },
    {
        "meal_id": 7,
        "display_name": "Rice Paneer Meal 6",
        "description": "Test",
        "name": "",
        "is_private": false,
        "dishes": [
            {
                "dish_id": 5,
                "portion_count": 3,
                "portion_weight": 250,
                "weight_unit": "GM"
            },
            {
                "dish_id": 10,
                "portion_count": 1,
                "portion_weight": 250,
                "weight_unit": "GM"
            }
        ]
    },
    {
        "meal_id": 8,
        "display_name": "Rice Paneer Meal 7",
        "description": "Test",
        "name": "",
        "is_private": true,
        "dishes": [
            {
                "dish_id": 5,
                "portion_count": 3,
                "portion_weight": 250,
                "weight_unit": "GM"
            },
            {
                "dish_id": 10,
                "portion_count": 1,
                "portion_weight": 250,
                "weight_unit": "GM"
            }
        ]
    },
];

export const MEAL_CREATION_FORM_STEPS = {
    BASIC_DETAILS : {
        'step' : 'basic_details',
    },
    DISH_INFO : {
        'step' : 'dish_info',
    },
    TAGS: {
        'step' : 'tags',
    },
    ACK: {
        'step' : 'ack',
    }
}
