import React, {useState, useEffect} from 'react';
import { useTheme,Grid, Container, Typography,Box, TableCell, FormControl, InputLabel, Select, OutlinedInput, Chip, MenuItem, Card, SwipeableDrawer, Stack, styled, Dialog, AppBar, Toolbar, IconButton, DialogContent } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

// import {
//     Scheduler,
//     WeekView,
//     Appointments,
//     Toolbar,
//     DateNavigator,
//     AppointmentTooltip,
//     AppointmentForm,
//     TodayButton,
//     Resources,
//     DragDropProvider,
// } from '@devexpress/dx-react-scheduler-material-ui'; 

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
// import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { isMobile } from 'react-device-detect';
  
import Page from "../../components/Page";

import  '../../assets/css/ScheduleActivity.css';
import { fetchClientSubscription, fetchClientDetails, scheduleUserActivity, fetchActivitySchedule } from '../../data/network/client/api';
import { getActiveClient, getClientActivitySchedule, getClientList, getClientSubscriptionPlanDetails } from '../../store/coach/reducer';
import {ReactComponent as Loader} from '../../assets/img/loader_3.svg';
import { formatDate } from '../../utils/date';
import { getActivityList } from '../../store/activity/reducer';
import { fetchActivity, fetchActivityCategories } from '../../data/network/activity/api';
import ScheduleActivityForm from '../../sections/coach/activity_calendar/ScheduleActivityForm';
import EditActivityForm from '../../sections/coach/activity_calendar/EditActivityForm';
import UserListCard from '../../sections/coach/journal/UserListCard';
import Iconify from '../../components/Iconify';

export default function ScheduleActivity() {

    const StackStyled = styled(Stack)(({theme}) => ({
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          '& .fc': {
              width: '100%',
          },
          '& .fc .fc-toolbar-title': {
              fontSize: '16px',
          }
      },
    }))

    const BoxStyled = styled('div')(({theme}) => ({
      display: 'flex',
      flexDirection: 'column',
      width: '25%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: '0px !important',
      },
      '& .event': {
          alignSelf: 'end',
          background: '#1a252f',
          [theme.breakpoints.down('md')]: {
              marginTop: '20px',
          },
      },
      '& .MuiCard-root': {
          padding: '15px',
          borderLeft: '6px solid #FDAA2E',
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
          marginTop: '30px',
          '&.breakfast': {
              borderLeft: '6px solid #FDAA2E',
              '& .MuiDivider-root': {
                  borderColor: '#FDAA2E',
              }
          },
          '&.lunch': {
              borderLeft: '6px solid #2AC3A7',
              '& .MuiDivider-root': {
                  borderColor: '#2AC3A7',
              }
          },
          '&.dinner': {
              borderLeft: '6px solid #447CFF',
              '& .MuiDivider-root': {
                  borderColor: '#447CFF',
              }
          },
          '&.allday, &.snacks': {
              borderLeft: '6px solid #5C5C5C',
              '& .MuiDivider-root': {
                  borderColor: '#5C5C5C',
              }
          },
          '& .time': {
              display: 'flex',
              alignItems: 'center',
              gap: '1px',
              background: '#F5F6F8',
              padding: '5px',
              borderRadius: '10px',
          },
          '& .MuiTable-root th, .MuiTable-root td': {
              padding: '5px',
              fontSize: '12px',
              textAlign: 'left',
          },
          '& .menu': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              '& .MuiTypography-root': {
                  fontSize: '12px',
                  fontWeight: 'bold',
              },
              '& .MuiDivider-root': {
                  flex: 1,
              }

          }
      }
    }));

    const theme = useTheme();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

    const [showOverviewDashboard, setShowOverviewDashboard] = useState(true);
    const [loading, setLoading] = useState(true);
    const [isScheduleDialogOpen, setIsScheduleDialogOpen] = useState(false);
    const [selectedActivityDate, setSelectedActivityDate] = useState(new Date());
    const [selectedActivityId, setSelectedActivityId] = useState(null);
    const [drawerType, setDrawerType] = useState("CREATE");

    const [range, setRange] = useState({
      'start_date' : null,
      'end_date' : null,
    });
  
    const activeClient = useSelector(state => getActiveClient(state));
    const activitySchedule = useSelector(state => getClientActivitySchedule(state));
    const activityList = useSelector(state => getActivityList(state));
    const clientList = useSelector(state => getClientList(state));

    const appointments = []; 
    const [data, setData] = useState(appointments);
    const [selectedEvent, setSelectedEvent] = useState(null); 

    const [searchParams, setSearchParams] = useSearchParams();
    const startDate = searchParams.get("start_date");
    const endDate = searchParams.get("end_date");
    const userId = searchParams.get("client_id");

    const headerToolbar = {
      left:'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    }

    const mobileHeaderToolbar = {
      left: 'prev,next today',
      right: 'title'
    }

    useEffect(() => { 
      if(userId !== null && userId !== ''){ 
        setShowOverviewDashboard(false);
        setLoading(true);
        fetchClientDetails(dispatch, cookies, userId);
        fetchClientSubscription(dispatch, cookies, userId);
        fetchActivity(dispatch, cookies);
        fetchActivityCategories(dispatch, cookies); 
      }else{
        setShowOverviewDashboard(true);
      }
    },[userId]);

    useEffect(() => { 
      if(activeClient != null && activityList != null ){
        setLoading(false);
      }
    },[activeClient, activityList]); 

    useEffect(() => { 
      
      if(activitySchedule != null){ 
        activitySchedule.forEach(element => {
          appointments.push({
            id: element.schedule_id,
            extendedProps: {
              activity_meta_id: element.activity_meta_id,
            },
            title: element.title,
            date: new Date(element.start),
          })
        });

        setData(appointments);

      } 
    },[activitySchedule]);  
 
    // useEffect(() =>{ 
    //     if(range.start_date != null && range.end_date != null){ 
    //         fetchActivitySchedule(dispatch, cookies, {
    //             user_id: userId,
    //             "start_date" : formatDate(range.start_date, "yyyy-MM-DD"),
    //             "end_date" : formatDate(range.end_date, "yyyy-MM-DD"), 
    //         });
    //     }
    // },[range]) 

    useEffect(() => { 
      if(userId !== null && userId !== ''){ 
        setShowOverviewDashboard(false);
        setLoading(true);
        fetchClientDetails(dispatch, cookies, userId);
        fetchClientSubscription(dispatch, cookies, userId);
        fetchActivity(dispatch, cookies);
        fetchActivityCategories(dispatch, cookies); 
        fetchActivitySchedule(dispatch, cookies, {user_id: userId});
      }else{
        setShowOverviewDashboard(true);
      }
    },[]);


    const handleClose = () => {
      setIsScheduleDialogOpen(false); 
    }

 
    const handleDateClick = (arg) => { // bind with an arrow function
      setSelectedActivityDate(arg.date);
      setDrawerType("CREATE");
      setIsScheduleDialogOpen(true); 
    }
 
    const handleEventClick = (arg) => {  
      setDrawerType("EDIT");
      setSelectedActivityId(arg.event._def.extendedProps.activity_meta_id);
      setSelectedActivityDate(arg.event._instance.range.start);
      setSelectedEvent(arg.event._def.publicId);
      setIsScheduleDialogOpen(true);
    }

    const handleDrop = (arg) => { // bind with an arrow function

      scheduleUserActivity(dispatch, cookies, {
        'activity_schedule_id': arg.event._def.publicId,
        'activity_meta_id': arg.event._def.extendedProps.activity_meta_id,
        'date': formatDate(arg.event._instance.range.start, "yyyy-MM-DD"), 
        'user_id': userId,
      }, false);

      fetchActivitySchedule(dispatch, cookies, {
        user_id: userId,
        // "start_date" : formatDate(range.start_date, "yyyy-MM-DD"),
        // "end_date" : formatDate(range.end_date, "yyyy-MM-DD"), 
      });
    }

    const handleEventReceive = (arg) => { // bind with an arrow function
      console.log(arg)
    }

    const handleDateRangeChange  = (arg) => { // bind with an arrow function
      setRange({
        'start_date' : arg.start,
        'end_date' :  arg.end
      });  
    }

    const renderEventContent = (eventInfo) => (
        <Card className="glass-box w-100 event-card">
          <i>{eventInfo.event.title}</i>
        </Card>
    )


    const toggleScheduleModal = (anchor , open ) => (event ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event ).key === 'Tab' ||
            (event ).key === 'Shift')
        ) {
            return;
        }
    
        setIsScheduleDialogOpen(open); 
    };

 
    return (
      <Page title="Schedule Activity">

        {
          showOverviewDashboard && 
          <Container  maxWidth="xl" style={{marginTop:10 }}>
            <Grid container spacing={3}> 

              <Stack direction="row" alignItems="center" justifyContent="space-between" margin={2}>
                  <Typography variant="h7" gutterBottom>
                      Select an user to view their activity calendar : 
                  </Typography>
              </Stack>

              <Grid container spacing={3} > 
                  {
                      clientList.map((e) => <Grid key={e.id} item   xs={12} sm={12} md={4} xl={3} lg={3} onClick={() => {
                          navigate(`/coach/schedule_activity?client_id=${e.id}`)
                      }} sx={{cursor: 'pointer'}}>
                          <UserListCard clientDetails={e} />
                      </Grid>)
                  }
              </Grid>
            </Grid>
          </Container>          
        }

        {
          !showOverviewDashboard && loading &&
          <Container  maxWidth="xl" style={{marginTop:10 }}>
            <Loader height={50} />
          </Container>
        }
        {
          !showOverviewDashboard && !loading &&
            <Container maxWidth="xl"  className="activity-calendar"  sx={{marginTop:4 }}>
               <StackStyled direction="row" spacing={2}>

                <FullCalendar
                  rerenderDelay={1}
                  eventDurationEditable={false}
                  editable 
                  droppable 
                  selectable
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar = {isMobile ? mobileHeaderToolbar : headerToolbar}
                  initialView= {isMobile ? 'timeGridDay' : 'dayGridMonth'}

                  // ref={calendarComponentRef}
                  events={data}
                  eventDrop={handleDrop}
                  drop={handleDrop}
                  eventReceive={handleEventReceive}
                  eventClick={handleEventClick}
                  dateClick={handleDateClick}
                  eventContent={renderEventContent}
                  // datesSet= {handleDateRangeChange}
                />
              
                {/* <SwipeableDrawer
                    anchor={'bottom'}
                    open={isScheduleDialogOpen}
                    onClose={toggleScheduleModal( 'bottom', false)}
                    onOpen={toggleScheduleModal( 'bottom', true)}>
                    <Grid padding={2} sx={{minHeight: 400}}>

                      {
                        drawerType === "CREATE"
                        ? <ScheduleActivityForm userId={activeClient && activeClient.user_id} date={formatDate(selectedActivityDate,"yyyy-MM-DD" )} handleClose={toggleScheduleModal('bottom', false)} visibleRange={range} />
                        : null
                      }
                      {
                        drawerType === "EDIT"
                        ? <EditActivityForm 
                            userId={activeClient && activeClient.user_id} 
                            date={formatDate(selectedActivityDate,"yyyy-MM-DD" )} 
                            handleClose={toggleScheduleModal('bottom', false)} 
                            selectedActivityId={selectedActivityId}
                            visibleRange={range} 
                            activityScheduleId={selectedEvent}
                          />
                        : null                          
                      }
                    </Grid>
                </SwipeableDrawer> */}

                <BoxStyled>
                  <Card className={'glass-box'} sx={{padding:1}}>
                    <Typography variant="h5">Schedule an activity</Typography>
                    <Grid padding={2} sx={{minHeight:'75vh'}}>
                      <ScheduleActivityForm 
                        activeClient={activeClient} 
                        userId={activeClient?.user_id} 
                        date={formatDate(selectedActivityDate,"yyyy-MM-DD" )}
                        handleClose={handleClose} 
                        visibleRange={range}                              
                      />
                    </Grid>
                    
                  </Card>
                </BoxStyled>
              </StackStyled>

              <Container>
                <Dialog
                  fullWidth="lg" 
                  maxWidth="lg"
                  open={isScheduleDialogOpen}
                  onClose={handleClose}
                  PaperProps={{
                    sx: {
                      minHeight: '90vh',
                      maxHeight: '90vh'
                    }
                  }}
                >

                  <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Grid container flexDirection={'column'} justifyContent={'flex-start'} padding={1}>
                          <Grid container item flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Grid item>
                              <Typography variant="h3">
                                {
                                  "Activity Schedule"
                                  // formatDate(selectedMealDate,"DD MMM yyyy" )
                                  // `${selectedMealDate.getDate()} ${selectedMealDate.getMonth()} ${selectedMealDate.getFullYear()}`
                                
                                      // selectedMeal 
                                      // ? selectedMeal.display_name
                                      // : 'Add new meal'
                                  }
                              </Typography>
                            </Grid>
                            <IconButton
                              edge="start"
                              color="inherit"
                              onClick={() => setIsScheduleDialogOpen(false)}
                              aria-label="close"
                            >
                              <Iconify icon="eva:close-circle-outline" sx={{height:40, width:40, cursor:'pointer'}}/>
                            </IconButton>
                          </Grid>
                        </Grid>
                    </Toolbar>
                  </AppBar>
                  <DialogContent>

                      <Grid padding={2} sx={{minHeight: 400}}>

                        {
                          drawerType === "CREATE"
                          ? <ScheduleActivityForm 
                              activeClient={activeClient} 
                              userId={activeClient?.user_id} 
                              date={formatDate(selectedActivityDate,"yyyy-MM-DD" )} 
                              handleClose={handleClose} 
                              visibleRange={range}                              
                            />
                          : null
                        }
                        {
                          drawerType === "EDIT"
                          ? <EditActivityForm 
                              activeClient={activeClient} 
                              userId={activeClient?.user_id} 
                              date={formatDate(selectedActivityDate,"yyyy-MM-DD" )} 
                              handleClose={handleClose} 
                              visibleRange={range} 
                              activityScheduleId={selectedEvent}
                              selectedActivityId={selectedActivityId}
                              // selectedType={selectedMealType}
                              // selectedMealPreference={selectedMealPreference}
                            />
                          : null                          
                        }
                      </Grid>
                    </DialogContent>
                  </Dialog>
              </Container>
              
            </Container>
        }

      </Page>
    );
}