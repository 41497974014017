import { Drawer, Stack, Typography, Box } from '@mui/material';

export const CorporateBillingDetail = ({ data, visible, setOpen }) => {
    return (
        <Drawer
            anchor='right'
            open={visible}
            onClose={() => setOpen(false)}
        >
            <Stack sx={{ width: 400 }} style={{ padding: '20px', gap: '10px' }}>
                <Typography variant='h6' sx={{ pb: 2 }}>Detail View</Typography>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Inovice Id</Typography>
                    <Typography>{data?.invoice_id}</Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Invoice Date</Typography>
                    <Typography>{data?.invoice_date}</Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Due Date</Typography>
                    <Typography>{data?.due_date}</Typography>
                </Box>
                <Typography variant='h6' sx={{ pt: 2 }}>Payment Details</Typography>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Payment Amount</Typography>
                    <Typography>{data?.payment_details?.payment_amount}</Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Payment Mode</Typography>
                    <Typography>{data?.payment_details?.payment_mode}</Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Payment Reference No</Typography>
                    <Typography>{data?.payment_details?.payment_reference_no}</Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Transaction Date</Typography>
                    <Typography>{data?.payment_details?.transaction_date}</Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Transaction Id</Typography>
                    <Typography>{data?.payment_details?.transaction_id}</Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Status</Typography>
                    <Typography>{data?.payment_details?.status}</Typography>
                </Box>

                <Typography variant='h6' sx={{ pt: 2 }}>Price Breakup</Typography>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Discount</Typography>
                    <Typography>{data?.price_breakup?.discount}</Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Grand Total</Typography>
                    <Typography>{data?.price_breakup?.grand_total}</Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Taxes</Typography>
                    <Typography>{data?.price_breakup?.taxes}</Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Total</Typography>
                    <Typography>{data?.price_breakup?.total}</Typography>
                </Box>
            </Stack>
        </Drawer>
    )
}