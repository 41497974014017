import { setActiveClient } from '../../../store/coach/reducer';
import { setLoadingStatus } from '../../../store/layout/reducer';
import { setUser } from '../../../store/user/reducer';
import axios from '../../../utils/axios-configure';
import { API_GET_USER_DETAILS, API_UPDATE_USER_DETAILS } from "../endpoints";
import {getAccessTokenFromCookie} from '../session/api'
import { setActiveStore } from '../../../store/partner_store/reducer';

export const fetchUserDetails = (dispatch, cookies) => {
  dispatch(setLoadingStatus({status: true}));  
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    if(accessToken != null){
        axios({
            method: "get",
            url: `${API_GET_USER_DETAILS}`,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
          })
          .then((response) => {
            // handle success
            if(response.status === 200){
              dispatch(setUser({data: response.data.data}));

              try{
                const corporateClients = response?.data?.data?.corporate_client
                if (corporateClients.length > 0){
                  const activeClient = corporateClients[0];
                  dispatch(setActiveClient({'client' : activeClient}));
                }
              }catch(e){
                console.log(e);
              }

              try{
                const stores = response?.data?.data?.stores
                if (stores.length > 0){
                  const activeStore = stores[0];
                  dispatch(setActiveStore({'data': activeStore}));
                }
              }catch(e){
                console.log(e);
              }



            }else{
              dispatch(setUser({data: {}}));
            } 
            dispatch(setLoadingStatus({status: false}));  
            return true;
          })
          .catch((err) => {
            dispatch(setUser({data: {}}));
            // console.log(response);
            dispatch(setLoadingStatus({status: false}));  
          });
    }else{
        dispatch(setUser({data: {}}));
        dispatch(setLoadingStatus({status: false}));  
      }
}

export const updateUserDetails = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));  
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
      axios({
          method: "post",
          url: `${API_UPDATE_USER_DETAILS}`,
          data,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
          // handle success
          if(response.status === 200){
              dispatch(setUser({data: response.data.data}));
          }else{
            dispatch(setUser({data: {}}));
          } 
          dispatch(setLoadingStatus({status: false}));  
          return true;
        })
        .catch((err) => {
          dispatch(setUser({data: {}}));
          // console.log(response);
          dispatch(setLoadingStatus({status: false}));  
        });
  }else{
      dispatch(setUser({data: {}}));
      dispatch(setLoadingStatus({status: false}));  
    }
}
