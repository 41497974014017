import { createSlice } from '@reduxjs/toolkit';
import { formatDate } from '../../utils/date';
import {SNACKBAR_SEVERITY, STATUS } from '../../constants/app_constants';

const dishSlice = createSlice({
  name: 'dish',
  initialState: {
    dishList: [],
    selectedDish: null,
  },
  reducers: {
    setDishList(state, action){
      state.dishList = action.payload.data;
    },
    setSelectedDish(state, action){
      state.selectedDish = action.payload.data;
    },
    resetSelectedDish(state, action){
      state.dishList = [];
      state.selectedDish = null;
    }
  }
})
 

export const getDishList = (state) => {
  try{
      return state.dish.dishList;
  }catch(e){
      return [];
  } 
} 

export const getSelectedDish = (state) => {
    try{
        return state.dish.selectedDish;
    }catch(e){
        return false;
    } 
} 

export const { setDishList, setSelectedDish, resetSelectedDish } = dishSlice.actions

export default dishSlice.reducer