
export const CART_ERROR = {
    NOT_SERVICABLE : 'Your cart is not servicable. Please check and try again.',
};

export const OTP_ERROR = {
    INVALID_MOBILE : 'You have entered an invalid mobile number.',
    UNABLE_TO_SEND_OTP : 'Unable to send OTP.',
    INVALID_OTP : 'You have entered an invalid OTP.',
    INCORRECT_OTP : 'Entered OTP is incorrect',
    UNABLE_TO_VERIFY_OTP : 'Unable to verify OTP.',
}; 

export const OTP_SUCCESS = {
    OTP_SEND_SUCCESS : 'OTP sent successfully.',
    OTP_VERIFY_SUCCESS : 'OTP verified successfully.', 
};

export const JOURNAL_UPDATE = {
    DAILY_LOG_SUCCESS : 'Daily Log updated successfully.',
    DAILY_LOG_ERROR : 'Unable to update user daily log.', 
};


export const JOURNAL_FETCH = {
    DAILY_LOG_SUCCESS : 'Daily Log fetched successfully.',
    DAILY_LOG_ERROR : 'Unable to fetch user daily log.', 
};

export const JOURNAL_CREATE = {
    JOURNAL_CREATE_SUCCESS : 'User Journal created successfully.',
    NO_USER_SELECTED : 'Please select a user.', 
};

export const ACTIVITY_CREATE = {
    SUCCESS : 'Activity created successfully.',
    ERROR : 'Unable to create activity.', 
    MISSING_ACTIVITY : 'Please select an activity.', 
};

export const ACTIVITY_SCHEDULE = {
    SUCCESS : 'Activity scheduled successfully.',
    ERROR : 'Unable to schedule activity.', 
};

export const ACTIVITY_SCHEDULE_DELETE = {
    SUCCESS : 'Activity schedule deleted successfully.',
    ERROR : 'Unable to deleted scheduled activity.', 
};

export const MEAL_SCHEDULE_ADD = {
    SUCCESS : 'Meal schedule added successfully.',
    ERROR : 'Unable to add meal schedule.', 
};

export const MEAL_SCHEDULE_UPDATE = {
    SUCCESS : 'Meal schedule updated successfully.',
    ERROR : 'Unable to update scheduled meal.', 
};

export const MEAL_SCHEDULE_DELETE = {
    SUCCESS : 'Meal schedule deleted successfully.',
    ERROR : 'Unable to deleted scheduled meal.', 
};

export const DISH_UPDATE = {
    SUCCESS : 'Dish updated successfully.',
    ERROR : 'Unable to update dish.', 
};

export const DISH_ADD = {
    SUCCESS : 'Dish added successfully.',
    ERROR : 'Unable to add dish.', 
};

export const DISH_RECIPE_UPDATE = {
    SUCCESS : 'Dish recipe updated successfully.',
    ERROR : 'Unable to update dish recipe.', 
};


export const MEAL_UPDATE = {
    SUCCESS : 'Meal updated successfully.',
    ERROR : 'Unable to update meal.', 
};

export const ADDRESS_SELECTION = { 
    MISSING : 'Please select an address to continue.', 
};

export const PAUSE_CLIENT_SUBSCRIPTION = {
    SUCCESS : 'Client Subscription paused successfully.',
    ERROR : 'Unable to pause client subscription.', 
};

export const RESUME_CLIENT_SUBSCRIPTION = {
    SUCCESS : 'Client Subscription resumed successfully.',
    ERROR : 'Unable to resume client subscription.', 
};

export const PLAN_SELECTION = {
    NO_PLAN_SELECTED : 'Please select a goal.',
};

export const ONBOARDING_FORM_MESSAGE = {
    INCOMPLETE_DETAILS : 'Please fill all fields.',
    TERMS_AND_CONDITIONS : 'Please agree to the terms and conditions'
};

export const ADD_ADDRESS_FORM = {
    INCOMPLETE_DETAILS : 'Please fill all fields.',
};

export const MEAL_PREFERENCE_ERROR = {
    NO_TENURE_SELECTED : 'Please select a valid tenure value between 3 and 180',
    MIN_TENURE : 'Tenure must be greater than 3',
    MAX_TENURE : 'Tenure must be less than 180'
};

export const CLIENT_SUBSCRIPTION_STATUS = {
    SUCCESS : '',
    ALREADY_EXISTS: 'A client subscription is already active for the mentioned order type and goal',
    ERROR: 'Unable to fetch subscription details'
}
export const USER_DELETE = {
    SUCCESS : 'User deleted successfully.',
    ERROR : 'Unable to deleted user.', 
};

export const USER_UPDATE = {
    SUCCESS : 'User updated successfully.',
    ERROR : 'Unable to update user.', 
};

export const STORE_ORDER_FETCH = {
    SUCCESS : 'Store order fetched successfully.',
    ERROR : 'Unable to fetch store order.', 
};

export const STORE_ORDER_UPDATE = {
    SUCCESS : 'Store order updated successfully.',
    ERROR : 'Unable to update store order.', 
};

export const STORE_STAFF_FETCH = {
    SUCCESS : 'Staff list fetched successfully.',
    ERROR : 'Unable to fetch staff list.', 
};

export const STORE_STAFF_UPDATE = {
    SUCCESS : 'Staff details updated successfully.',
    ERROR : 'Unable to update store staff.', 
};

export const STORE_STAFF_DELETE = {
    SUCCESS : 'Staff details deleted successfully.',
    ERROR : 'Unable to delete store staff.', 
};

export const STORE_STOCK_FETCH = {
    SUCCESS : 'Stock fetched successfully.',
    ERROR : 'Unable to fetch stock.', 
};

export const STORE_STOCK_UPDATE = {
    SUCCESS : 'Stock updated successfully.',
    ERROR : 'Unable to update stock.', 
};

export const STORE_STOCK_DELETE = {
    SUCCESS : 'Stock deleted successfully.',
    ERROR : 'Unable to delete stock.', 
};


export const STORE_VENDOR_FETCH = {
    SUCCESS : 'Vendors fetched successfully.',
    ERROR : 'Unable to fetch vendor.', 
};

export const STORE_VENDOR_UPDATE = {
    SUCCESS : 'Vendor updated successfully.',
    ERROR : 'Unable to update vendor.', 
};

export const STORE_VENDOR_DELETE = {
    SUCCESS : 'Vendor deleted successfully.',
    ERROR : 'Unable to delete vendor.', 
};

export const STORE_EXPENSE_FETCH = {
    SUCCESS : 'Expense fetched successfully.',
    ERROR : 'Unable to fetch expense.', 
};

export const STORE_EXPENSE_UPDATE = {
    SUCCESS : 'Expense updated successfully.',
    ERROR : 'Unable to update expense.', 
};

export const STORE_EXPENSE_DELETE = {
    SUCCESS : 'Expense deleted successfully.',
    ERROR : 'Unable to delete expense.', 
};

export const STORE_ROLES_FETCH = {
    SUCCESS : 'Roles fetched successfully.',
    ERROR : 'Unable to fetch roles.', 
};

export const STORE_ROLES_UPDATE = {
    SUCCESS : 'Role updated successfully.',
    ERROR : 'Unable to update role.', 
};

export const STORE_ROLES_DELETE = {
    SUCCESS : 'Role deleted successfully.',
    ERROR : 'Unable to delete role.', 
};

export const STORE_MENU_UPDATE = {
    SUCCESS : 'Menu updated successfully.',
    ERROR : 'Unable to update menu.', 
};

export const STORE_MENU_FETCH = {
    SUCCESS : 'Menu fetched successfully.',
    ERROR : 'Unable to fetch menu.', 
};

export const STORE_MENU_DELETE = {
    SUCCESS : 'Menu deleted successfully.',
    ERROR : 'Unable to delete menu.', 
};

export const STORE_DISH_LIST_FETCH = {
    SUCCESS : 'Dish list fetched successfully.',
    ERROR : 'Unable to fetch dish list.', 
};

export const STORE_SALES_FETCH = {
    SUCCESS : 'Store sale fetched successfully.',
    ERROR : 'Unable to fetch store sale.', 
};

export const STORE_SALES_UPDATE = {
    SUCCESS : 'Store sale updated successfully.',
    ERROR : 'Unable to update store sale.', 
};

export const STORE_INVOICE_FETCH = {
    SUCCESS : 'Store invoice fetched successfully.',
    ERROR : 'Unable to fetch store invoice.', 
};

export const STORE_INVOICE_UPDATE = {
    SUCCESS : 'Store invoice updated successfully.',
    ERROR : 'Unable to update store invoice.', 
};