import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';

import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';

import { useEffect, useState } from 'react';
// @mui
import { Card, CardHeader, Grid, Box, Typography, Button, SwipeableDrawer, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
// components
import { ActivityChart } from './chart';
import Iconify from '../../../../components/Iconify';
import { getActiveClient, getClientActivitySchedule, getClientJournalEditDetail, getClientJournalEditStatus } from '../../../../store/coach/reducer';
import { formatDate } from '../../../../utils/date';
import { updateClientActivityStatus } from '../../../../data/network/journal/api';
import { fetchActivitySchedule } from '../../../../data/network/client/api';
import ActivityInfo from './activity/ActivityInfo';
import CreateActivityForm from './activity/CreateActivityForm';
import ShowDropdownList from './activity/ShowDropdownList';
import { resetAddActivityStatus } from '../../../../store/activity/reducer';
import CreateJournalForm from '../CreateJournalForm';
import { setLoadingStatus} from '../../../../store/layout/reducer';
import { fetchActivityCategories } from '../../../../data/network/activity/api';

// ----------------------------------------------------------------------
ActivityTab.propTypes  = {
    journalDate : PropTypes.string,
}

export default function ActivityTab( {journalDate}) {


    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const activeClient = useSelector(state => getActiveClient(state));
    const clientActivitySchedule = useSelector(state => getClientActivitySchedule(state));
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [forceSave, setForceSave] = useState(false);

    const scheduledActivityList = useSelector(state => getClientActivitySchedule(state));

    const clientJournalEditDetail = useSelector(state => getClientJournalEditDetail(state));
    const clientJournalEditStatus = useSelector(state => getClientJournalEditStatus(state));

    const [isJournalDrawerOpen, setIsJournalDrawerOpen] = useState(false);
    const toggleJournalDrawer = (anchor , open ) => (event ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event ).key === 'Tab' ||
            (event ).key === 'Shift')
        ) {
            return;
        }
    
        setIsJournalDrawerOpen(open); 
    };

    const journalCreateCallback = () => {
        dispatch(setLoadingStatus({status: true}));
        setTimeout(() => {
            setForceSave(true);
            // handleSave();
        }, 1500);
    }

    useEffect(() => {
        if(clientJournalEditDetail !== null && forceSave){
            updateStatus();
        }
    },[clientJournalEditDetail, forceSave])


    useEffect(() => { 
        fetchActivitySchedule(dispatch, cookies, {
            user_id : activeClient.user_id,
            start_date : formatDate(clientJournalEditDetail.from_date),
            end_date: formatDate(clientJournalEditDetail.to_date),
        });

        fetchActivityCategories(dispatch, cookies);
    },[])

    const showInfoModal =  (e) => {
        setIsDrawerOpen(true);
        setSelectedActivity(e);
    }

    const toggleInfoModal = (anchor , open ) => (event ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event ).key === 'Tab' ||
            (event ).key === 'Shift')
        ) {
            return;
        }
    
        setIsDrawerOpen(open); 
    };
     
    
    const showCreateModal =  ()=> {
        setIsCreateDrawerOpen(true);
    }

    const toggleCreateModal = (anchor , open ) => (event ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event ).key === 'Tab' ||
            (event ).key === 'Shift')
        ) {
            return;
        }
    
        setIsCreateDrawerOpen(open); 
    };

    const toggleActivityStatus = (activity) => {
        setSelectedActivity(activity);
        if(clientJournalEditDetail && clientJournalEditDetail !== null && clientJournalEditDetail.id !== null){
            updateStatus();
        }else{
            setIsJournalDrawerOpen(true);
        }
    }

    function updateStatus () {
        updateClientActivityStatus(dispatch, cookies, {
            schedule_id : selectedActivity.schedule_id,
            user_id : activeClient.user_id,
            start_date : formatDate(clientJournalEditDetail.from_date),
            end_date: formatDate(clientJournalEditDetail.to_date),
            activity_id: selectedActivity.activity_id,
            scheduled_activity_id: selectedActivity.activity_id,
            status: (selectedActivity.status === "COMPLETED" ? "SCHEDULED" : "COMPLETED"),
            follow_up_id: clientJournalEditDetail.id,
            standard_duration: selectedActivity.standard_duration, 
            standard_burned_calories: selectedActivity.standard_burned_calories, 
            standard_rest_duration : selectedActivity.standard_rest_duration
        });
    }

    useEffect(() => { 

        if(clientJournalEditDetail !== null && forceSave){
            toggleActivityStatus();
        }

    },[clientJournalEditDetail, forceSave])


    const [radioValue, setRadioValue] =  useState('existing');

    const handleRadioChange = (event) => {
        setRadioValue(event.target.value);
    };

    return (
        <Grid container spacing={3} direction="column">
            <Grid item container>
                <Card className='glass-box w-100'>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <CardHeader title="User Activities" />
                        <Grid item margin={2}>
                            <Button variant="contained" onClick={(a) => {
                                showCreateModal( );
                            }}>
                                Add new activity
                            </Button>
                        </Grid>
                    </Grid>
                    <Box margin={2} />
                    <Grid container justifyContent="center" alignItems="center" px={4}>
                        {
                            scheduledActivityList !== null && scheduledActivityList.map(e => <Grid className="glass-box w-100" padding={2} mt={2} container item direction="row" key={e.id} justifyContent="space-between" alignItems="center">
                                    <Grid item >
                                        <Typography variant='caption'>{formatDate(e.start, "DD MMM YYYY")}</Typography>
                                        <Typography>{e.title}</Typography>
                                    </Grid>
                                    <Grid item >
                                         <Iconify icon="eva:info-outline" sx={{height:20, width:20, marginRight: 4 , cursor: 'pointer'}} onClick={(a) => {
                                                showInfoModal(e);
                                            }}/> 

                                            <Iconify icon="eva:checkmark-circle-2-outline"  sx={{
                                                height:20,
                                                width:20,
                                                cursor: 'pointer',
                                                color: `${e.status === "COMPLETED" ? 'green' : 'black'}`  
                                                
                                            }} 
                                                onClick={(a) => {
                                                    if(clientJournalEditStatus){
                                                        dispatch(resetAddActivityStatus());
                                                        toggleActivityStatus(e);    
                                                    }else{
                                                        //
                                                    }
                                                }}
                                            /> 
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Grid>
                    <Box margin={2} />
                    <SwipeableDrawer
                        anchor={'bottom'}
                        open={isDrawerOpen}
                        onClose={toggleInfoModal( 'bottom', false)}
                        onOpen={toggleInfoModal( 'bottom', true)}
                    >
                        <ActivityInfo activity={selectedActivity} drawerHandler={toggleInfoModal('bottom', false)} />
                         
                    </SwipeableDrawer>

                    <SwipeableDrawer
                        anchor={'bottom'}
                        open={isCreateDrawerOpen}
                        onClose={toggleCreateModal( 'bottom', false)}
                        onOpen={toggleCreateModal( 'bottom', true)}
                    >
                        <Grid padding={2} sx={{minHeight: 400}}>

                            <Typography variant="h5">
                                Add new Activity
                            </Typography>

                            <Box margin={2} />



                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Select one</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={radioValue}
                                    onChange={handleRadioChange}
                                >
                                    <FormControlLabel value="existing" control={<Radio />} label="Use Existing" />
                                    <FormControlLabel value="new" control={<Radio />} label="Create New" /> 
                                    
                                </RadioGroup>
                            </FormControl>

                            {
                                (radioValue === "existing")
                                ? <ShowDropdownList userId={activeClient.user_id} date={journalDate} handleClose={toggleCreateModal('bottom', false)}  />
                                : null
                            }

                            {
                                (radioValue === "new")
                                ? <CreateActivityForm userId={activeClient.user_id} date={journalDate} handleClose={toggleCreateModal('bottom', false)} /> 
                                : null
                            }
                        </Grid>
                    </SwipeableDrawer>
                </Card>
            </Grid> 

            <SwipeableDrawer
                anchor={'bottom'}
                open={isJournalDrawerOpen}
                onClose={toggleJournalDrawer( 'bottom', false)}
                onOpen={toggleJournalDrawer( 'bottom', true)}>
                <CreateJournalForm drawerHandler={toggleJournalDrawer('bottom', false)} createCallback={journalCreateCallback} />
            </SwipeableDrawer>
        </Grid>
        
    );
}
