import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import axios from '../../../utils/axios-configure';
import { getAccessTokenFromCookie } from "../session/api";
import { SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { setStoreDishList, setStoreMenu, setStoreMenuStats } from '../../../store/partner_store/reducer';
import { STORE_MENU_DELETE, STORE_MENU_UPDATE, STORE_MENU_FETCH, STORE_DISH_LIST_FETCH } from '../../../constants/message_constants';
import { API_DELETE_STORE_MENU, API_GET_STORE_DISH, API_GET_STORE_MENU, API_GET_STORE_MENU_STATS, API_UPDATE_STORE_MENU } from '../endpoints';

export const fetchStoreMenu = (dispatch, cookies,storeId, page = 1, count=10, filterBy = { key: '', value: '' }) => {
    dispatch(setLoadingStatus({ status: true }));
    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    if (accessToken != null) {
  
      let url = `${API_GET_STORE_MENU}?store_id=${storeId}&page=${page}&count=${count}`

      if (filterBy != null) {
        if (filterBy.key != null && filterBy.key !== '' && filterBy.key !== undefined ) {
          url += `&filter=${filterBy.key}`

          if (filterBy.value != null && filterBy.value !== '' && filterBy.value !== undefined) {
            url += `&term=${filterBy.value}`
          }
        }
      }

      axios({
        method: "get",
        url,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
      })
        .then((response) => {
          // handle success
          if (response.status === 200) {
            dispatch(setSnackbarMessage({ 'message': STORE_MENU_FETCH.SUCCESS, 'severity': SNACKBAR_SEVERITY.SUCCESS }));
            dispatch(setSnackbarVisibilityStatus({ 'status': true }));
            dispatch(setStoreMenu({ data: response.data }));
          } else {
            dispatch(setSnackbarMessage({ 'message': STORE_MENU_FETCH.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
            dispatch(setSnackbarVisibilityStatus({ 'status': true }));
          }
          dispatch(setLoadingStatus({ status: false }));
          return true;
        })
        .catch((err) => {
          dispatch(setLoadingStatus({ status: false }));
          dispatch(setSnackbarMessage({ 'message': STORE_MENU_FETCH.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
          dispatch(setSnackbarVisibilityStatus({ 'status': true }));
        });
    } else {
      dispatch(setLoadingStatus({ status: false }));
      //
    }
  }
  
  export const updateStoreMenu = (dispatch, cookies,storeId, data) => {
    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    dispatch(setLoadingStatus({ status: true }));
    if (accessToken != null) {
      // data.store_id = storeId;
      axios({
        method: "post",
        url: `${API_UPDATE_STORE_MENU}`,
        data,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
      }).then((response) => {
        // handle success
        if (response.status === 200) {
          if (response.data.success){
            dispatch(setSnackbarMessage({ 'message': STORE_MENU_UPDATE.SUCCESS, 'severity': SNACKBAR_SEVERITY.SUCCESS }));
            dispatch(setSnackbarVisibilityStatus({ 'status': true }));
            fetchStoreMenu(dispatch, cookies, storeId)
          }else{
            dispatch(setSnackbarMessage({ 'message': STORE_MENU_UPDATE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
            dispatch(setSnackbarVisibilityStatus({ 'status': true }));
          }
        } else {
          dispatch(setSnackbarMessage({ 'message': STORE_MENU_UPDATE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
          dispatch(setSnackbarVisibilityStatus({ 'status': true }));
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
      })
      .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
        dispatch(setSnackbarMessage({ 'message': STORE_MENU_UPDATE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
        dispatch(setSnackbarVisibilityStatus({ 'status': true }));
      });
    }
    dispatch(setLoadingStatus({ status: false }));
  }


  
  export const deleteStoreMenu = (dispatch, cookies, storeId, data) => {
    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    dispatch(setLoadingStatus({ status: true }));
    if (accessToken != null) {

      data.store_id = storeId;

      axios({
        method: "delete",
        url: `${API_DELETE_STORE_MENU}`,
        data,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
      })
        .then((response) => {
          // handle success
          if (response.status === 200) {
            if (response.data.success){ 
              dispatch(setSnackbarMessage({ 'message': STORE_MENU_DELETE.SUCCESS, 'severity': SNACKBAR_SEVERITY.SUCCESS }));
              dispatch(setSnackbarVisibilityStatus({ 'status': true }));
              fetchStoreMenu(dispatch, cookies, storeId);
            }else{
              dispatch(setSnackbarMessage({ 'message': STORE_MENU_DELETE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
              dispatch(setSnackbarVisibilityStatus({ 'status': true }));  
            }
          } else {
            dispatch(setSnackbarMessage({ 'message': STORE_MENU_DELETE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
            dispatch(setSnackbarVisibilityStatus({ 'status': true }));
          }
          dispatch(setLoadingStatus({ status: false }));
          return true;
        })
        .catch((err) => {
          dispatch(setLoadingStatus({ status: false }));
          dispatch(setSnackbarMessage({ 'message': STORE_MENU_DELETE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
          dispatch(setSnackbarVisibilityStatus({ 'status': true }));
        });
    }
    dispatch(setLoadingStatus({ status: false }));
  }
  
  

  export const fetchStoreMenuStats = (dispatch, cookies, storeId, filterBy = { key: '', value: '' } ) => {
    dispatch(setLoadingStatus({ status: true }));
    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    if (accessToken != null) {
  
      let url = `${API_GET_STORE_MENU_STATS}?store_id=${storeId}`
  
      if (filterBy != null) {
        if (filterBy.key != null && filterBy.key !== '' && filterBy.key !== undefined ) {
          url += `&filter=${filterBy.key}`
  
          if (filterBy.value != null && filterBy.value !== '' && filterBy.value !== undefined) {
            url += `&term=${filterBy.value}`
          }
        }
      }
  
      axios({
        method: "get",
        url,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
      })
        .then((response) => {
          // handle success
          if (response.status === 200) {
            dispatch(setStoreMenuStats({ data: response.data }));
          } else {
            //
          }
          dispatch(setLoadingStatus({ status: false }));
          return true;
        })
        .catch((err) => {
          dispatch(setLoadingStatus({ status: false }));
        });
    } else {
      dispatch(setLoadingStatus({ status: false }));
      //
    }
  }
  

  export const fetchStoreDishList = (dispatch, cookies,storeId, page = 1, count=10, filterBy = { key: '', value: '' }) => {
    dispatch(setLoadingStatus({ status: true }));
    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    if (accessToken != null) {
  
      let url = `${API_GET_STORE_DISH}?store_id=${storeId}&page=${page}&count=${count}`

      if (filterBy != null) {
        if (filterBy.key != null && filterBy.key !== '' && filterBy.key !== undefined ) {
          url += `&filter=${filterBy.key}`

          if (filterBy.value != null && filterBy.value !== '' && filterBy.value !== undefined) {
            url += `&term=${filterBy.value}`
          }
        }
      }

      axios({
        method: "get",
        url,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
      })
        .then((response) => {
          // handle success
          if (response.status === 200) {
            dispatch(setSnackbarMessage({ 'message': STORE_DISH_LIST_FETCH.SUCCESS, 'severity': SNACKBAR_SEVERITY.SUCCESS }));
            // dispatch(setSnackbarVisibilityStatus({ 'status': true }));
            dispatch(setStoreDishList({ data: response.data }));
          } else {
            dispatch(setSnackbarMessage({ 'message': STORE_DISH_LIST_FETCH.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
            // dispatch(setSnackbarVisibilityStatus({ 'status': true }));
          }
          dispatch(setLoadingStatus({ status: false }));
          return true;
        })
        .catch((err) => {
          dispatch(setLoadingStatus({ status: false }));
          dispatch(setSnackbarMessage({ 'message': STORE_DISH_LIST_FETCH.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
          dispatch(setSnackbarVisibilityStatus({ 'status': true }));
        });
    } else {
      dispatch(setLoadingStatus({ status: false }));
      //
    }
  }