import { createSlice } from '@reduxjs/toolkit';
import { STATUS } from '../../constants/app_constants';

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    subscriptionPlanList: [],
    subscriptionPlanListStatus : STATUS.LOADING,
    selectedSubscriptionPlan : null
  },
  reducers: {
    setSubscriptionPlanList(state, action) {
      state.subscriptionPlanList = action.payload.data;
    },
    setSubscriptionPlanListStatus(state, action) {
      state.subscriptionPlanListStatus = action.payload.status;
    },
    setSelectedSubscriptionPlan(state, action) {
      console.log("-------");
      console.log(action.payload.data);
      console.log("-------");
      state.selectedSubscriptionPlan = action.payload.data;
    },
    resetSubscriptionPlanState(state, action) {
      state.subscriptionPlanList = [];
      state.subscriptionPlanListStatus = STATUS.LOADING;
      state.selectedSubscriptionPlan = null;
    }
  }
})

export const getSubscriptionPlanList = (state) => state.subscription.subscriptionPlanList

export const getSubscriptionPlanListStatus = (state) => state.subscription.subscriptionPlanListStatus

export const getSelectedSubscriptionPlan = (state) => state.subscription.selectedSubscriptionPlan

export const { setSubscriptionPlanList, setSubscriptionPlanListStatus, setSelectedSubscriptionPlan } = subscriptionSlice.actions

export default subscriptionSlice.reducer