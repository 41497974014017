import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { Button, Card, Container, Divider, Grid, Typography } from "@mui/material";

import { fetchSubscriptionPlanList } from "../../../data/network/subscription/api";
import Page from "../../../components/Page";
import { getSubscriptionPlanList } from "../../../store/subscription/reducer";
import Iconify from "../../../components/Iconify";

export const MealPlanList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [cookies] = useCookies(['mobile', 'access_token', 'session']);

    const mealPlans = useSelector((state) => getSubscriptionPlanList(state));

    useEffect(() => {
        // fetchSubscriptionPlanList(dispatch, cookies);                   
    },[])

    return (
        <Grid container pt={2}>
            <Card className={'glass-box'} sx={{padding:2}}>
                <Grid container>
                    <Typography variant="h3">
                        Meal Plans List
                    </Typography>
                </Grid>

                <Divider />

                <Grid container direction={'row'} justifyContent={'space-between'} rowGap={6} columnGap={4} my={4}>
                    {
                        mealPlans && mealPlans.map((mealPlan) => {
                            return <Card className="glass-box meal-plan-card">                            
                                <Grid item direction={'column'} justifyContent={'flex-start'} p={2}>
                                    <Grid item my={1}>
                                        <Typography variant="h4">{mealPlan.display_name}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1"
                                            sx={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "-webkit-box",
                                                lineClamp:"4",
                                                boxOrient: "vertical",
                                                WebkitLineClamp: "4",
                                                WebkitBoxOrient: "vertical",
                                                minHeight:'80px'
                                            }}
                                        >{mealPlan.description}</Typography>
                                    </Grid>
                                    <Grid item my={1} sx={{minHeight:'110px'}}>
                                        {/* <Typography variant="h6">{`Active Clients`}</Typography> */}
                                    </Grid>
                                    <Grid direction={'row'} justifyContent={'flex-end'} sx={{position:'absolute', bottom:0, right:0}}>
                                        <Button variant={'contained'} sx={{height:48, borderBottomLeftRadius:0, borderTopLeftRadius: 16, borderTopRightRadius:0}} 
                                            onClick={() => {
                                                navigate(`/coach/meal_plan?meal_plan_id=${mealPlan.id}`);
                                            }}>
                                            <Typography variant="body1" sx={{mx:1}}>View</Typography>
                                            <Iconify icon={'eva:arrow-forward-outline'} sx={{fontSize:24, mr:1}} />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Card>
                        })
                    }
                </Grid>
            </Card>
        </Grid>
    )
}
