import { Container } from "@mui/material";

import Page from "../../../components/Page";
import { StoreVendorList } from "./StoreVendorList";

export const StoreVendor = () => {
    return (
        <Page title="Vendors">
            <Container size="xl">
                <StoreVendorList />
            </Container>
        </Page>
    );
}