import { useSelector, useDispatch } from 'react-redux';  
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikProvider, useFormik } from 'formik';
import { useState,useEffect  } from 'react';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// material
import { Grid, Button, Stack, Typography, TextField, Box, InputAdornment, DialogContent, Dialog, Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import { getActiveClient, getClientJournalEditDetail } from '../../../store/coach/reducer';
import { updateUserDailyLog } from '../../../data/network/journal/api';
import { formatDate } from '../../../utils/date';
import { DAILY_LOG_TYPE } from '../../../constants/app_constants';
import '../../../assets/css/Dish.css';
import { updateDishRecipe } from '../../../data/network/dish/api';

export default function DishRecipeForm( {dish} ) {
    const theme = useTheme();
    const activeClient = useSelector(state => getActiveClient(state));
    
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const dispatch = useDispatch();
    const clientJournalEditDetail = useSelector(state => getClientJournalEditDetail(state)); 

    const initialValues = {
        recipe: [
          {
            title: '',
            description: '',
            instruction: '',
            image: '',
            video: '',
          },
        ],
    };

    return (  
        <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
                await new Promise((r) => setTimeout(r, 500));
                
                const formData = [];

                values.recipe.forEach(e => {
                    if(e.title && e.title !== ""){
                        formData.push({title: e.title, description: e.description, instruction: e.instruction, image: e.image, video: e.video})
                    }
                });

                if(formData.length > 0){

                    updateDishRecipe(dispatch, cookies, {
                        dish_id : dish.id,
                        data: JSON.stringify(formData, null),
                    });
                }
            }}
        >
            {({ values }) => (
                <Form>
                    {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                label="Select Date" 
                                inputFormat="dd MMM yyyy"
                                value={startDateValue}
                                onChange={handleStartDateChange}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        
                        </LocalizationProvider>
                         
                    </Stack> */}
                    <Box margin={2} />
                <FieldArray name="recipe">
                    {({ insert, remove, push }) => (
                    <div>

                        <Grid item container direction="column" justifyContent="center">
                            {values.recipe && values.recipe.length > 0 &&
                                values.recipe.map((meal, index) => (
                                    <Card className="glass-box" style={{padding:10, margin:10}}>
                                        <Stack key={index} direction={{ xs: 'column', sm: 'row' }} spacing={2} mt={2}>
                                            <Grid container justifyContent="space-between">
                                                <Typography variant="h6">
                                                    Step {index+1}
                                                </Typography>

                                                <Button
                                                    type="button"
                                                    className="secondary"
                                                    onClick={() => remove(index)} >
                                                    Remove
                                                </Button>
                                            </Grid>
                                        </Stack>

                                        <Stack key={index} direction={{ xs: 'column', sm: 'row' }} spacing={2} mt={2}>
                                            <Field
                                                className="dish-recipe-form-field"
                                                name={`recipe.${index}.title`}
                                                placeholder="Title"
                                                type="text"
                                            />
                                        </Stack>

                                        <Stack key={index} direction={{ xs: 'column', sm: 'row' }} spacing={2} mt={2}>
                                            <Field
                                                className="dish-recipe-form-field"
                                                type="text"
                                                fullWidth
                                                label="Description"
                                                placeholder="Description"
                                                name={`recipe.${index}.description`}
                                            />

                                        </Stack>  

                                        <Stack key={index} direction={{ xs: 'column', sm: 'row' }} spacing={2} mt={2}>
                                            <Field
                                                className="dish-recipe-form-field"
                                                name={`recipe.${index}.instruction`}
                                                placeholder="Instruction"
                                                type="text"
                                            />
                                        </Stack>
                                        <Stack key={index} direction={{ xs: 'column', sm: 'row' }} spacing={2} mt={2}>
                                            <Field
                                                className="dish-recipe-form-field"
                                                name={`recipe.${index}.image`}
                                                placeholder="Image"
                                                type="text"
                                            />
                                        </Stack>

                                        <Stack key={index} direction={{ xs: 'column', sm: 'row' }} spacing={2} mt={2}>
                                            <Field
                                                className="dish-recipe-form-field"
                                                name={`recipe.${index}.video`}
                                                placeholder="Video"
                                                type="text"
                                            />
                                        </Stack>
                                    </Card>
 
                                ))
                            }
                        </Grid>
                        <Grid item container direction="row" justifyContent="center" mt={4}>
                            <Button                                  
                                type="button"
                                className="secondary"
                                variant="outlined"
                                onClick={() => push({ name: '', qty: '' })}
                            >
                                Add Row
                            </Button>
                        </Grid>
                    </div>
                    
                    )}
                </FieldArray>


                
                <Grid item container direction="row" justifyContent="center" mt={10}>
                    <Button variant='contained' type="submit">
                        Update Dish Recipe
                    </Button>                            
                </Grid>

                </Form>
            )}
        </Formik>
 

    );
}
