import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Dialog, DialogContent } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import PauseSubscriptionForm from './PauseSubscriptionForm';
import ResumeSubscriptionForm from './ResumeSubscriptionForm';

// ----------------------------------------------------------------------

export default function UserMoreMenu(props) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const { clientId, mealPlan } = props;

  const [isPauseModalopen, setIsPauseModalopen] = useState(false);
  const [isResumeModalopen, setIsResumeModalopen] = useState(false);

  const handlePauseModalClose = () => {
    setIsPauseModalopen(false);
    setIsOpen(false);
  };

  const handleResumeModalClose = () => {
    setIsResumeModalopen(false);
    setIsOpen(false);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Dialog open={isPauseModalopen} onClose={handlePauseModalClose}> 
        <DialogContent> 
          <PauseSubscriptionForm clientId={clientId} mealPlan={mealPlan} handleClose={handlePauseModalClose} />
        </DialogContent>
      </Dialog>

      <Dialog open={isResumeModalopen} onClose={handleResumeModalClose}> 
        <DialogContent> 
          <ResumeSubscriptionForm clientId={clientId} mealPlan={mealPlan} handleClose={handleResumeModalClose} />
        </DialogContent>
      </Dialog>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >

        {
          mealPlan.status === "ACTIVE" &&
          <MenuItem sx={{ color: 'text.secondary' }} onClick={() => {
            
            setIsPauseModalopen(true);
            // navigate(`/coach/schedule_meal/?client_id=${clientId}`);
          }}>
            <ListItemIcon>
              <Iconify icon="eva:pause-circle-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Pause" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>  
        }

        {
          mealPlan.status === "PAUSED" &&
          <MenuItem sx={{ color: 'text.secondary' }} onClick={() => {
            setIsResumeModalopen(true);
            // navigate(`/coach/schedule_meal/?client_id=${clientId}`);
          }}>
            <ListItemIcon>
              <Iconify icon="eva:play-circle-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Resume" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>  
        }

        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => {
          navigate(`/coach/profile/?client_id=${clientId}`);
        }}>
          <ListItemIcon>
            <Iconify icon="ep:food" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Profile" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => {
          navigate(`/coach/schedule_meal/?client_id=${clientId}`);
        }}>
          <ListItemIcon>
            <Iconify icon="ep:food" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Schedule Meals" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }}  onClick={() => {
          navigate(`/coach/schedule_activity/?client_id=${clientId}`);
        }}>
          <ListItemIcon>
            <Iconify icon="fa-solid:running" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Schedule Activities" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => {
          navigate(`/coach/report/?client_id=${clientId}`);
        }}>
          <ListItemIcon>
            <Iconify icon="ion:analytics" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="View Report" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem sx={{ color: 'text.secondary' }}  onClick={() => {
          navigate(`/coach/journal/?client_id=${clientId}`);
        }}>
          <ListItemIcon>
            <Iconify icon="bxs:food-menu" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="View Journal" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

      </Menu>
    </>
  );
}
