import { Container } from "@mui/material";

import Page from "../../../components/Page";
import { StoreStockList } from "./StoreStockList";

export const StoreStock = () => {
    return (
        <Page title="Stock">
            <Container size="xl">
                <StoreStockList />
            </Container>
        </Page>
    );
}