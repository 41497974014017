import { useTheme,Grid, Container, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Page from "../../components/Page"; 
import UserSummary from "../../sections/coach/user/UserSummary";
import MedicalHistory from "../../sections/coach/user/MedicalHistory";
import Lifestyle from "../../sections/coach/user/Lifestyle";
import Journal from "./Journal";
import Logo from "../../components/Logo";

export default function UserProfile() {
    const theme = useTheme();
  
    const navigate = useNavigate();
  
    return (
      <Page title="User Profile" style={{height:'500px'}}>
        {/* <Container maxWidth="xl"> */}

            <Logo />
            <Grid container flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-start'} >
                {/* <Grid container item xs={12} md={12} lg={8} mt={1} order={{ xs: 1, sm: 1 , md: 1, lg:2, xl:2}}> */}
                
                
                <Grid padding={2} container item direction="column" xs={12} md={12} lg={8.5} order={{ xs: 1, sm: 1 , md: 1, lg:0, xl:0}}>
                    
                    <MedicalHistory />

                    <Lifestyle />
                      
                    {/* <FoodPreference /> */}
                </Grid>
                <Grid item xs={12} md={12} lg={3} order={{ xs: 1, sm: 1 , md: 1, lg:1, xl:1}}>

                    <UserSummary />

                    <Journal />

                </Grid>
            </Grid>

        {/* </Container> */}
    </Page>
    );
}