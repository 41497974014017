import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider,Field, Formik, FormikConfig, FormikValues } from 'formik';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// material
import { Link, Box, Card, CardContent, Checkbox, CircularProgress, Container, Grid, TextField,Button, Stack, FormControlLabel, InputLabel, Select, MenuItem, Switch, FormGroup, Typography, Dialog, DialogContent, Toolbar, IconButton, AppBar } from '@mui/material';
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { useDispatch, useSelector } from "react-redux";
import { mixed, number, object } from 'yup';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import { LoadingButton} from '@mui/lab';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { ONBOARDING_FORM_STEPS, ORDER_TYPE, PAYMENT_STATUS, PLAN_SELECTION_STATUS, SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { sendOTP, verifyOTP } from '../../../data/network/otp/api';

import { clearOTPState, getOTPSentStatus, getOTPVerificationStatus } from '../../../store/otp/reducer';
import { getClientDetailStatus, getClientPlanSelectionStatus, getClientOrderType, 
  setClientPlanSelection, getActiveClient, getClientSelectedPlan, setClientOrderType,
  setClientDetailSubmitted, getClientPaymentStatus, resetClientState  
} 
from '../../../store/coach/reducer';
import { checkClientSubscription, confirmOrder, updateClientDetails } from '../../../data/network/client/api';
import { fetchSubscriptionPlanList } from '../../../data/network/subscription/api';
import SubscriptionPlanList from './SubscriptionPlanList';
import MealPreferenceContainer from './MealPreferenceContainer';
import SubscriptionDetailsContainer from './SubscriptionDetailsContainer';
import PaymentContainer from './PaymentContainer';
import OnboardingConfirmation from './OnboardingConfirmation';
import { getOnboardingCurrentStep, resetOnboardingForm, setOnboardingCurrentStep } from '../../../store/onboarding/reducer';

import '../../../assets/css/OnboardingForm.css';
import Iconify from '../../../components/Iconify';

import {formatDate} from '../../../utils/date';
import { getUserId } from '../../../store/user/reducer';
import { setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import { ONBOARDING_FORM_MESSAGE, OTP_SUCCESS, PLAN_SELECTION } from '../../../constants/message_constants';
import { getSubscriptionPlanList } from '../../../store/subscription/reducer';

export default function ClientOnboardingForm({handleClose}){
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
 
  const LoginSchema = Yup.object().shape({
    mobile: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
    .min(10, "Phone number is too short")
    .max(10, "Phone number is too long"),
    otp: Yup.string()
      .required('OTP is required')
      .min(4, "OTP is too short")
      .max(4, "OTP is too long"), 
  });

  const activeClient = useSelector((state) => getActiveClient(state));
  const clientPlanSelectionStatus = useSelector((state) => getClientPlanSelectionStatus(state));
  const clientSelectedPlan = useSelector((state) => getClientSelectedPlan(state));
  const clientPaymentStatus = useSelector((state) => getClientPaymentStatus(state));
  const partnerId = useSelector((state) => getUserId(state));
  const orderType = useSelector((state) => getClientOrderType(state));
  
  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
  const [showOTPContainer, setShowOTPContainer] = useState(false);
  const [otpValue, setOTPValue] = useState(null);
  const [clientMobile, setClientMobile] = useState(null);
  const [firstNameValue, setFirstNameValue] = useState('');
  const [lastNameValue, setLastNameValue] = useState('');
  const [weightValue, setWeightValue] = useState('');
  const [heightValue, setHeightValue] = useState('');
  const [genderValue, setGenderValue] = useState('');
  const [dobValue, setDobValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [initialValuesUpdated, setInitialValuesUpdated] = useState(false);

  const formik = useFormik({
    initialValues: {
      mobile: '',
      otp: '',
    },
    validationSchema: LoginSchema,
    onSubmit: () => {

    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const dispatch = useDispatch();

  const selectedOrderType = useSelector(state => getClientOrderType(state));
  const otpSent = useSelector(state => getOTPSentStatus(state));

  useEffect(() => {
    if(otpSent){
      setShowOTPContainer(true);
    }else{
      setShowOTPContainer(false);
    }
  },[otpSent]);

  const handleSendOTPClick = () => {
    dispatch(resetOnboardingForm());
    dispatch(clearOTPState());
    dispatch(resetClientState());

    if(clientMobile !== null){
      
      // call send OTP Api here
      sendOTP(dispatch, cookies, clientMobile);
    }
  }
  
  const handleVerifyOTPClick = () => { 
    // call verify OTP Api here 
    verifyOTP(dispatch, cookies, clientMobile, otpValue, partnerId);
  }

  useEffect(() => { 
    if(activeClient != null && !initialValuesUpdated){ 
      setInitialValuesUpdated(true);
      setFirstNameValue(activeClient.first_name);
      setLastNameValue(activeClient.last_name);
      setHeightValue(activeClient.height);
      setWeightValue(activeClient.weight);
      setGenderValue(activeClient.gender);

      const formattedDate = new Date(activeClient.dob);
      setDobValue(formattedDate);

      setEmailValue(activeClient.email);
    }
  },[activeClient]);

  const handleSaveDetailsClick = () => {
    // call Update Client Details Api here 

    const formattedDate = formatDate(dobValue, "YYYY-MM-DD");

    if(termsConsentStatus){
      if(firstNameValue && lastNameValue && weightValue && heightValue && genderValue && formattedDate){
        updateClientDetails(dispatch, cookies, {
          'user_id': activeClient.user_id,
          'first_name' : firstNameValue,
          'last_name' : lastNameValue,
          'weight' : weightValue,
          'height' : heightValue,
          'gender' : genderValue,
          'dob' : formattedDate,
          'mobile_no' : activeClient.mobile_no,
          'email': emailValue
        });
      }else{
        dispatch(setSnackbarMessage({'message': ONBOARDING_FORM_MESSAGE.INCOMPLETE_DETAILS , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));
      }
    }else{
      dispatch(setSnackbarMessage({'message': ONBOARDING_FORM_MESSAGE.TERMS_AND_CONDITIONS, 'severity': SNACKBAR_SEVERITY.ERROR}));
      dispatch(setSnackbarVisibilityStatus({'status': true }));
    }
  }

  const handleSwitchUser = () => {
    dispatch(setOnboardingCurrentStep({step: ONBOARDING_FORM_STEPS.OTP_VERIFICATION}));
    setOTPValue(null);
    formik.values.otp = '';
    // dispatch(resetOnboardingForm());
    // dispatch(clearOTPState());
    // dispatch(resetClientState());
  }

  const [open, setOpen] = useState(false);
  
  const [termsConsentStatus, setTermsConsentStatus] = useState(true);

  const termsLink = <a target="_blank" href="https://the-bytes.com/terms-and-conditions" rel='noreferrer'>terms and conditions</a>;

  const handleTermsConsentChange = (e) => {
    console.log(e.target.checked);
    if(e.target.checked) {
      setTermsConsentStatus(true)
    }else{
      setTermsConsentStatus(false)
    }
  }

  return (
    <Container>
      <Dialog 
        fullWidth="lg"
        maxWidth="lg"
        open
        onClose={handleClose}
        PaperProps={{
          sx: {
            minHeight: '90vh',
            maxHeight: '90vh'
          }
        }}
        // sx={{height:'90vh'}}
        // sx={{height:'80vh', width:'80%', margin:'10%', overflowY:'auto' }}
        >

        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Grid container direction="column">

            <Typography sx={{ padding:2, flex: 1 }} variant="h6" component="div">
              Onboarding Form 
            </Typography>
            {
              activeClient && activeClient !== null && activeClient.mobile_no !== null
              ? <Grid>
                  <Typography sx={{paddingLeft:2, paddingBottom: 1}} variant="h7" component="span">
                    Customer Mobile : {activeClient.mobile_no}
                  </Typography>
                  <Button sx={{ml:2, mb: 1}} variant="contained" color="error" onClick={() => handleSwitchUser()}>Switch</Button>
                </Grid>
              : <Grid>
                  <Button sx={{ml:2, mb: 1}} variant="contained" color="error" onClick={() => handleSwitchUser()}>Reset</Button>
                </Grid>
            }
            </Grid>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Iconify  icon="eva:close-circle-outline" sx={{height:40, width:40, cursor:'pointer'}}/>
            </IconButton>
          </Toolbar>
        </AppBar>
        
        <DialogContent>
          <FormikStepper
            initialValues={{
              // firstName: '',
              // lastName: '',
          }}
          onSubmit={async (values) => {
              // await sleep(3000);
              console.log('values', values);
          }}
          >
          <FormikStep label="Mobile Verification">

            <FormikProvider value={formik}>
                <Stack spacing={3} sx={{marginTop:8}}  className="stepper-content" justifyContent="center" alignItems="center">
                  <TextField
                    autoComplete="off"
                    type="number"
                    label="Mobile Number"
                    onKeyUp={e => {
                      setClientMobile(e.target.value)
                    }}
                    {...getFieldProps('mobile')}
                    error={Boolean(touched.mobile && errors.mobile)}
                    helperText={touched.mobile && errors.mobile}
                    style={{maxWidth:'500px'}}
                  />
                { 
                  showOTPContainer  
                  ? <TextField
                    
                    autoComplete="current-otp"
                    type="number"
                    // type={showPassword ? 'number' : 'password'}
                    label="OTP"
                    onKeyUp={e => {
                      setOTPValue(e.target.value);
                    }}
                    {...getFieldProps('otp')}
                    error={Boolean(touched.otp && errors.otp)}
                    helperText={touched.otp && errors.otp}
                    style={{maxWidth:'500px'}}
                  />
                  : <Container />  }
                </Stack>
                  
                <Box margin={2} />
               
                <Grid direction="column" justifyContent="center" alignItems="center" container>
                  <Box padding={2} style={{width:'20%'}}>
                    { !showOTPContainer 
                      ? <>
                        {activeClient && activeClient.mobile_no === clientMobile
                          ? <LoadingButton fullWidth size="large" onClick={() => 
                            dispatch(setOnboardingCurrentStep({step: ONBOARDING_FORM_STEPS.PERSONAL_INFO}))
                          } variant="contained" loading={isSubmitting}>
                            Continue
                          </LoadingButton>
                          : <LoadingButton fullWidth size="large" onClick={() => handleSendOTPClick()} variant="contained" loading={isSubmitting}>
                            Send OTP
                          </LoadingButton>
                        } 
                        </>
                      : <></>  
                    }

                    { showOTPContainer   ? 
                    <LoadingButton  fullWidth size="large"  onClick={() => handleVerifyOTPClick()} variant="contained" loading={isSubmitting}>
                      Verify OTP
                    </LoadingButton> 
                      : <></>  }   
                  </Box>
                </Grid>

                { showOTPContainer  ? 
                <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
                  
                  <Link component={RouterLink} variant="subtitle2" to="#" underline="hover" onClick={() => handleSendOTPClick()}>
                    Resend OTP?
                  </Link>
                </Stack>
                : <Container />  }

            </FormikProvider>
          </FormikStep>
          <FormikStep
              label="Personal Details"
              validationSchema={object({
                // firstName:Yup.string().required('First name is required'),
                // lastName:Yup.string().required('Last name is required'),
                // height:Yup.string().required('Height is required'),
                // weight:Yup.string().required('Weight is required'),
                // gender:Yup.string().required('Gender is required'),

                // firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name required'),
                // lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
              })}
            >
            <FormikProvider value={useFormik({
              initialValues: {
                first_name : activeClient != null ?activeClient.first_name : '',
                last_name:activeClient != null ? activeClient.last_name : '',
                height:activeClient != null ? activeClient.height : '',
                weight:activeClient != null ? activeClient.weight : '',
                gender: activeClient != null ? activeClient.gender : '',
                dob:activeClient != null ? activeClient.dob : ''
                // remember: true,
              },
              // validationSchema: LoginSchema,
              onSubmit: () => {
                console.log("123")
              },
            })}>
              
                <Stack spacing={3} sx={{marginTop:2}}  className="stepper-content">

                {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Typography>Customer Mobile : {activeClient !== null ? activeClient.mobile_no : ""}</Typography>
                    <Button variant="outlined" onClick={() => handleSwitchUser()}>Switch</Button>
                  </Stack> */}

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <TextField
                      fullWidth
                      required
                      id="first_name"
                      name="first_name"
                      label="First name"
                      value={firstNameValue}
                    //  {...getFieldProps('firstName')}
                      onChange = {
                        (e) => {
                          setFirstNameValue(e.target.value);
                        }
                      }
                      error={Boolean(false)}
                      helperText={false}
                    />

                    <TextField
                      fullWidth
                      required
                      label="Last name"
                      id="last_name"
                      name="last_name"
                      value={lastNameValue}
                      // value={lastNameValue}
                      // value={activeClient.first_name}
                    //  {...getFieldProps('lastName')}
                    onChange = {
                        (e) => {
                          setLastNameValue(e.target.value);
                        }
                      }
                      // {...getFieldProps('lastName')}
                      error={Boolean(false)}
                      helperText={false}
                    />

                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                    <TextField
                      fullWidth
                      required
                      type="number"
                      label="Height (in cms)"
                      value={heightValue}
                      onChange = {
                        (e) => {
                          setHeightValue(e.target.value);
                        }
                      }
                      // {...getFieldProps('height')}
                      error={Boolean(false)}
                      helperText={false}
                    />

                    <TextField
                      fullWidth
                      required
                      type="number"
                      label="Weight (in kg)"
                      onChange = {
                        (e) => {
                          setWeightValue(e.target.value);
                        }
                      }
                      value={weightValue}
                      // {...getFieldProps('weight')}
                      error={Boolean(false)}
                      helperText={false}
                    />
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                    <TextField
                      fullWidth
                      required
                      id="gender"
                      name="gender"
                      value={genderValue}
                      onChange={(e) => setGenderValue(e.target.value)}
                      select // tell TextField to render select
                      label="Gender">
                      <MenuItem value="MALE">Male</MenuItem>
                      <MenuItem value="FEMALE">Female</MenuItem>
                      <MenuItem value="OTHERS">Others</MenuItem>
                    </TextField>

                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                      <DatePicker 
                        required
                          name="date"
                          type="date"
                          autoComplete='off'
                          value={dobValue}
                          selected={dobValue}
                          onSelect={(date) => { 
                            setDobValue(date)
                          }}
                          // inputFormat="yyyy-MM-dd"
                          // format="yyyy-MM-dd"
                          maxDate={new Date()}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="yyyy-MM-dd"
                          label="Date of Birth"
                          placeholderText='Select Date of Birth'
                      />
                        
                    </LocalizationProvider>
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                    <TextField
                      fullWidth
                      type="email"
                      label="Email Address"
                      onChange = {
                        (e) => {
                          setEmailValue(e.target.value);
                        }
                      }
                      value={emailValue}
                      error={Boolean(false)}
                      helperText={false}
                    />
                  </Stack>
                  <Grid container justifyContent="start" alignItems="center">
                      <Grid item>
                        <Checkbox
                            checked={termsConsentStatus}
                            // color="success"
                            onChange={handleTermsConsentChange}                      
                            inputProps={{ 'aria-label': 'controlled' }}
                            sx={{width:'10px', height: '10px'}}
                      />
                      </Grid>
                      <Grid item ml={2}> 
                        I agree to the {termsLink}
                      </Grid>

                  </Grid>

                  <Grid  justifyContent="center" alignItems="center" container>
                    <Box padding={1} style={{width:'30%'}}>
                      <LoadingButton fullWidth size="large" onClick={() => handleSaveDetailsClick()} variant="contained" loading={isSubmitting}>
                        Save
                      </LoadingButton>
                    </Box>
                  </Grid>
                </Stack>
              
            </FormikProvider>
          </FormikStep>
          <FormikStep label="Diet Plan">
            {
              
              <Box paddingBottom={2}>

              <Box padding={1}/>
              <Grid container justifyContent="start" alignItems="center" onClick={() => {
                dispatch(setOnboardingCurrentStep({step: ONBOARDING_FORM_STEPS.PERSONAL_INFO}));
              }}
              style={{cursor:'pointer'}}>              
                <Iconify icon="bytesize:arrow-left" />
                <Box padding={0.5}/>
                <span>Go Back</span>
              </Grid>

                <div style={{height:'15px'} }>
                  <div />
                </div>
                {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Typography>Customer Mobile : {activeClient !== null ? activeClient.mobile_no : ""}</Typography>
                    <Button variant="outlined" onClick={() => handleSwitchUser()}>Switch</Button>
                  </Stack> */}
                <Box padding={2}  className="stepper-content">
                  <div>
                    <b>Please select a goal</b>
                  </div>
                </Box>
                
                <div >
                    <SubscriptionPlanList />
                </div>

                <Grid  justifyContent="center" alignItems="center" container>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked = {selectedOrderType === ORDER_TYPE.SUBSCRIPTION}
                          onChange={(e) => {
                            if(!e.target.checked){
                              dispatch( setClientOrderType( { 'type' : ORDER_TYPE.ZERO_DELIVERY }));
                            }else{
                              dispatch( setClientOrderType( { 'type' : ORDER_TYPE.SUBSCRIPTION }));
                            }
                          }}
                        />
                      }
                      label="I want to opt for Bytes Meal Plan" />
                  </FormGroup>
                </Grid>
                <Box padding={1}/>
                <Grid  justifyContent="center" alignItems="center" container >
                  <Box style={{width:'25%'}} padding={2}>
                    <Button variant="contained" fullWidth onClick={() => {
                      console.log(clientSelectedPlan);
                      if(clientSelectedPlan == null || Object.keys(clientSelectedPlan).length === 0) {
                        dispatch(setSnackbarMessage({'message': PLAN_SELECTION.NO_PLAN_SELECTED , 'severity': SNACKBAR_SEVERITY.ERROR}));
                        dispatch(setSnackbarVisibilityStatus({'status': true }));
                      }else{
                        // Check if subscription already exists for user
                        checkClientSubscription(dispatch, cookies, {
                          user_id: activeClient && activeClient.user_id,
                          order_type: selectedOrderType,
                          selected_plan: clientSelectedPlan.id,
                        });
                        // dispatch(setOnboardingCurrentStep({step: ONBOARDING_FORM_STEPS.PREFERENCE_SELECTION}));
                      }
                    }} >
                      Continue
                    </Button>
                  </Box>
                </Grid>
              </Box>
            }
          </FormikStep>
           
          <FormikStep label="Preference">
            <Box padding={1}/>
            <Grid container justifyContent="start" alignItems="center" onClick={() => {
              dispatch(setOnboardingCurrentStep({step: ONBOARDING_FORM_STEPS.PLAN_LIST}));
            }}
            style={{cursor:'pointer'}}>              
              <Iconify icon="bytesize:arrow-left" />
              <Box padding={0.5}/>
              <span>Go Back</span>
            </Grid>

            {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Typography>Customer Mobile : {activeClient !== null ? activeClient.mobile_no : ""}</Typography>
              <Button variant="outlined" onClick={() => handleSwitchUser()}>Switch</Button>
            </Stack> */}
            <Box paddingBottom={2} className="stepper-content"> 
              <MealPreferenceContainer />
            </Box>
          </FormikStep>

          <FormikStep label="Subscription Details">
            <Box padding={1}/>
            <Grid container justifyContent="start" alignItems="center" onClick={() => {
              dispatch(setOnboardingCurrentStep({step: ONBOARDING_FORM_STEPS.PREFERENCE_SELECTION}));
            }}
            style={{cursor:'pointer'}}>              
              <Iconify icon="bytesize:arrow-left" />
              <Box padding={0.5}/>
              <span>Go Back</span>
            </Grid>
            {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Typography>Customer Mobile : {activeClient !== null ? activeClient.mobile_no : ""}</Typography>
              <Button variant="outlined" onClick={() => handleSwitchUser()}>Switch</Button>
            </Stack> */}
            <Box paddingBottom={2} className="stepper-content">
              <SubscriptionDetailsContainer />
            </Box>
          </FormikStep>
          
          { orderType === ORDER_TYPE.SUBSCRIPTION && 
            <FormikStep label="Payment">
              <Box padding={1}/>
              <Grid container justifyContent="start" alignItems="center" onClick={() => {
                dispatch(setOnboardingCurrentStep({step: ONBOARDING_FORM_STEPS.SUBSCRIPTION_DETAILS}));
              }}
              style={{cursor:'pointer'}}>              
                <Iconify icon="bytesize:arrow-left" />
                <Box padding={0.5}/>
                <span>Go Back</span>
              </Grid>
              {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Typography>Customer Mobile : {activeClient !== null ? activeClient.mobile_no : ""}</Typography>
                <Button variant="outlined" onClick={() => handleSwitchUser()}>Switch</Button>
              </Stack> */}
              <Box paddingBottom={2} className="stepper-content">
                <PaymentContainer />
              </Box>
            </FormikStep>
          }
          {
            <FormikStep label="Confirmation">
              <Box paddingBottom={2} className="stepper-content">                          
                <OnboardingConfirmation handleClose={handleClose}/>
              </Box>
            </FormikStep>
          }
          </FormikStepper>
        </DialogContent>
      </Dialog>
    </Container>
  );
}


export function FormikStep({ children }) {
  return <>{children}</>;
}

export function FormikStepper({ children, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = React.useState(0);
  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);
  const otpVerified = useSelector((state) => getOTPVerificationStatus(state));
  const clientDetailsSubmitted = useSelector((state) => getClientDetailStatus(state));
  const clientPlanSelectionStatus = useSelector((state) => getClientPlanSelectionStatus(state));
  const subscriptionPlanList = useSelector((state) => getSubscriptionPlanList(state));

  const currentStep = useSelector((state) => getOnboardingCurrentStep(state));
  
  const orderType = useSelector((state) => getClientOrderType(state));

  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
  const dispatch = useDispatch();

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  useEffect(() => { 
    switch(currentStep.step){
      case ONBOARDING_FORM_STEPS.OTP_VERIFICATION.step:
        setStep(0);
        break;
      case ONBOARDING_FORM_STEPS.PERSONAL_INFO.step:
        setStep(1);
        break;
      case ONBOARDING_FORM_STEPS.PLAN_LIST.step:
        setStep(2);
        break;
      case ONBOARDING_FORM_STEPS.PREFERENCE_SELECTION.step:
        setStep(3);
        break;
      case ONBOARDING_FORM_STEPS.SUBSCRIPTION_DETAILS.step:
        setStep(4);
        break;
      case ONBOARDING_FORM_STEPS.PAYMENT_DETAILS.step:
        setStep(5);
        break;
      case ONBOARDING_FORM_STEPS.ONBOARDING_CONFIRMATION.step:
        if(orderType === ORDER_TYPE.ZERO_DELIVERY){
          setStep(5);
        }else{
          setStep(6);
        }
        break;
      default:
        setStep(0);
        break;
    }
  },[otpVerified, clientDetailsSubmitted, clientPlanSelectionStatus, currentStep, orderType]);

  useEffect(() => { 
    if(step === 2){
      if(subscriptionPlanList === undefined || subscriptionPlanList === null || subscriptionPlanList.length === 0 ){
        fetchSubscriptionPlanList(dispatch, cookies);
      }else{
        // console.log("plans available");
      }
    }
  },[step])

  return (
    <Formik
      {...props}
      validationSchema={currentChild && currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
          setCompleted(true);
        } else {
          setStep((s) => s + 1);
          helpers.setTouched({});
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          <Stepper alternativeLabel activeStep={step}>
            {childrenArray.map((child, index) => (
              <Step key={child.props.label} completed={step > index || completed}>
                <StepLabel>{child.props.label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {currentChild}
        </Form>
      )}
    </Formik>
  );
}