import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { Container } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { fetchSubscriptionPlanList } from "../../../data/network/subscription/api";
import Page from "../../../components/Page";
import '../../../assets/css/MealPlan.css';
import { MealPlanList } from "./MealPlanList";
import { MealPlanDetail } from "./MealPlanDetail";
import { getSubscriptionPlanList, setSelectedSubscriptionPlan } from "../../../store/subscription/reducer";

export const MealPlan = () => {

    const dispatch = useDispatch();
    const [cookies] = useCookies(['mobile', 'access_token', 'session']);

    const [searchParams, setSearchParams] = useSearchParams();
    const mealPlanId = searchParams.get("meal_plan_id");
    const mealPlans = useSelector((state) => getSubscriptionPlanList(state));

    useEffect(() => {
        if (mealPlanId && mealPlanId !== '') {

            if (mealPlans) {
                mealPlans.forEach((plan) => {
                    if (plan.id.toString() === mealPlanId.toString()) {
                        dispatch(setSelectedSubscriptionPlan({data: plan}));
                    }
                })
            }
        }
    },[mealPlanId])

    useEffect(() => {
        fetchSubscriptionPlanList(dispatch, cookies);
    },[])

    return (
        <Page title="Clients">
            <Container size="xl">
                {
                    mealPlanId && mealPlanId !== ''
                    ? <MealPlanDetail />
                    : <MealPlanList />
                }
            </Container>
        </Page>
    )
}
