import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import axios from '../../../utils/axios-configure';
import { getAccessTokenFromCookie } from "../session/api";
import { SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { setStoreSalesStats, setStoreSalesList } from '../../../store/partner_store/reducer';
import { STORE_SALES_UPDATE } from '../../../constants/message_constants';
import { API_GET_STORE_SALES, API_UPDATE_STORE_SALES, API_GET_STORE_SALES_STATS } from '../endpoints';

export const fetchStoreSales = (dispatch, cookies, storeId, startDate=null, endDate=null, page = 1, count=10, filterBy = { key: '', value: '' }, corporateId=null) => {
    dispatch(setLoadingStatus({ status: true }));
    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    if (accessToken != null) {
      let url = `${API_GET_STORE_SALES}?store_id=${storeId}&page=${page}&count=${count}&filter=${filterBy?.key}&term=${filterBy?.value}`
      if (startDate != null) {
          url += `&start_date=${startDate}`;
      }
  
      if (endDate != null) {
          url += `&end_date=${endDate}`;
      }

      if (corporateId != null) {
        url += `&corporate_id=${corporateId}`;
    }
  
      axios({
        method: "get",
        url,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
      })
        .then((response) => {
          // handle success
          if (response.status === 200) {
            dispatch(setStoreSalesList({ data: response.data }));
          } else {
            //
          }
          dispatch(setLoadingStatus({ status: false }));
          return true;
        })
        .catch((err) => {
          dispatch(setLoadingStatus({ status: false }));
        });
    } else {
      dispatch(setLoadingStatus({ status: false }));
      //
    }
  }
  
  export const updateStoreSales = (dispatch, cookies, storeId, data) => {
    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    dispatch(setLoadingStatus({ status: true }));
    if (accessToken != null) {
      data.store_id = storeId;
      axios({
        method: "post",
        url: `${API_UPDATE_STORE_SALES}`,
        data,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
      })
        .then((response) => {
          // handle success
          if (response.status === 200) {
  
            dispatch(setSnackbarMessage({ 'message': STORE_SALES_UPDATE.SUCCESS, 'severity': SNACKBAR_SEVERITY.SUCCESS }));
            dispatch(setSnackbarVisibilityStatus({ 'status': true }));
            fetchStoreSales(dispatch, cookies, storeId);
          } else {
            dispatch(setSnackbarMessage({ 'message': STORE_SALES_UPDATE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
            dispatch(setSnackbarVisibilityStatus({ 'status': true }));
          }
          dispatch(setLoadingStatus({ status: false }));
          return true;
        })
        .catch((err) => {
          dispatch(setLoadingStatus({ status: false }));
          dispatch(setSnackbarMessage({ 'message': STORE_SALES_UPDATE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
          dispatch(setSnackbarVisibilityStatus({ 'status': true }));
        });
    }
    dispatch(setLoadingStatus({ status: false }));
  }
  

  
  export const fetchStoreSalesStats = (dispatch, cookies, storeId, startDate=null, endDate=null, filterBy = { key: '', value: '' } ) => {
    dispatch(setLoadingStatus({ status: true }));
    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    if (accessToken != null) {
  
      let url = `${API_GET_STORE_SALES_STATS}?store_id=${storeId}`
  
      if (filterBy != null) {
        if (filterBy.key != null && filterBy.key !== '' && filterBy.key !== undefined ) {
          url += `&filter=${filterBy.key}`
  
          if (filterBy.value != null && filterBy.value !== '' && filterBy.value !== undefined) {
            url += `&term=${filterBy.value}`
          }
        }
      }
  
      axios({
        method: "get",
        url,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
      })
        .then((response) => {
          // handle success
          if (response.status === 200) {
            dispatch(setStoreSalesStats({ data: response.data }));
          } else {
            //
          }
          dispatch(setLoadingStatus({ status: false }));
          return true;
        })
        .catch((err) => {
          dispatch(setLoadingStatus({ status: false }));
        });
    } else {
      dispatch(setLoadingStatus({ status: false }));
      //
    }
  }
  