import React, {useState, useEffect, useRef} from 'react';
import { useTheme,Grid, Container, Typography,Box, IconButton, TableCell, FormControl, InputLabel, Select, OutlinedInput, Chip, MenuItem, Card, SwipeableDrawer, Stack, Dialog, DialogContent, AppBar, Toolbar, styled, TableRow, Button, Divider, Table, TableHead, TableBody } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import Page from "../../components/Page";

import  '../../assets/css/ScheduleMeal.css';
import { fetchClientSubscription, fetchClientDetails, scheduleUserActivity, fetchMealPlanDishOptions, fetchMealPlanSchedule, addMealPlanSchedule, updateMealPlanSchedule, removeScheduledUserMeal } from '../../data/network/client/api';
import { getClientList, getClientMealOptions, getClientMealSchedule, getClientSubscriptionPlanDetails } from '../../store/coach/reducer';
import {ReactComponent as Loader} from '../../assets/img/loader_3.svg';
import { formatDate } from '../../utils/date'; 
import UserListCard from '../../sections/coach/journal/UserListCard';
import ScheduleMealForm from '../../sections/coach/meal_calendar/ScheduleMealForm';
import EditMealForm from '../../sections/coach/meal_calendar/EditMealForm';
import { fetchPartnerDish, fetchPartnerMeals } from '../../data/network/dish/api';
import Iconify from '../../components/Iconify';


export default function ScheduleMeal() {

    const StackStyled = styled(Stack)(({theme}) => ({
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          '& .fc': {
              width: '100%',
          },
          '& .fc .fc-toolbar-title': {
              fontSize: '16px',
          }
      },
    }))

    const BoxStyled = styled('div')(({theme}) => ({
        display: 'flex',
        flexDirection: 'column',
        width: '25%',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          marginLeft: '0px !important',
        },
        '& .event': {
            alignSelf: 'end',
            background: '#1a252f',
            [theme.breakpoints.down('md')]: {
                marginTop: '20px',
            },
        },
        '& .MuiCard-root': {
            padding: '15px',
            borderLeft: '6px solid #FDAA2E',
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            marginTop: '30px',
            '&.breakfast': {
                borderLeft: '6px solid #FDAA2E',
                '& .MuiDivider-root': {
                    borderColor: '#FDAA2E',
                }
            },
            '&.lunch': {
                borderLeft: '6px solid #2AC3A7',
                '& .MuiDivider-root': {
                    borderColor: '#2AC3A7',
                }
            },
            '&.dinner': {
                borderLeft: '6px solid #447CFF',
                '& .MuiDivider-root': {
                    borderColor: '#447CFF',
                }
            },
            '&.allday, &.snacks': {
                borderLeft: '6px solid #5C5C5C',
                '& .MuiDivider-root': {
                    borderColor: '#5C5C5C',
                }
            },
            '& .time': {
                display: 'flex',
                alignItems: 'center',
                gap: '1px',
                background: '#F5F6F8',
                padding: '5px',
                borderRadius: '10px',
            },
            '& .MuiTable-root th, .MuiTable-root td': {
                padding: '5px',
                fontSize: '12px',
                textAlign: 'left',
            },
            '& .menu': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                '& .MuiTypography-root': {
                    fontSize: '12px',
                    fontWeight: 'bold',
                },
                '& .MuiDivider-root': {
                    flex: 1,
                }

            }
        }
    }));

    const SlotStyled = styled(Box)(() => ({
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        background: '#FFF6E8',
        alignItems: 'center',
        padding: '0 5px',
        borderLeft: '4px solid #FDAA2E',
        '&.breakfast': {
            borderLeft: '4px solid #FDAA2E',
            background: '#FFF6E8',
        },
        '&.lunch': {
            borderLeft: '4px solid #2AC3A7',
            background: '#F2FFFA',
        },
        '&.dinner': {
            borderLeft: '4px solid #447CFF',
            background: '#FAFBFF',
        },
        '&.allday, &.snacks': {
            borderLeft: '4px solid #5C5C5C',
            background: '#F9F9F9',
        },
        '& .MuiTypography-body1': {
            fontSize: '11px',
            color: '#000',
            fontWeight: 'bold',
        },
        '& .MuiTypography-caption': {
            fontSize: '11px',
            color: '#6D6D6D',
        },
    }));

    const ModalBoxStyled = styled(Box)(({theme}) => ({
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      background: '#fff',
      borderRadius: '5px',
      padding: '20px',
      maxHeight: '120vh',
      overflow: 'visible',
      [theme.breakpoints.down('md')]: {
          width: '90%',
       },
      '& .box': {
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
      },
      '& .items': {
          display: 'flex',
          gap: '10px',
      },
      '& .container': {
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          marginBottom: '10px',
          flex: 1,
      },
      '& .MuiInputBase-root': {
          flex: 1,
          width: 'auto',
      }
    }))

    const theme = useTheme();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

    const calendarComponentRef = useRef();

    const [showOverviewDashboard, setShowOverviewDashboard] = useState(true);
    const [loading, setLoading] = useState(true);
    const [isScheduleDrawerOpen, setIsScheduleDrawerOpen] = useState(false);
    const [selectedMealDate, setSelectedMealDate] = useState(new Date()); 
    const [selectedMealId, setSelectedMealId] = useState(null);
    const [selectedMealType, setSelectedMealType] = useState(null);
    const [selectedMealPreference, setSelectedMealPreference] = useState(null);
    const [drawerType, setDrawerType] = useState("CREATE");

    const [range, setRange] = useState({
      'start_date' : null,
      'end_date' : null,
    });
  
    const mealSchedule = useSelector(state => getClientMealSchedule(state)); 
    const clientList = useSelector(state => getClientList(state));
    const mealOptions = useSelector(state => getClientMealOptions(state));

    const appointments = []; 
    const [data, setData] = useState(appointments);
    const [open, setOpen] = useState(false);
    const [selectedData, setSelectedData] = useState([])
    const [selectedEvent, setSelectedEvent] = useState(null); 
    const [searchParams, setSearchParams] = useSearchParams();
    const _startDate = searchParams.get("start_date");
    const _endDate = searchParams.get("end_date");
    const userId = searchParams.get("client_id");

    const tableRef = useRef();

    // const [userId, setUserId] = useState(null);

    const headerToolbar = {
      left:'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    }

    const mobileHeaderToolbar = {
      left: 'prev,next today',
      right: 'title'
    }

    useEffect(() => { 
      if(userId){ 
        setShowOverviewDashboard(false);
        setLoading(true);
        fetchClientDetails(dispatch, cookies, userId);
        fetchClientSubscription(dispatch, cookies, userId); 
        fetchMealPlanSchedule(dispatch, cookies, {'user_id': userId});
        fetchMealPlanDishOptions(dispatch, cookies, userId); 
        // fetchPartnerDish(dispatch, cookies);
        fetchPartnerMeals(dispatch, cookies); 
      }else{
        setShowOverviewDashboard(true);
      }
    },[userId]);

    useEffect(() => { 

      if(userId !== null && userId !== undefined && userId !== ''){ 
        // setUserId(userId);

        setShowOverviewDashboard(false);
        setLoading(true);

        fetchClientDetails(dispatch, cookies, userId);
        fetchClientSubscription(dispatch, cookies, userId); 
        fetchMealPlanDishOptions(dispatch, cookies, userId); 
        fetchMealPlanSchedule(dispatch, cookies, {'user_id': userId});
        // fetchPartnerDish(dispatch, cookies);
        fetchPartnerMeals(dispatch, cookies); 
      }else{
        setShowOverviewDashboard(true);
      }
    },[]);

    useEffect(() => { 
      if(mealOptions != null ){
        setLoading(false);
      }
    },[mealOptions]); 

    useEffect(() => { 
      if(mealSchedule != null){ 
        mealSchedule.forEach(element => { 
          appointments.push({
            id: element.id,
            extendedProps: {
              ...element
            },
            title: element.meal_type.display_name,
            date: new Date(element.date),
          })
        });
        setData(appointments);
      } 
    },[mealSchedule]);  
 
    useEffect(() =>{ 
        if(range.start_date != null && range.end_date != null){ 
          fetchMealPlanSchedule(dispatch, cookies, {
              user_id: userId,
              "start_date" : formatDate(range.start_date, "yyyy-MM-DD"),
              "end_date" : formatDate(range.end_date, "yyyy-MM-DD"), 
            });
        }
    },[range])

    const handleDateClick = (arg) => { 
      const data = mealSchedule.filter((ele) => ele.date === arg.dateStr);
      setSelectedData(data);

      const s = document.querySelectorAll('[data-date]');
      s.forEach((e) => {
          const x = e.getAttribute('data-date');
          if (x === arg.dateStr){
              e.classList.add('fc-selected-date');
          }else{
              e.classList.remove('fc-selected-date');
          }
      });

      const calendarApi = calendarComponentRef.current.getApi()
      calendarApi.gotoDate(arg.dateStr);

    }

    const handleEventClick = (arg) => {
      setDrawerType("EDIT");
      setSelectedMealId(arg.event._def.extendedProps.meal?.meal_id); 
      setSelectedMealType(arg.event._def.extendedProps.meal_type);
      setSelectedMealPreference(arg.event._def.extendedProps.preference);
      setSelectedMealDate(arg.event._instance.range.start);
      setSelectedEvent(arg.event._def.publicId);
      setIsScheduleDrawerOpen(true);
      setOpen(true); 
    }

    const handleEditClick = (meal) => {
      setDrawerType("EDIT");

      console.log(meal);
      console.log(meal.meal_type);

      const mealIds = [];
      meal?.meal.forEach(element => {
        mealIds.push(element.meal_id);
      });

      console.log(mealIds);

      setSelectedMealId(mealIds);
      setSelectedMealType(meal.meal_type.id);
      setSelectedMealPreference(meal.preference);
      setSelectedMealDate(formatDate(meal.date));
      // setSelectedEvent(arg.event._def.publicId);

      const calendarApi = calendarComponentRef.current.getApi()
      calendarApi.gotoDate(formatDate(meal.date));

      setIsScheduleDrawerOpen(true);
      setOpen(true);
    }

    const handleDrop = (arg) => { // bind with an arrow function

      try{
        updateMealPlanSchedule(dispatch, cookies, {
          "schedule_id" : arg.event._def.extendedProps.id,
          'date': formatDate(arg.event._instance.range.start, "yyyy-MM-DD"), 
          'user_id': userId,
          'dishes': [arg.event._def.extendedProps.dish_id],
          'meal_type': arg.event._def.extendedProps.meal_type,
          'preference':  arg.event._def.extendedProps.preference,
        }, false);   
      }catch (e){
        console.log(e);
      }

      fetchMealPlanSchedule(dispatch, cookies, {
        user_id: userId,
        "start_date" : formatDate(range.start_date, "yyyy-MM-DD"),
        "end_date" : formatDate(range.end_date, "yyyy-MM-DD"), 
      });
    }

    const handleEventReceive = (arg) => { // bind with an arrow function
      console.log(arg)
    }

    const handleDateRangeChange  = (arg) => { // bind with an arrow function
      setRange({
        'start_date' : arg.start,
        'end_date' :  arg.end
      });  
    }

    const renderEventContent = (eventInfo) => {
      const mealType = eventInfo?.event?.title.replace(' ', '').trim().toLowerCase();

      return <SlotStyled className={mealType}>
          <i>{eventInfo.event.extendedProps.meal_type_name}</i>
          <br />
          <i>{eventInfo.event.title}</i>
        </SlotStyled>;
    }


    const toggleScheduleModal = (anchor , open ) => (event ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event ).key === 'Tab' ||
            (event ).key === 'Shift')
        ) {
            return;
        }    
        setIsScheduleDrawerOpen(open); 
    };

    const handleClose = () => {
      setOpen(false);
      setIsScheduleDrawerOpen(false); 
    }

    const handleScheduleDelete = (mealScheduleId) => {

      removeScheduledUserMeal(dispatch, cookies,  mealScheduleId );

      fetchMealPlanSchedule(dispatch, cookies, {
          user_id: userId,
          "start_date" : formatDate(range.start_date, "yyyy-MM-DD"),
          "end_date" : formatDate(range.end_date, "yyyy-MM-DD"), 
      });
    }

    
    function Row(props) {
      const { row } = props;

      const id = row.meal_id;
      const displayName = row.display_name;

      return (
        <>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell component="th" scope="row">
                  {id}
              </TableCell>
              <TableCell align="center">
                  <Typography variant="body1" sx={{cursor:'pointer'}}>{displayName}</Typography>
              </TableCell>
              <TableCell align="center" onClick={() => handleScheduleDelete(row.meal_schedule_id)}>
                <Iconify icon={'eva:close-outline'} sx={{fontSize:18, cursor:'pointer'}} />
              </TableCell>
          </TableRow>
        </>
      );
  }

    return (
      <Page title="Schedule Meal">
          {
            showOverviewDashboard && 
            <Container  maxWidth="xl" style={{marginTop:10, padding:1 }}>
              <Grid container spacing={3}> 

                <Stack direction="row" alignItems="center" justifyContent="space-between" margin={2}>
                    <Typography variant="h7" gutterBottom>
                        Select an user to view their meal calendar : 
                    </Typography>
                </Stack>

                <Grid container spacing={3} > 
                    {
                        clientList && clientList.map((e) => <Grid key={e.id} item   xs={12} sm={12} md={4} xl={3} lg={3} onClick={() => {
                            navigate(`/coach/schedule_meal?client_id=${e.id}`)
                        }} sx={{cursor: 'pointer'}}>
                            <UserListCard clientDetails={e} />
                        </Grid>)
                    }
                </Grid>
              </Grid>
            </Container>          
          }

          {
            !showOverviewDashboard && loading &&
            <Container  maxWidth="xl" style={{marginTop:10 }}>
              <Loader height={50} />
            </Container>
          }

          {
            !showOverviewDashboard && !loading &&
              <Container maxWidth="xl" className="meal-calendar" sx={{marginTop:4 }}>

                  <StackStyled direction="row" spacing={2}>

                      <FullCalendar
                        // defaultView="dayGridMonth"
                        rerenderDelay={1}
                        eventDurationEditable={false}
                        editable 
                        droppable 
                        selectable 
                        headerToolbar = {isMobile ? mobileHeaderToolbar : headerToolbar}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        initialView= {isMobile ? 'timeGridDay' : 'dayGridMonth'}
                        ref={calendarComponentRef}
                        events={data}
                        eventDrop={handleDrop}
                        drop={handleDrop}
                        eventReceive={handleEventReceive}
                        // eventClick={handleEventClick}
                        dateClick={handleDateClick}
                        eventContent={renderEventContent}
                        // datesSet= {handleDateRangeChange}
                        // height={'60vh'}
                        
                      />

                      <BoxStyled>

                        {
                          <Button variant='contained' className='event' onClick={() => {
                            // handleOpen(formatDate(Date()))
                            setSelectedMealDate(formatDate(Date()));
                            setDrawerType("CREATE");
                            setIsScheduleDrawerOpen(true);
                            setOpen(true); 
                          }}>Add Schedule</Button>
                        }

                        {selectedData?.map((meal) => {
                            const mealType = meal?.meal_type.display_name.replace(' ', '').trim().toLowerCase();
                            // const startTime = meal?.service_start_time?.slice(0, 5);
                            // const endTime = meal?.service_end_time?.slice(0, 5);
                            return (
                                <Card className={mealType} key={meal?.service_id}>
                                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                                        <Typography variant='h6'>{meal?.meal_type.display_name}</Typography> 
                                        <Typography variant='h6'>{formatDate(new Date(meal?.date), "ddd, DD MMM yyyy")}</Typography> 
                                        {/* <IconButton onClick={() => handleEdit(meal)}><Iconify icon='basil:edit-solid' /></IconButton> */}
                                        {/* <Typography variant='caption' className='time'> <Iconify icon="ion:time-outline" /> {startTime} - {endTime}</Typography> */}
                                    </Box>
                                    <Box className='menu'>
                                        <Typography sx={{ pt: 1, pb: 1, mr: 1 }}>Menu Options</Typography>
                                        <Divider sx={{color: '#000eee'}} />
                                        <Typography sx={{ pt: 1, pb: 1, ml: 1, cursor:'pointer' }} onClick={() => {
                                          handleEditClick(meal)
                                        }}>
                                          <Iconify icon={'eva:edit-outline'} sx={{fontSize:18}} />
                                        
                                        </Typography>
                                    </Box>

                                    <Table ref={tableRef} aria-label="collapsible table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">#ID</TableCell>
                                                <TableCell align="center">Meal Name</TableCell>
                                                <TableCell align="center" />
                                                {/* <TableCell align="center">Total Qty</TableCell>
                                                <TableCell align="center">Consumed</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {
                                              meal?.meal.map((menuOption) => {
                                                    return <Row key={menuOption.meal_id} row={menuOption} />
                                                })
                                            }
                                        </TableBody>

                                    </Table>

                                </Card>

                            )
                        })}
                      </BoxStyled>
                  </StackStyled>
                      
                    <Container>
                      <Dialog
                        fullWidth="lg" 
                        maxWidth="lg"
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                        sx: {
                            minHeight: '90vh',
                            maxHeight: '90vh'
                        }
                        }}
                        // sx={{height:'90vh'}}
                        // sx={{height:'80vh', width:'80%', margin:'10%', overflowY:'auto' }}
                        >

                          <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <Grid container flexDirection={'column'} justifyContent={'flex-start'} padding={1}>
                                    <Grid container item flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Grid item>
                                            <Typography variant="h3">
                                              {
                                                "Meal Schedule"
                                                // formatDate(selectedMealDate,"DD MMM yyyy" )
                                                // `${selectedMealDate.getDate()} ${selectedMealDate.getMonth()} ${selectedMealDate.getFullYear()}`
                                              
                                                    // selectedMeal 
                                                    // ? selectedMeal.display_name
                                                    // : 'Add new meal'
                                                }
                                            </Typography>
                                        </Grid>
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() => setOpen(false)}
                                            aria-label="close"
                                        >
                                            <Iconify icon="eva:close-circle-outline" sx={{height:40, width:40, cursor:'pointer'}}/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                          </AppBar>
                          <DialogContent>

                            <Grid padding={2} sx={{minHeight: 400}}>

                              {
                                drawerType === "CREATE"
                                ? <ScheduleMealForm 
                                    userId={userId} 
                                    date={formatDate(selectedMealDate,"yyyy-MM-DD" )} 
                                    handleClose={handleClose} 
                                    visibleRange={range}                              
                                  />
                                : null
                              }
                              {
                                drawerType === "EDIT"
                                ? <EditMealForm
                                    userId={userId}
                                    date={formatDate(selectedMealDate,"yyyy-MM-DD" )} 
                                    handleClose={handleClose} 
                                    visibleRange={range} 
                                    mealScheduleId={selectedEvent}
                                    selectedMealId={selectedMealId}
                                    selectedMealType={selectedMealType}
                                    selectedMealPreference={selectedMealPreference}
                                  />
                                : null                          
                              }
                            </Grid>
                          </DialogContent>
                        </Dialog>
                    </Container>

              </Container>
          }
        
      </Page>
    );
}