import { useSelector, useDispatch } from 'react-redux';  
import { Form, FormikProvider, useFormik } from 'formik';
import { useState, useEffect  } from 'react';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// material
import { Grid, Button, Stack, Typography, TextField, Box, InputAdornment, DialogContent, Dialog, FormControl, InputLabel, Select, OutlinedInput, Chip, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components 
import { getClientMealOptions, getClientSubscriptionPlanDetails } from '../../../store/coach/reducer'; 
import { addActivity, fetchActivity } from '../../../data/network/activity/api'; 
import { fetchMealPlanSchedule, removeScheduledUserMeal, addMealPlanSchedule, updateMealPlanSchedule } from '../../../data/network/client/api';
import { getActivityList } from '../../../store/activity/reducer';
import { formatDate } from '../../../utils/date';
import { calendarMealType, mealPreferences } from '../../../constants/app_constants';
import { getPartnerMealList } from '../../../store/user/reducer';
import Iconify from '../../../components/Iconify';

export default function EditMealForm( {userId, date, selectedMealId, selectedMealType, handleClose, visibleRange, mealScheduleId, selectedMealPreference} ) {
    const theme = useTheme();
 
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const dispatch = useDispatch(); 
    const [mealDate, setMealDate] = useState(new Date(date)); 
    // const mealOptions = useSelector(state => getClientMealOptions(state));
    // const mealOptions = mealOptionsData?.data
    const mealOptionsData = useSelector(state => getPartnerMealList(state));
    const mealOptions = mealOptionsData?.data


    const [selectedMeal, setSelectedMeal] = useState(selectedMealId);
    const subscribedPlanDetails = useSelector(state => getClientSubscriptionPlanDetails(state));

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
    };

    console.log(selectedMealType);
    console.log(selectedMealId);
    console.log(date);
    console.log(userId);

    const handleScheduleMeal = () => {

        updateMealPlanSchedule(dispatch, cookies, {
            'preference': formik.values.meal_preference,
            'schedule_id': mealScheduleId,
            'meal_ids': selectedMeal,
            'meal_type': formik.values.meal_type,
            'date': formatDate(mealDate, "yyyy-MM-DD"),
            'user_id': userId,
        }, false);

        fetchMealPlanSchedule(dispatch, cookies, {
            user_id: userId,
            "start_date" : formatDate(visibleRange.start_date, "yyyy-MM-DD"),
            "end_date" : formatDate(visibleRange.end_date, "yyyy-MM-DD"), 
        });

        handleClose();
    };
 

    const handleRemoveScheduleMeal = () => {
        removeScheduledUserMeal(dispatch, cookies,  mealScheduleId );

        fetchMealPlanSchedule(dispatch, cookies, {
            user_id: userId,
            "start_date" : formatDate(visibleRange.start_date, "yyyy-MM-DD"),
            "end_date" : formatDate(visibleRange.end_date, "yyyy-MM-DD"), 
        });

        handleClose();
    };

    const handleMealDateChange = (newValue) => {
        setMealDate(newValue);
    }

    const ActivitySchema = Yup.object().shape({
        activity_meta_id: Yup.string().required('Activity is required'),
    });
   
    const formik = useFormik({
        initialValues: {
            meal_id:  selectedMealId ,
            meal_preference: selectedMealPreference || subscribedPlanDetails.meal_preference === 1 ? 'vegetarian' : 'non-vegetarian',
            meal_type: selectedMealType
        },
        validationSchema: ActivitySchema,
        onSubmit: () => {
  
        },
    });

    const handleChange = (event) => {
        const {
          target: { value },
        } = event; 
  
        setSelectedMeal(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
   
        
      };
    
    const handleDelete = (mealId) => {

        const _selectedMeal = selectedMeal.filter(e => e!==mealId);
        setSelectedMeal(_selectedMeal);
    }

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (  
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid item container direction="column" justifyContent="center">                                    
                    <Box margin={2} />
                    <Stack direction={'row'} spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                label="Meal Date"
                                inputFormat="dd MMM yyyy"
                                value={mealDate}
                                onChange={handleMealDateChange}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </LocalizationProvider>
                    </Stack>
                    <Box margin={2} />
                    <Stack direction={'row'} spacing={2}>
                        {/* <TextField
                            fullWidth 
                            select
                            disabled
                            label="Meal Preference"
                            {...getFieldProps('meal_preference')}
                            error={Boolean(touched.meal_preference && errors.meal_preference)}
                            helperText={touched.meal_preference && errors.meal_preference}
                            SelectProps={{
                                native: true,
                            }}
                            variant="filled" >
                            {mealPreferences != null && mealPreferences.map((option) => (
                                <option key={option.text} value={option.text}>
                                    {option.display_name}
                                </option>
                            ))}
                        </TextField> */}
                        <TextField
                            fullWidth 
                            select
                            defaultValue={selectedMealType}
                            label="Select Meal Type"
                            {...getFieldProps('meal_type')}
                            error={Boolean(touched.meal_type && errors.meal_type)}
                            helperText={touched.meal_type && errors.meal_type}
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {calendarMealType != null && calendarMealType.map((option) => (
                                subscribedPlanDetails && subscribedPlanDetails.meal_types
                                && subscribedPlanDetails.meal_types.includes(option.id) &&
                                <option key={option.id} value={option.id}>
                                    {option.text}
                                </option>
                            ))}
                        </TextField> 
                    </Stack>
                    <Box margin={2} />
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <FormControl sx={{ width: '100%', marginTop:2 }}>
                            <InputLabel id="select-meals-label">Meals</InputLabel>
                            <Select
                                labelId="select-meals-label"
                                id="select-meals"
                                multiple
                                value={selectedMeal}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-meals" label="Meals" />}
                                renderValue={(selected) => {
                                    return (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}> 
                                    { 
                                        selected && selected.map((value) =>   
                                            mealOptions && mealOptions.map((element, val) => {
                                                return ( 
                                            (element.meal_id === value) 
                                            ? <Chip 
                                                key={element.meal_id} 
                                                label={element.display_name} 
                                                onMouseDown={(event) => {
                                                    event.stopPropagation();
                                                }}
                                               onDelete={() => handleDelete(element.meal_id)} /> 
                                            : null
                                        )})
                                        ) 
                                    }
                                    </Box>
                                )}
                            }
                                MenuProps={MenuProps}
                            >
                            {mealOptions && mealOptions.map((e) => (
                                <MenuItem
                                    key={e.meal_id}
                                    value={e.meal_id} 
                                >
                                    {e.display_name}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Box margin={2} />
                    
                    {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                label="Meal Date"
                                inputFormat="dd MMM yyyy"
                                value={mealDate}
                                onChange={handleMealDateChange}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </LocalizationProvider>
                    </Stack> */}
                    <Box margin={2} />
                    <Box margin={2} />
                </Grid>

                <Grid item container direction="row" justifyContent="center">
                    {/* <Button variant='outlined' onClick={() => {
                        handleRemoveScheduleMeal();
                    }}>
                        Remove
                    </Button> */}
                    <Box margin={2} />
                    <Button variant='contained' onClick={() => {
                        handleScheduleMeal();
                    }}>
                        Save
                    </Button>                            
                </Grid>
            </Form>
        </FormikProvider>
    );
}
