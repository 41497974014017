import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid,Card,Stack, Button,  Container, Typography, Box } from '@mui/material';
import { useNavigate , Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux'; 

// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { formatDate } from '../../utils/date';
import CorporatePendingInvoice from '../../sections/corporate/profile/PendingInvoices';
import CorporateTransaction from '../../sections/corporate/profile/Transactions';
import { getActiveCorporateClient } from '../../store/user/reducer';
// sections

// ----------------------------------------------------------------------

export default function CorporateProfile() {
  const theme = useTheme();

  const navigate = useNavigate();
  const activeCorporateClient = useSelector(state => getActiveCorporateClient(state));

  const getServiceLabel = (services) => {
    const serviceLabel = [ ];

    // if(services.includes(1)){ 
    //     serviceLabel.push(MEAL_TYPE.BREAKFAST);
    // }

    // if(services.includes(2)){ 
    //     serviceLabel.push(MEAL_TYPE.LUNCH);
    // }

    // if(services.includes(3)){ 
    //     serviceLabel.push(MEAL_TYPE.SNACKS);
    // }

    // if(services.includes(4)){ 
    //     serviceLabel.push(MEAL_TYPE.DINNER);
    // }

    return serviceLabel.toString();
}

  return (
    <Page title="Profile">
      <Container maxWidth="xl">
        <Box margin={4} />

        <Card sx={{padding: 4}} className="glass-box">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    My Profile
                </Typography>
                {/* <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:edit-outline" />}>
                    Edit
                </Button> */}
            </Stack>
            <Grid className="corporate-profile-container"  container padding ={0} >
                <Grid className="corporate-profile-item" container justifyContent="space-between" alignItems="center" direction="row" margin={1} >
                    <Typography paddingLeft={4}>Name</Typography> 
                    <Typography paddingRight={4}> {activeCorporateClient != null ? activeCorporateClient.name : ""} </Typography> 
                </Grid>
                {
                    activeCorporateClient != null && activeCorporateClient.corporate != null
                    && activeCorporateClient.corporate.agreement_doc != null &&
                    <Grid className="corporate-profile-item" container justifyContent="space-between" alignItems="center" direction="row" margin={1} >
                        <Typography paddingLeft={4} style={{width:'30%'}}>Agreement Type</Typography> 
                        <Grid style={{paddingRight:32,width:'50%'}} className="corporate-profile-item" container justifyContent="end" alignItems="center" direction="row" margin={0} >
                            <a className='button' href= {activeCorporateClient.corporate.agreement_doc} download target="_blank" rel="noreferrer" >
                                Download 
                            </a>  
                             
                            {/* <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:download-outline" />}>
                                Download 
                            </Button>  */}
                        </Grid>
                    </Grid>
                }
                <Grid className="corporate-profile-item"  container justifyContent="space-between" alignItems="center" direction="row" margin={1}  >
                    <Typography paddingLeft={4}>Agreement Start Date</Typography> 
                    <Typography paddingRight={4}> {activeCorporateClient != null ? formatDate(activeCorporateClient.agreement_started, "DD MMM YYYY") : ""} </Typography> 
                    {/* <Typography paddingRight={4}>{activeCorporateClient != null ? formatDate(null, "DD MMM YYYY") : "" }</Typography>  */}
                </Grid>
                <Grid className="corporate-profile-item"  container justifyContent="space-between" alignItems="center" direction="row" margin={1} >
                    <Typography paddingLeft={4}>Agreement End Date</Typography> 
                    <Typography paddingRight={4}> {(activeCorporateClient != null && activeCorporateClient.agreement_expires != null) ? formatDate(activeCorporateClient.agreement_expires, "DD MMM YYYY") : ""} </Typography> 
                    {/* <Typography paddingRight={4}> {activeCorporateClient != null ? activeCorporateClient.agreement_expires : ""} </Typography>  */}
                    {/* <Typography paddingRight={4}>{null != null ? formatDate(null, "DD MMM YYYY") : "" }</Typography>  */}
                </Grid>
                <Grid className="corporate-profile-item"  container justifyContent="space-between" alignItems="center" direction="row" margin={1} >
                    <Typography paddingLeft={4}>Address</Typography> 
                    <Typography paddingRight={4}> {`${activeCorporateClient != null && activeCorporateClient.address1 !== null ? activeCorporateClient.address1: " "} 
                        ${activeCorporateClient != null && activeCorporateClient.address2!== null ? activeCorporateClient.address2: " "} 
                        ${activeCorporateClient != null && activeCorporateClient.city!== null ? activeCorporateClient.city: " "}` } </Typography> 
                </Grid> 
            </Grid>
        </Card>

        {/* <Card sx={{padding: 4, marginTop: 4}} >
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    Pending Invoices
                </Typography>
                <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:flash-outline" />}>
                    Settle Now
                </Button>
            </Stack>
            <CorporatePendingInvoice />
        </Card>

        <Card sx={{padding: 4, marginTop: 4}}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    My Transactions
                </Typography>
                <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:download-outline" />}>
                    Export
                </Button>
            </Stack>
            <CorporateTransaction />
        </Card> */}
      </Container>
    </Page>
  );
}
