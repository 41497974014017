
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Card, Container } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {CardBack, CardAvatar, CardTitle, CardBio, CardSocialIcons, CardImg} from './components';
import '../../../assets/css/FlipperCard.css';
import { getClientSelectedPlan, setClientOrderType, setClientPlanSelection } from '../../../store/coach/reducer';
import { ORDER_TYPE, PLAN_SELECTION_STATUS } from '../../../constants/app_constants';
// Styles
const bodyStyles = {
    background: "-webkit-gradient(linear, left top, right top, color-stop(0%, transparent), color-stop(50%,red), color-stop(100%,transparent))",
    // background: "-webkit-linear-gradient(left, transparent 0%,red 50%,transparent 100%)", /* Chrome10+, Safari5.1+ */
    // background: "-moz-linear-gradient(left, transparent 0%,red 50%,transparent 100%)",    /* FF3.6+ */
    // background: "linear-gradient(to bottom right,#ff5555 40%,#5555ff 100%)",
    width: "100%",
    height: "100vh",
    overflowX: "hidden"
}
const headerStyles = {
textAlign: "center",
color:"#fff",
}
const cardContainerStyles = {
width: "150px",
height: "50px",
background: "#fff",
borderRadius: 15,
margin:'10px',
justifyContent: 'center',
display: 'flex',
alignItems: 'center',
cursor:'pointer'
// boxShadow: "1px 1px 15px #eee"
};



SubscriptionPlanCard.propTypes = {
    subscriptionPlan: PropTypes.object.isRequired
};


export default function SubscriptionPlanCard(
    {subscriptionPlan, selected}
) {
 
    const dispatch = useDispatch();
    const [selectionClass1, setSelectionClass] = useState(null);
    useEffect(() => {
        if(selected){
            setSelectionClass("cardFront cardContainer selected");
        }else{
            setSelectionClass("cardFront cardContainer not-selected");
        } 

    },[subscriptionPlan]);

    return (
        // <div className="flipperContainer">
        //   <div className="flipper"> 
             <Card style={cardContainerStyles} className={selectionClass1} onClick={() => {
                dispatch( setClientOrderType( { 'type' : ORDER_TYPE.SUBSCRIPTION }));
                dispatch( setClientPlanSelection( { 'planSelectionStatus' : PLAN_SELECTION_STATUS.SELECTED, 'selectedPlan': subscriptionPlan}))
             }}>
                 {/* <CardImg imgSrc={subscriptionPlan.image_url[0]} /> */}
                 {/* <CardAvatar avatarSrc={subscriptionPlan.image_url[0]} /> */}
                 <CardTitle targetId={subscriptionPlan.display_name} title={subscriptionPlan.display_name} subTitle={subscriptionPlan.display_name} />
                 {/* <CardBio bio={subscriptionPlan.short_description} /> */}
                 {/* <CardSocialIcons /> */}
             </Card>
            
    //         <CardBack imgSrc={subscriptionPlan.image_url[0]} />

    //        </div>
    //   </div> 
    );
};
