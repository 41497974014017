import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import axios from '../../../utils/axios-configure';
import { API_DELETE_STORE_EXPENSE, API_GET_STORE_EXPENSE, API_GET_STORE_EXPENSE_STATS, API_UPDATE_STORE_EXPENSE } from '../endpoints';
import { getAccessTokenFromCookie } from "../session/api";
import { SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { STORE_EXPENSE_DELETE, STORE_EXPENSE_UPDATE } from '../../../constants/message_constants';
import { setStoreExpense, setStoreExpenseStats } from '../../../store/partner_store/reducer';


export const fetchStoreExpense = (dispatch, cookies, storeId,  startDate=null, endDate=null, page = 1, count=10, filterBy = { key: '', value: '' }) => {
    dispatch(setLoadingStatus({ status: true }));
    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    if (accessToken != null) {
  
      let url = `${API_GET_STORE_EXPENSE}?store_id=${storeId}&page=${page}&count=${count}&filter=${filterBy?.key}&term=${filterBy?.value}`
  
      if (startDate != null) {
        url += `&startDate=${startDate}`;
      }
  
      if (endDate != null) {
        url += `&end_date=${endDate}`;
      }
  
      axios({
        method: "get",
        url,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
      })
        .then((response) => {
          // handle success
          if (response.status === 200) {
            dispatch(setStoreExpense({ data: response.data }));
          } else {
            //
          }
          dispatch(setLoadingStatus({ status: false }));
          return true;
        })
        .catch((err) => {
          dispatch(setLoadingStatus({ status: false }));
        });
    } else {
      dispatch(setLoadingStatus({ status: false }));
      //
    }
  }
  
  export const updateStoreExpense = (dispatch, cookies,storeId, data) => {
    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    dispatch(setLoadingStatus({ status: true }));
    if (accessToken != null) {
      data.store_id = storeId;
      axios({
        method: "post",
        url: `${API_UPDATE_STORE_EXPENSE}`,
        data,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
      }).then((response) => {
        // handle success
        if (response.status === 200) {
          dispatch(setSnackbarMessage({ 'message': STORE_EXPENSE_UPDATE.SUCCESS, 'severity': SNACKBAR_SEVERITY.SUCCESS }));
          dispatch(setSnackbarVisibilityStatus({ 'status': true }));
          fetchStoreExpense(dispatch, cookies, storeId)
        } else {
          dispatch(setSnackbarMessage({ 'message': STORE_EXPENSE_UPDATE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
          dispatch(setSnackbarVisibilityStatus({ 'status': true }));
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
      })
      .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
        dispatch(setSnackbarMessage({ 'message': STORE_EXPENSE_UPDATE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
        dispatch(setSnackbarVisibilityStatus({ 'status': true }));
      });
    }
    dispatch(setLoadingStatus({ status: false }));
  }
  
  
  export const deleteStoreExpense = (dispatch, cookies, storeId, data) => {
    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    dispatch(setLoadingStatus({ status: true }));
    if (accessToken != null) {

      data.store_id = storeId;

      axios({
        method: "delete",
        url: `${API_DELETE_STORE_EXPENSE}`,
        data,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
      })
        .then((response) => {
          // handle success
          if (response.status === 200) {
  
            dispatch(setSnackbarMessage({ 'message': STORE_EXPENSE_DELETE.SUCCESS, 'severity': SNACKBAR_SEVERITY.SUCCESS }));
            dispatch(setSnackbarVisibilityStatus({ 'status': true }));
            fetchStoreExpense(dispatch, cookies, storeId);
          } else {
            dispatch(setSnackbarMessage({ 'message': STORE_EXPENSE_DELETE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
            dispatch(setSnackbarVisibilityStatus({ 'status': true }));
          }
          dispatch(setLoadingStatus({ status: false }));
          return true;
        })
        .catch((err) => {
          dispatch(setLoadingStatus({ status: false }));
          dispatch(setSnackbarMessage({ 'message': STORE_EXPENSE_DELETE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
          dispatch(setSnackbarVisibilityStatus({ 'status': true }));
        });
    }
    dispatch(setLoadingStatus({ status: false }));
  }
  

export const fetchStoreExpenseStats = (dispatch, cookies, storeId,  startDate=null, endDate=null, filterBy = { key: '', value: '' }) => {
  dispatch(setLoadingStatus({ status: true }));
  const accessToken = getAccessTokenFromCookie(dispatch, cookies);
  if (accessToken != null) {

    let url = `${API_GET_STORE_EXPENSE_STATS}?store_id=${storeId}`

    if (startDate != null) {
      url += `&startDate=${startDate}`;
    }

    if (endDate != null) {
      url += `&end_date=${endDate}`;
    }

    if (filterBy != null) {
      if (filterBy.key != null && filterBy.key !== '' && filterBy.key !== undefined ) {
        url += `&filter=${filterBy.key}`

        if (filterBy.value != null && filterBy.value !== '' && filterBy.value !== undefined) {
          url += `&term=${filterBy.value}`
        }
      }
    }
    
    axios({
      method: "get",
      url,
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
    })
      .then((response) => {
        // handle success
        if (response.status === 200) {
          dispatch(setStoreExpenseStats({ data: response.data }));
        } else {
          //
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
      })
      .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
      });
  } else {
    dispatch(setLoadingStatus({ status: false }));
    //
  }
}
