import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PropTypes } from 'prop-types';
// material
import { Grid, Button, Box, Stack, Typography, Card, IconButton, Avatar, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Iconify from '../../../components/Iconify';
import { getActiveClient } from '../../../store/coach/reducer';
import { getAge } from '../../../utils/date';
// mocks_
import account from '../../../_mock/account'; 
import { getSelectedDish } from '../../../store/dish/reducer';
import DishRecipeForm from './DishRecipeForm';
// ----------------------------------------------------------------------

DishDetail.propTypes = { 
    clientDetails: PropTypes.object.isRequired,
};

export default function DishDetail() {
    const theme = useTheme();

    const dish = useSelector(state => getSelectedDish(state));

    const navigate = useNavigate();

    return (
        <Grid >
            <Grid>
                <Button startIcon={<Iconify icon="bytesize:arrow-left" />} onClick={() => {
                    navigate('/coach/dish');
                }}>
                    Go Back
                </Button>
            </Grid>

            <Container sx={{padding:2, borderRadius: 2}}>
                
                <Grid container direction="column" justifyContent="start"> 
                    <Grid item container direction="row" justifyContent="start">
                        <IconButton >
                            {/* <Avatar src={account.photoURL} alt="photoURL" style={{width:120, height:120}} /> */}
                        </IconButton>
                    </Grid>
                    <Grid item container direction="row" justifyContent="start">
                        <Typography  gutterBottom>
                            {dish && `${dish.display_name}`}
                        </Typography>
                    </Grid>
                    <Grid item container direction="row" justifyContent="start">
                        <Typography  gutterBottom>
                            {dish && `${dish.description}`}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" justifyContent="start">                                
                        <Typography gutterBottom sx={{fontSize: '0.8rem'}}>
                            {
                                dish && `₹ ${dish.mark_price}`
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <Box margin={2} />
            <Container style={{padding:10}}>
                <Typography variant="h6">Dish Recipe</Typography>
                <Box margin={2} />
                <DishRecipeForm dish={dish} />
            </Container>
        </Grid>
    );
}
