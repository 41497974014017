
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Stack, Typography, Box, TextField, Select, MenuItem, Button, styled, DialogTitle, Dialog } from '@mui/material';
import { useCookies } from 'react-cookie';
import { formatDate } from '../../../utils/date';
import { getActiveStoreId } from '../../../store/partner_store/reducer';
import { STOCK_UNITS } from '../../../constants/app_constants';
import { updateStoreMenu } from '../../../data/network/store/menu';

const TextFieldStyle = styled(TextField)(() => ({
    '& .MuiInputBase-input': {
        padding: '8px 14px',
    }
}));

const SelectStyle = styled(Select)(() => ({
    '& .MuiSelect-select': {
        padding: '8px 14px',
    }
}));


export const StoreMenuDetail = ({ data, visible, setOpen, editable=true, create=true }) => {
    const [editEnable, setEditEnable] = useState(false);
    const [menuData, setMenuData] = useState({});
    const [date, setDate] = useState(null);

    const dispatch = useDispatch();
    const [cookies] = useCookies(['mobile', 'session', 'access_token']);

    const storeId = useSelector((state) => getActiveStoreId(state));
    
    useEffect(() => {
        setMenuData(data);
        setEditEnable(!editable);
    }, [data])

    const handleChange = (event) => {
        if (event.target.name === 'name') {
            setMenuData({ ...menuData, 'display_name': event.target.value, [event.target.name]: event.target.value })
        }else{
            setMenuData({ ...menuData, [event.target.name]: event.target.value })
        }
    }

    const handleDate = (newValue) => {
        setDate(formatDate(newValue));
        setMenuData({ ...menuData, dob: formatDate(newValue) })
    }

    const handleUpdate = () => {
        // console.log(menuData);
        updateStoreMenu(dispatch, cookies, storeId, {"schedule_id": menuData.schedule_id, "quantity": menuData.quantity});
        setOpen(false);
    }
    return (
        <Dialog  
            // anchor='right'
            open={visible}
            onClose={() => setOpen(false)}
            maxWidth={'md'}
            // fullWidth
        >
            <DialogTitle>
                <Box sx={{ pb: 2 }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h6'>Detail View</Typography>
                    <Button size='small' color='info' onClick={() => setEditEnable(false)}>Edit</Button>
                </Box>
            </DialogTitle>
            <Stack sx={{ width: 'md' }} style={{ padding: '20px', gap: '10px' }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Name</Typography>
                    <TextFieldStyle disabled="true" name='name' value={menuData?.display_name} />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>In Stock</Typography>
                    <TextFieldStyle name='quantity' value={menuData?.quantity} onChange={handleChange} />
                </Box>

                {!editEnable && <Button sx={{ mt: 2, pt: 1, pb: 1 }} size='small' variant='contained' onClick={handleUpdate}>
                    {create ? <Typography>Create</Typography>
                     : <Typography>Update</Typography>
                    }
                </Button>}
            </Stack>
        </Dialog>
    )

}