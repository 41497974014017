import { getAccessTokenFromCookie } from "../session/api";
import axios from '../../../utils/axios-configure';
import { API_GET_SUBSCRIPTION_PLAN_LIST } from "../endpoints";
import { setSubscriptionPlanList, setSubscriptionPlanListStatus } from "../../../store/subscription/reducer";
import { STATUS } from "../../../constants/app_constants";
import { setLoadingStatus } from "../../../store/layout/reducer";

export const subscribeClient = () => {
    
}

export const confirmSubscription = () => {
    
}

export const fetchSubscriptionPlanList = (dispatch, cookies) => {
  dispatch(setLoadingStatus({status: true}));  
  dispatch(setSubscriptionPlanListStatus({status: STATUS.LOADING}));
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    if(accessToken != null){
        axios({
            method: "get",
            url: `${API_GET_SUBSCRIPTION_PLAN_LIST}?system_created=${true}&ai=${false}`,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
          })
          .then((response) => {
            console.log(response);
            // handle success
            if(response.status === 200){
              dispatch(setSubscriptionPlanList({data: response.data.data}));
              dispatch(setSubscriptionPlanListStatus({status: STATUS.SUCCESS}));
            }else{
              dispatch(setSubscriptionPlanListStatus({status: STATUS.ERROR}));
              dispatch(setSubscriptionPlanList({data: {}}));
            } 
            dispatch(setLoadingStatus({status: false}));  
            return true;
          })
          .catch((err) => {
            dispatch(setSubscriptionPlanListStatus({status: STATUS.ERROR}));
            dispatch(setSubscriptionPlanList({data: {}}));
            dispatch(setLoadingStatus({status: false}));  
            // console.log(response);
          });
    }else{
      dispatch(setSubscriptionPlanListStatus({status: STATUS.ERROR}));
      dispatch(setSubscriptionPlanList({data: {}}));
      dispatch(setLoadingStatus({status: false}));  
    }
}
