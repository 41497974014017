import { Container } from "@mui/material";
import Page from "../../../components/Page";
import { StoreInvoiceList } from "./StoreInvoiceList";

export const StoreInvoices = () => {

    return (
        <Page title="Invoices">
            <Container size="xl">
                <StoreInvoiceList />
            </Container>
        </Page>
    );
}