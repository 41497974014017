import { useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Grid, Container, Typography, Card, Stack, Button, Dialog, DialogContent, SwipeableDrawer, Box, Modal } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import DishList from '../../sections/coach/dish/DishList';

import { getPartnerDish, getPartnerMealList } from '../../store/user/reducer';
import { fetchPartnerDish, fetchPartnerMeals } from '../../data/network/dish/api';
import DishListCard from '../../sections/coach/dish/dish_list/DishListCard';
import DishDetail from '../../sections/coach/dish/DishDetail';
import CreateDishForm from '../../sections/coach/dish/dish_list/CreateDishForm';
import BulkUploadDishForm from '../../sections/coach/dish/BulkUploadDishForm';
import BulkUploadMealForm from '../../sections/coach/meal/BulkUploadMealForm';
import { getMealDetailModalVisibility, resetMealCreationState, setMealDetailModalVisibility, setSelectedMeal } from '../../store/meal/reducer';
import MealListCard from '../../sections/coach/meal/meal_list/MealListCard';
import { mealList } from '../../constants/meal_constants';
import MealDetail from '../../sections/coach/meal/MealDetail';
import CreateMealForm from '../../sections/coach/meal/CreateMealForm';
import ListContainer from '../../components/list/ListContainer';
import MealList from '../../sections/coach/meal/MealList';
import { APP_BAR_DESKTOP, APP_BAR_MOBILE, DRAWER_WIDTH } from "../../constants/layout_constants";
// sections


// ----------------------------------------------------------------------

export default function Meals() {
  const theme = useTheme();

  const navigate = useNavigate();

  const dishList = useSelector(state => getPartnerDish(state));
  // const mealList = useSelector(state => getPartnerMealList(state));

  const [meals, setMeals] = useState([]);

  const dispatch = useDispatch(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);
  const [open, setOpen] = useState(false);

  const [bulkUploadDrawerOpen, setBulkUploadDrawerOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const dishId = searchParams.get("dish_id");
  const mealId = searchParams.get("meal_id");

  const isMealDetailModalVisible = useSelector((state) => getMealDetailModalVisibility(state));



  const showMealDetailModal = () => {
    dispatch(setMealDetailModalVisibility({data: true}));
  };

  const handleClose = () => {
    dispatch(setMealDetailModalVisibility({data: false}));
  }

  const handleBulkUploadDrawer = () => {
    setBulkUploadDrawerOpen(true);
  }

  const handleBulkUploadDrawerClose = () => {
    setBulkUploadDrawerOpen(false);
  }

  const handleExportAll = () => {

  }

  const toggleBulkUploadModal = (anchor , open ) => (event ) => {
    if (
        event &&
        event.type === 'keydown' &&
        ((event ).key === 'Tab' ||
        (event ).key === 'Shift')
    ) {
        return;
    }    
    setBulkUploadDrawerOpen(open); 
  };

  const HeaderStyle = styled('div')(({ theme }) => ({
    position:'fixed',
    top: APP_BAR_MOBILE,
    zIndex:3,
    width:'100%',
    backgroundColor: 'rgba(255,255,255,0.8)',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    [theme.breakpoints.up('xl')]: {
        top: APP_BAR_DESKTOP,
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
      }
  }));


  return (
    <Page title="Meals">
      {
        <>
          {/* <HeaderStyle className='glass-box'>
            <Grid container direction="row" alignItems="center" justifyContent="space-between" padding={2}>

                <Grid item justifyContent={'flex-start'}>
                    <input type="search" />
                </Grid>

                <Grid item justifyContent={'space-evenly'}>
                    <Button margin={1} variant="contained" justifyContent="center" alignItems="center" onClick={() => {
                        setBulkUploadDrawerOpen(true);
                    }}>
                        Bulk Upload
                    </Button>
                    <Button margin={1} variant="contained" justifyContent="center" alignItems="center" onClick={() => {
                        handleExportAll();
                    }}>
                        Export All
                    </Button> 
                    <Button margin={1} variant="contained" justifyContent="center" alignItems="center" onClick={() => {
                        dispatch(resetMealCreationState());
                        showMealDetailModal(); 
                    }}>
                        Add new Meal
                    </Button> 
                </Grid>
            </Grid>       
        </HeaderStyle> */}

        <Box margin={1} />

        <MealList />

        <Box margin={1} />

        <SwipeableDrawer 
            anchor="bottom" 
            open={bulkUploadDrawerOpen} 
            onClose={toggleBulkUploadModal('bottom', false)}
            onOpen={toggleBulkUploadModal( 'bottom', true)}>
              <DialogContent>
                  <BulkUploadMealForm handleClose={toggleBulkUploadModal('bottom', false)} />                    
              </DialogContent>
          </SwipeableDrawer>

          <Modal
                open={isMealDetailModalVisible}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{zIndex:999}}
                // sx={{zIndex: 999, top:'100px', left:'100px', height:'100%', width:'100%', background:'#fff', borderRadius:2, margin:'0'}}
            >
            <Box>
                <CreateMealForm handleClose={handleClose} />  
            </Box>
          </Modal>
        </>
      }

    </Page>
  );
}
